@import '../style/common';

.latest-featured-block {
  $latest-featured-block-switch-breakpoint: md;

  &__featured-secondary-top-section {
    border-bottom: $border-style;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      .grid-card {
        width: 75%;
      }
    }
  }

  &__featured-author-image {
    flex-shrink: 0;
  }

  &__featured-block-list-wrapper {
    @include media-breakpoint-only($latest-featured-block-switch-breakpoint) {
      border-bottom: $border-style;
    }
  }

  &__list-border {
    border-bottom: $border-style;

    @include media-breakpoint-only($latest-featured-block-switch-breakpoint) {
      border: 0;
    }
  }

  &__featured-block-list {
    a {
      @include link-focus-outline;

      color: $gray-5;
      display: inline-block;
    }
  }
}
