/*
    AU STYLES BUNDLE
    ==================
*/

// 1. Get the core styles. (these include bootstrap etc.)
@import "scss/style";

// 2. Import the base components
@import "scss/components/index";

// TODO 3. Import the overrides
@import "overrides/index";

//Import Landing Page styles
//@import "landing-pages/index.scss";

//Import font awesome
@import "../../../../fonts/font-awesome-4.7.0/css/font-awesome.min.css";
