@import '../style/common';

.grid-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__label-title {
    line-height: $h4-line-height;
    display: block;
  }

  &__label-link {
    display: inline-block;
    margin-bottom: space(3);
  }

  &__divider {
    width: 100%;
    height: $border-width;
    background-color: $gray-2;
    margin-top: auto;
  }

  &__eyebrow-link,
  &__label-link {
    @include link-focus-outline;

    color: $dark;

    &:hover {
      color: $primary;
    }
  }

  &__header {
    margin-bottom: space(3);
  }

  &__title-link {
    @include link-focus-outline;

    display: inline-block;
    color: $dark;

    &:hover {
      color: $dark;
    }
  }

  &__badge {
    border-radius: 0;
    background-color: $purple;
    color: $white;
    letter-spacing: $text-util-sm-letter-spacing;
    width: fit-content;
    block-size: fit-content;
    font-weight: $font-weight-semi-bold;
  }

  &__body {
    margin-top: space(3);
    margin-bottom: space(4);
    flex: 1;
    color: $gray-6;
  }

  &__image-icon {
    color: $white;
    display: flex;
    position: absolute;
    bottom: 2rem;
    right: space(3);
    padding: 2px;
    transition: color $trans-duration $trans-easing;
  }

  &__image {
    transition: transform 1s;

    &-link {
      @include hover-focus {
        .grid-card__image {
          transform: scale(1.07);
        }
      }
    }

    &-container {
      overflow: hidden;
    }
  }
}
