@import '../style/common';

.fund-promotion-block {
  &__title {
    @include make-heading(2);
    margin-bottom: space(3);
  }

  &__body, &__cta {
    margin-bottom: space(4);
  }

  @include media-breakpoint-down(lg) {
    &__fund-card {
      margin-bottom: space(4);
    }
  }
}
