﻿
/*Styles for the social content*/
.social-content-card {
    min-height: 220px;
    color: white;
    padding: 30px 25px;
    background-size: cover;

    a{
        color:white;
    }
    .heading {
        font-size: 26px;
        line-height: 25px;
        margin-bottom: 0px;
        color:white;
    }

    .sub-heading {
    }

    .body-text {
        font-size: 18px;
        line-height: 28px;
        padding-top: 30px;
    }
}
/*Aside Card with "campaign-aside-card" class*/
.green-button-cta {
    .fund-card__ticker {
        font-size: 1.5rem !important;
    }
}
/*Styles for the schedule a call block*/
.campaign-schedule-a-call {
    &.jumbotron {
        .jumbotron__cta {
            margin: 1.5rem 0;
        }

        .jumbotron__cta .cta-link__text {
            font-size: 1.5rem !important;
            padding-right: 30px;
            text-transform: none;
            letter-spacing: inherit;
            display: inline;
            margin-bottom: 0px;
            line-height: 36px;
            font-weight: 700;
        }

        @media (max-width: 639.9px) {
            .jumbotron__cta.cta-link {
                display: inline-block !important;
            }
        }

        @media (max-width: 400px) {
            .jumbotron__cta svg {
                margin-top: -8px !important;
            }
        }
    }
}

/*Hero banner styles and removing site map from the footer navigation*/
.hide-promo-banner li.site-footer__nav-list-item:last-child {
    display: none;
}
@media (min-width:1200px) {
    .hide-promo-banner .hero__bg.hero__bg--overlay {
        background-position: -230px;
    }
}

/**Conference Page bio styles*/
.conference-page-bio {
    .bio.row {
        margin-bottom: unset;
        padding-bottom: unset;
    }

    .bio__image-column {
        text-align: left;
    }

    .grid-card__divider {
        display: none;
    }

    .bio__content-column .bio__body {
        padding-left: 1rem;
    }
}
@media (max-width: 767.9px)  {
    .conference-page-bio {
        .bio__content-column {
            display:flex;
            flex-direction:column;
            .image.bio__image-mobile {
                order: 1;
            }
            h2 {
                order: 2;
            }
            .bio__body{
                order:3;
            }
        }
    }
}

.teams-block {
    &__image-wrapper img {
        width: 60%;
    }

    &__subheading, &__link, &__contact-number{
        font-size:large;
    }
    &__link{
        color:$primary;
    }
    @media (max-width:1024px){
        &__image-wrapper img{
            width:100%;
        }
    }
}