@import '../style/common';

.promo-card {
  border: 0;
  padding: space(4);
  text-align: center;

  &--dark {
    color: $white;
    background-color: $tertiary;
    background-image: url('./assets/images/promo-background-dark.svg');
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__quote {
    @include make-display-heading(1);
  }

  &__by {
    font-size: $font-size-lg;
  }

  &__quote, &__by {
    text-align: left;
    margin-bottom: space(3);
  }

  &__title {
    @include make-heading(3);
    margin-top: space(4);
    margin-bottom: space(3);
  }

  &__body, &__cta {
    font-size: $font-size-xs;
  }

  &__cta {
    margin-top: auto;
    margin-bottom: space(3);
  }
}
