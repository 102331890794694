@import '../style/common';

.tabs {
  &__items {
    border-bottom: 0.25rem solid $gray-1;
  }

  &__content {
    padding-top: 1rem;
  }

  .nav-item {
    margin-right: 1rem;
  }

  .nav-link {
    position: relative;
    font-weight: $font-weight-bold;
    color: $gray-4;
    padding: 0.5rem;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: 0.25rem;
      background-color: $transparent;
    }

    &.active, &:hover, &:focus {
      color: $gray-5;
      background-color: $transparent;
      border-color: $transparent;

      &:after {
        background-color: $primary;
      }
    }
  }
}
