@import '../style/common';

.aside-card {
  &--light {
    background-color: $light;
    border-color: $light;
    color: $body-color;
  }

  &--dark {
    background-color: $tertiary;
    border-color: $tertiary;
    color: $white;
  }

  &__heading {
    margin-bottom: space(4);
  }

  &__body { // @TODO: Move rich text style overrides to more centralized/accessible location (if applicable)
    hr {
      border-color: $pastel;
      margin: space(4) 0;
    }
  }

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
  }

  &__list-item ~ &__list-item {
    margin-top: space(3);
  }

  &__icon {
    margin-right: space(3);
    vertical-align: -0.3em;
  }
}
