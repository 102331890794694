﻿
#NavPriceBlock {
    .toggle-button-wrapper {
        border: 1px solid #17468f;
        border-radius: 5px;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;

        @media(max-width:768px){
            top:60px;
        }

        span {
            padding: 10px 20px;
            display: inline-block;

            &.active {
                background-color: #17468f;
                color: #fff;
            }
        }
    }
}

.secondary-nav__link{
    padding-left:2rem;
    padding-right:2rem;
}

#excelDrawArea{
    table,th,td{
        border:1px solid black;
        border-collapse:collapse;
    }
}

.fund-listing-table .table-header-item, .fund-explorer-table__download-excel.excel-download {
    cursor: pointer;
}

#veYieldInvestmentReturns.fund-explorer-table td:first-child,
#veMorningstarFlowsTable.fund-explorer-table td:first-child,
#veYieldToWorstTable.fund-explorer-table td:first-child {
    color: #17468f;
}
#veYieldInvestmentReturns.fund-explorer-table td:nth-child(2),
#veMorningstarFlowsTable.fund-explorer-table td:nth-child(2),
#veYieldToWorstTable.fund-explorer-table td:nth-child(2) {
    color: unset;
}

.chart-disclaimer, .table-footer {
    font-size: 12px;
}

.fund-listing-table {
    .table-title.grouping h5 {
        font-size: 14px;
    }
}

.fund-explorer-table td {
    border-top: none;
    border-bottom: 1px solid #e6e7e8;
}

.custom-premium-content-form {
    .Form__MainBody {
        #__field_ {
            display: flex;
        }

        @media(max-width:640px) {
            #__field_ {
                flex-direction:column;
            }
        }
    }

    .sub-title {
        color: white;
    }

    &.green-cta {
        .premium-content-button {
            button {
                background-color: #0ab400 !important;
                border-color: #0ab400 !important;
                color: #fff !important;
            }

            button.focus, button:focus, button:hover {
                background-color: #fff !important;
                border-color: #007703 !important;
                color: #000 !important;
            }
        }

        .download-pdf {
            background-color: #0ab400 !important;
            border-color: #0ab400 !important;
            color: #fff !important;
        }

        .download-pdf.focus, .download-pdf:focus, .download-pdf:hover {
            background-color: #fff !important;
            border-color: #007703 !important;
            color: #000 !important;
        }
    }
}

.yield-monitor {
    .utility-menu {
        #veSpinnerContainer {
            display: none;
        }
    }

    .fund-explorer-table table.table-desktop {
        position: relative;

        thead {
            position: sticky;
            top: 136px;
        }

        @media(max-width:1199px) {
            thead {
                position: sticky;
                top: 176px;
            }
        }
    }

    @media screen and (orientation:landscape) {
        #secondary-nav .dropdown-menu {
            height: 60vh;
            overflow-y: scroll;
        }
    }

    @media (max-width:1023px) {
        .premium-content-block .subscribe-block__content .row div:nth-child(2) {
            width: 100%;
        }
    }
}

.tab-container{
    position:relative;
}