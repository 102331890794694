﻿.video-palette {

    .video-playlist__preview-track {
        .video-playlist__preview.playing .video-playlist__preview-image-overlay, .video-playlist__preview:hover .video-playlist__preview-image-overlay {
            background-color: #17468f;
            opacity: 0.8;
            background-image: none;
        }

        .video-playlist__preview:not(.playing) {
            //opacity: 0.6;
        }
        /*        .video-playlist__preview-link:focus *, .video-playlist__preview-link:hover * {
            text-decoration: initial;
        }*/

        .video-playlist__preview:not(.playing) {
            cursor: pointer;
        }

        .video-playlist__preview.playing {
            display: none;
        }

        @media(max-width: 991px) {
            .video-playlist__preview-image-overlay {
                max-width: 50% !important;
            }
        }

        @media (orientation: landscape) and (max-width: 991px) {
            .video-palette .video-playlist__preview-track .video-playlist__preview-image-overlay {
                max-width: 50% !important;
            }
        }
    }

    .embed-responsive .embed-responsive-item {
        position: relative;
    }

    .embed-block-player.sticky video.vjs-tech {
        min-height: 200px;
    }

    .player-description-block {
        font-size: 1.125rem;
        letter-spacing: 0;
        line-height: 1.75rem;
        font-weight: 400;
    }
}
