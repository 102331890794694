@import '../style/common';

.promo-block {
  $promo-col-switch-breakpoint: lg;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $light;
  padding-top: space(5);
  padding-bottom: space(6);

  &__list {
    @include list-unstyled;
    @include make-row;
    margin-top: space(4);
    margin-bottom: space(4);
  }

  &__col {
    @include make-col-ready;
    margin-bottom: space(5);

    @include media-breakpoint-up($promo-col-switch-breakpoint) {
      @include make-col(4);
      margin-bottom: 0;
    }
  }

  &__card {
    height: 100%;
  }
}
