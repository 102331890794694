@import '../style/common';

.related-insights {
  background-color: $light;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: space(5) 0 space(6);

  &__heading {
    margin-bottom: space(5);
  }

  &__list {
    @include list-unstyled;
    @include make-row;
    margin-bottom: 0;
  }

  &__list-item {
    @include make-col-ready;
    margin-bottom: space(4);

    @include media-breakpoint-up(xl) {
      @include make-col(4);
      margin-bottom: 0;
    }
  }

  &__link {
    height: 100%;
  }
}
