
* {
  // override default flex behavior for IE 11
  flex: 0 1 auto;
}

html, body {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}


body {
  visibility: visible; // webpack dev deflicker
  min-width: 320px;
  margin: 0 auto;
}

.root {
  display: flex;
  height: 100%; // needed for IE 11 flex grow - https://bit.ly/2y6bFbK
  min-height: 100vh;
  flex-direction: column;
}

main {
  position: relative;
  flex: 1 0 auto;
}
