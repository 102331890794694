@import '../style/common';

.disclosure-block-light {
  border-left: $border-width solid $gray-1;

  &__heading {
    font-size: $font-size-xs;
    letter-spacing: $text-util-md-letter-spacing;
  }

  &__body {
    color: $gray-4;
  }
}
