@import '../style/common';

.section-header {
    border-bottom: $border-width solid $gray-3;

    &__label {
        line-height: $line-height-base;
    }

    &__cta {
        line-height: $h5-line-height;
        text-align: right;
    }

    &__dropdown {
        width: 350px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .cta-link:hover {
        transform: none;
        text-decoration: underline;
    }

    a,
    .cta-link,
    .dropdown__button {
        @include link-focus-outline;
    }

    @include media-breakpoint-down(sm) {
        margin: 0 - space(3);
        padding: 0 space(3);

        &__label,
        &__dropdown-label {
            max-width: 50%;
        }

        &__cta {
            max-width: 40%;
        }

        &__dropdown-label {
            width: auto;
        }
    }
}
