body.au-campaign-page {
    p a {
        font-weight: 700;
    }
}

.z-index-2 {
    z-index: 2;
}

.section-heading {
    color: #001E6D;
}

.level2-indent {
    .level2-content {
        margin-left: 1.5rem;
    }
}

.h3-aqua {
    h3 {
        color: #0bb1bf;
    }
}

.h2-blue {
    h2 {
        color: #001E6D;
    }
}

.in-content-heading {
    font-size: 0.9375rem;
}

table.shaded-table {
    line-height: 1.5;

    td, th {
        padding: 0.5rem 1rem;
    }

    ul {
        list-style-type: disc;
        padding-left: 1rem;
    }
}

.author-section {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    grid-gap: 3rem;

    .byline__author {
        flex-basis: 45%;
    }

    .byline__author-title, .byline__author-employer {
        font-size: 0.9375rem;
        line-height: 1.2;
        margin-bottom: 0.25rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .author-section {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.section-accordion {
    .accordion {
        border-top: none;
        padding: 0.5rem 0;

        &__label {
            font-size: 0.9375rem;
        }

        &__icon {
            font-size: 1.1rem;
            top: 0.5em;
        }

        &__toggle {
            padding: 0 3rem 1rem 0;

            &:focus, &:hover {
                text-decoration: none;
            }
        }

        &__content {
            padding: 0;
        }
    }
}

.tab-accordion {
    .accordion {
        padding: 0;

        &__toggle {
            padding: 1rem 0.5rem;
        }

        &__icon {
            top: 1.75em;
        }
    }

    .preview-card:hover:before, .preview-card:focus:before {
        opacity: 0;
    }
}

.tile-list {
    padding-top: 1rem;
    text-align: center;
    display: flex;
    gap: 1rem;

    &__item {
        padding: 0.5rem;
        border: 1px solid #323232;
        border-radius: 0.3125rem;
        margin-bottom: 1rem;
        width: 150px;
        height: 100px;
        display: flex;
        justify-content: center;
    }

    &__label {
        align-self: center;
    }
}

.tile-accordion {
    .accordion {
        border: none;

        &__toggle {
            padding: 0.5rem 1rem;
            border: 1px solid #323232;
            border-radius: 0.3125rem;
            text-align: center;
        }

        &__icon {
            color: #000;
            top: 0.65em;
            right: 0.5em;
        }

        &__content {
            padding-left: 1rem;
        }

        &.green-toggle {
            .accordion__toggle {
                background-color: #7ca081;
                color: #000;
                border: 2px solid #7ca081;
            }

            .accordion.inner-tile-accordion .accordion__toggle {
                background-color: #ccd9ce;
                border-color: #ccd9ce;
            }

            .tile-list__item {
                border: 2px solid #ccd9ce;
            }
        }

        &.blue-toggle {
            .accordion__toggle {
                background-color: #7f8eb8;
                color: #000;
                border: 2px solid #7f8eb8;
            }

            .accordion.inner-tile-accordion .accordion__toggle {
                background-color: #cdd2e3;
                border-color: #cdd2e3;
            }

            .tile-list__item {
                border: 2px solid #cdd2e3;
            }
        }

        &.black-toggle {
            .accordion__toggle {
                background-color: #808080;
                color: #000;
                border: 2px solid #808080;
            }

            .accordion.inner-tile-accordion .accordion__toggle {
                background-color: #cdcdcd;
                border-color: #cdcdcd;
            }

            .tile-list__item {
                border: 2px solid #cdcdcd;
            }
        }
    }
}

.inner-tile-accordion {
    .accordion {
        &__content {
            padding: 0;
        }
    }
}

@media (max-width: 767px) {
    .tile-list {
        flex-direction: column;
        gap: 0;

        &__item {
            width: 100%;
            height: auto;
        }
    }
}

ul.star {
    list-style: none;
    position: relative;
}

ul.star li:before {
    content: "";
    position: absolute;
    left: 10px;
    margin-top: 0.5rem;
    background-image: url(/globalassets/home.au/special/awards/star1x.png);
    background-size: cover;
    width: 10px;
    height: 10px;
}

ul.checkmark {
    list-style: none;
    position: relative;
}

ul.checkmark li:before {
    content: "";
    position: absolute;
    left: 15px;
    margin-top: 0.5rem;
    background-image: url(/globalassets/home.au/special/esg-investing/check.svg);
    background-size: cover;
    width: 15px;
    height: 15px;
}


.preview-card__link-title {
    gap: 1.5rem;
}

.wrapped-div {
    max-width: 100%;
}

@media (max-width: 1240px) {
    .wrapped-div {
        overflow-x: scroll;
    }
}

.wrapped-div-sector {
    max-width: 100%;
    overflow-x: scroll;
}

@media (min-width: 992px) {
    body.au-campaign-page {
        .right-column-content {
            border-left: 1px solid grey;
            padding-left: 2rem;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    body.au-campaign-page {
        .main-content {
            max-width: 100%;
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
    body.au-campaign-page .root > main {
        padding-top: 19px;
    }
}
