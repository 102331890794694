@import '../scss/style/common';

.ve-modal {

    &__content {
        background-image: url(/globalassets/home/us/common/form-bg.svg);
        background-position: 100% 0;
        background-size: cover;
    }

    &__dialog {
        max-width: 790px;

        @include media-breakpoint-down(md) {
            max-width: none;
        }
    }

    &__modal-body {
        padding: space(5) space(4);


        .text-util-lg-darker {
            font-size: 0.9375rem;
            letter-spacing: 0;
            line-height: 1.5625rem;
            font-weight: 900;
        }

        .Form__MainBody {

            .Grouping {
                width: 100%;

                .GroupingContainer {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .div-Accredited {
                        @include media-breakpoint-down(lg) {
                            top: 0.5rem;
                        }
                    }

                    .Form__Element__ValidationError {
                        font-size: .624375rem;
                        font-weight: 600;
                        letter-spacing: .0625rem;
                        text-transform: uppercase;
                        color: red;
                        margin-top: .5rem;
                        text-align: right;
                    }

                    .dropdown {
                        @include media-breakpoint-down(md) {
                            margin-bottom: 1rem;
                        }

                        > .dropdown__button {
                            @include media-breakpoint-down(md) {
                                margin-bottom: 0 !important;
                            }
                        }
                    }

                    > div {
                        @include media-breakpoint-up(md) {
                            max-width: calc(50% - 10px);
                        }
                    }

                    .FormChoice {
                        position: relative;
                        margin-bottom: 25px;

                        label.FormChoice__Label {

                            .label-title {
                                font-size: 0.8125rem;
                                letter-spacing: 2px;
                                line-height: 1.125rem;
                                font-weight: 600;
                                text-transform: uppercase;
                            }

                            padding-right: 10px;
                            text-transform: none;
                            font-size: 0.75rem;
                            letter-spacing: 0;
                            line-height: 1.0625rem;
                            font-weight: 400;
                        }
                    }
                }
            }

            .dropdown__icon-default {
                top: 12px;

                @include media-breakpoint-down(md) {
                    top: 35px;
                }
            }
        }
    }

    &__body {
        font-size: $font-size-lg;
    }

    &__eyebrow {
        width: 90%;
        font-size: 1.1875rem;
        letter-spacing: 0;
        line-height: 1.5625rem;
        font-weight: 700;

        @include media-breakpoint-down(md) {
            padding-right: 25px;
        }
    }

    &__title {
        font-size: 1.5rem;
        letter-spacing: 0;
        line-height: 1.875rem;
        font-weight: 700;
    }

    &__close {
        position: absolute;
        top: space(4);
        right: space(4);

        &.close-icon {
            @include link-focus-outline;
        }
    }
    /*&__content {
    background-color: $light;
    background-image: url('#{$images-path}form-bg.svg');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
  }*/

    &__left-container {
        border-right: $border-width solid $gray-2;
    }

    &__badge {
        border-radius: 0;
        background-color: $purple;
        color: $white;
        letter-spacing: $text-util-sm-letter-spacing;
        font-weight: $font-weight-semi-bold;
    }

    &__date,
    &__time {
        font-weight: $display1-weight;
    }

    &__description {
        font-weight: $display1-weight;
    }

    &__section-title {
        font-size: 1.0625rem;
        line-height: 1.4375rem;
        list-style: $h2-line-height;
    }

    &__disclaimer {
        font-size: 0.75rem;
        line-height: 1.0625rem;
    }

    .dropdown__button-default:disabled {
        background-color: $gray-1;
        pointer-events: none;
    }

    @include media-breakpoint-down(md) {
        &__outer-container,
        &__inner-container {
            text-align: left;
        }
    }
}
