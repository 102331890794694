@import '../style/common';

.insights-accordion-block {
    .section-header {
        margin-bottom: space(4);

        @include media-breakpoint-down(md) {
            margin-bottom: space(2);
        }
    }

    &__list {
        border-top: $border-width solid $gray-2;

        &:last-child {
            border-bottom: $border-width solid $gray-2;
        }
    }

    &__toggle {
        display: flex;
        justify-content: space-between;
        position: relative;
        width: 100%;
        text-align: left;
        padding: 2rem;
        align-items: center;

        @include media-breakpoint-down(sm) {
            padding: space(3);
            align-items: flex-start;
        }

        @include hover {
            text-decoration: underline;
        }

        &:focus-visible {
            h3 {
                outline: solid 2px mix(color-yiq($primary), $primary, 15%);
                outline-offset: 3px;
            }
        }
    }

    &__label {
        display: inline;
        vertical-align: middle;
        font-weight: $font-weight-normal;
        font-size: $font-size-lg;
        line-height: $line-height-base;
        padding-right: space(2);
    }

    &__icon {
        color: $dark-blue;
        transform: rotate(-180deg);
        transition: $trans-duration $trans-easing;

        @include media-breakpoint-down(sm) {
            margin-top: space(1);
        }

        .collapsed & {
            transform: rotate(0);
        }
    }

    &__content {
        padding: 0 2rem space(5);

        @include media-breakpoint-down(sm) {
            padding: 0 space(4) space(5);
        }
    }
}
