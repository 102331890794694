@import '../style/common';

.fund-carousel-block {
    $fund-carousel-block-switch-breakpoint: md;
    $fund-carousel-block-control-size: 2.1875rem;
    position: relative;

    &__heading {
        @include make-heading(4);
        margin: 0 space(6) 0 0;
    }

    &__main {
        margin-top: space(3);
        position: relative;

        &--desktop {
            .slick-track {
                display: flex;
            }

            .slick-slide {
                height: auto;
            }

            .slick-list {
                @include media-breakpoint-up($fund-carousel-block-switch-breakpoint) {
                    @include margin(null $grid-gutter-width / -2);
                }
            }

            &-count {
                height: $fund-carousel-block-control-size;
                padding: 0 space(2);
                background-color: $gray-1;

                b, span {
                    padding: 0 space(1);
                }
            }
        }

        @include media-breakpoint-down($fund-carousel-block-switch-breakpoint) {
            margin-bottom: space(3);
        }
    }

    &__placement {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .slick-initialized .slick-slide {
        display: flex;
    }

    .slick-disabled {
        background-color: $gray-3;
        pointer-events: none;
    }

    &__item {
        height: 100%;
        margin-top: space(3);

        @include media-breakpoint-up($fund-carousel-block-switch-breakpoint) {
            @include margin(0 $grid-gutter-width / 2);
        }
    }

    &__control {
        transition: $btn-transition;
        height: $fund-carousel-block-control-size;
        width: $fund-carousel-block-control-size;
        background-color: $dark-blue;
        color: $white;

        &--prev {
            right: $fund-carousel-block-control-size;
        }
    }
}
