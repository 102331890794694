@import '../style/common';

.porthole {
  $porthole-sizes: (
    'sm': 2.5rem,    // ~40px
    'md': 3.5rem,    // ~56px
    'lg': 3.875rem,  // ~62px
  );

  border-radius: 50%;
  overflow: hidden;
  background-color: $white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @each $name, $size in $porthole-sizes {
    &--#{$name} {
      height: $size;
      width: $size;
    }
  }

  &__image {
    max-width: 100%;
    opacity: 0;
    height: auto;
  }
}
