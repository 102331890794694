@import '../style/common';

.compound-hero {
  $compound-hero-switch-breakpoint: lg;
  visibility: hidden;

  &__slide {
    background-color: $tertiary;
    color: $white;
    padding: space(5) 0;
    position: relative;
    z-index: 0;
    height: 100%;
  }

  .slick-initialized {
    visibility: visible;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;
  }

  &__slick-dots {
    position: relative;
    z-index: 3;

    @include media-breakpoint-down(md) {
      visibility: visible;
    }
  }

  .slick-dots {
    bottom: 0;
    z-index: 3;
    text-align: left;
    width: 90%;

    @include media-breakpoint-up($compound-hero-switch-breakpoint) {
      visibility: hidden;
    }

    // stylelint-disable selector-max-compound-selectors
    li,
    li button,
    li button:before {
      font-size: $slick-dot-size;
      height: 2rem;
      width: space(3);
    }

    li:first-child {
      margin-left: 0;
    }

    li button:before {
      color: $white;
      opacity: 0.75;
    }

    li button:hover:before,
    li button:focus:before {
      opacity: 0.7;
    }

    li.slick-active button:before {
      color: $light-blue;
    }
    // stylelint-enable selector-max-compound-selectors
  }

  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    height: 100%;

    &--overlay {
      background-position: center left;
      z-index: -1;
    }
  }

  &__row {
    @include make-row;
    align-items: center;
  }

  &__main,
  &__aside {
    @include make-col-ready;
    position: relative;
  }

  &__main {
    @include media-breakpoint-up($compound-hero-switch-breakpoint) {
      @include make-col(5);
    }
  }

  &__aside {
    display: none;

    @include media-breakpoint-up($compound-hero-switch-breakpoint) {
      @include make-col(4);
      @include make-col-offset(3);
      display: block;
    }

    @include media-breakpoint-only($compound-hero-switch-breakpoint) {
      @include make-col(5);
      @include make-col-offset(2);
      display: block;
    }
  }

  &__heading {
    margin-bottom: space(5);
  }

  &__cta-wrapper {
    margin-bottom: space(3);

    @include media-breakpoint-up($compound-hero-switch-breakpoint) {
      max-width: 60%;
      margin-bottom: 0;
    }
  }

  &__carousel-container {
    display: none;

    @include media-breakpoint-up($compound-hero-switch-breakpoint) {
      display: block;
    }
  }

  &__carousel {
    &-inner {
      background-color: rgba($dark-blue, 0.9);
      position: relative;
    }

    &-item {
      padding: space(4) space(4) 0;
      position: relative;
      display: flex;

      &:last-child {
        padding-bottom: space(4);
      }

      &.slick-current:after {
        background-color: $light-blue;
      }
    }

    &-image {
      flex-shrink: 0;
      width: 3.125rem;
      margin-right: 1.125rem;
    }

    &-thumbnail {
      padding-bottom: 3.125rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-main {
      border-bottom: $border-width solid rgba($gray-3, 0.27);
      margin-bottom: 0;
    }

    &-eyebrow {
      text-transform: uppercase;
      margin-bottom: 3px;
      font-weight: $font-weight-semi-bold;
    }

    &-body {
      font-size: $font-size-md;
      line-height: $h4-line-height;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 5px;
      display: block;

      @include plain-hover-focus {
        color: $white;
      }
    }

    &-explore {
      padding: space(4) space(4) space(4) 5.625rem;
      display: block;

      @include plain-hover-focus {
        color: $white;
      }
    }
  }
}
