
// See existing SASS 4 mixins before adding anything here: https://github.com/twbs/bootstrap/tree/v4-dev/scss/mixins


// ----- breakpoints -----
//
// Use media-breakpoint-up(), media-breakpoint-down(), or media-breakpoint-only()
// sizes -- xs, sm, md, lg, and xl


// ----- multi-state -----
//
@mixin all-states($self: true) {
  @if $self {
    @content;
  }
  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:hover:focus,
  &:active:focus,
  &:hover:active:focus {
    @content;
  }
}


// ----- responsivize util -----
//
$_w: ''; // https://github.com/sass/sass/issues/871
@mixin responsivize {
  @each $width in xs, sm, md, lg, xl {
    $_w: if($width == xs, '', '-#{$width}') !global; // stylelint-disable-line scss/no-duplicate-dollar-variables
    @include media-breakpoint-up($width) {
      @content;
    }
  }
}


// ----- hardware acceleration for animations -----
@mixin accelerate($property-name: null) {
  @if ($property-name) {
    will-change: $property-name; // To be used sparingly and only on a few elements at any time
  }

  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}


// ----- focus outline -----
@mixin focus-outline($color) {
  &:focus:not(.focus-visible) {
    outline: none;
  }

  &.focus-visible {
    outline: dashed 1px rgba(mix(color-yiq($color), $color, 15%), 0.5);
    outline-offset: 5px;
  }
}

@mixin link-focus-outline() {
  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: solid 2px mix(color-yiq($primary), $primary, 15%);
    outline-offset: 3px;
  }
}

// ---- last row selector ----//
@mixin last-row-selector($col) {
  &:nth-child(#{$col}n + 1):nth-last-child(-n + #{$col}),
  &:nth-child(#{$col}n + 1):nth-last-child(-n + #{$col}) ~ * {
    @content;
  }
}

/*
  Example of $breakpoint-cols var for grid-setup

  $breakpoint-cols: (
    'lg': 4,
    'md': 3,
    'sm': 2,
    'xs': 1,
  );
*/

@mixin borderless-grid($breakpoint-cols) {
  border-right: $border-style;
  border-bottom: $border-style;

  @each $breakpoint, $col in $breakpoint-cols {
    $index: (index(map-keys($breakpoint-cols), $breakpoint));

    @if $index == 1 {
      @include media-breakpoint-up($breakpoint) {
        &:nth-child(#{$col}n) {
          border-right: 0;
        }

        @include last-row-selector($col) {
          border-bottom: 0;
        }
      }
    }

    @else if $col == 1 {
      @include media-breakpoint-down($breakpoint) {
        border-right: 0;

        @include last-row-selector($col) {
          border-bottom: 0;
        }
      }
    }

    @else {
      @include media-breakpoint-only($breakpoint) {
        &:nth-child(#{$col}n) {
          border-right: 0;
        }

        @include last-row-selector($col) {
          border-bottom: 0;
        }
      }
    }
  }
}

// ----- headings -----
@mixin make-heading(
  $number: 1,
  $font-sizes: $heading-font-sizes,
  $line-heights: $heading-line-heights,
) {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;

  @if $number == 6 {
    font-size: map-get($font-sizes, $number);
    line-height: map-get($line-heights, $number);
  }
  @else {
    font-size: map-get($font-sizes, $number + 1);
    line-height: map-get($line-heights, $number + 1);
  }

  @include media-breakpoint-up(md) {
    font-size: map-get($font-sizes, $number);
    line-height: map-get($line-heights, $number);
  }

  small, .small {
    display: block;
    margin-bottom: 0.6em;
  }
}

@mixin make-display-heading(
  $number: 1,
  $font-sizes: $display-font-sizes,
  $letter-spacings: $display-letter-spacings,
) {
  @include make-heading(1);
  @include media-breakpoint-up(md) {
    font-size: map-get($font-sizes, $number);
    font-weight: $font-weight-extra-bold;
    letter-spacing: map-get($letter-spacings, $number);
    line-height: $display-line-height;
  }
}

// ----- text utils -----
@mixin make-text-util(
  $size: md,
  $font-sizes: $text-util-font-sizes,
  $letter-spacings: $text-util-letter-spacings,
) {
  font-size: map-get($font-sizes, $size);
  font-weight: $font-weight-semi-bold;
  letter-spacing: map-get($letter-spacings, $size);
  text-transform: uppercase;
}
