@import '../style/common';

.media-block {
  $media-block-grid-gutter-width: 0;
  $media-block-switch-breakpoint: lg;

  &__container {
    @include make-row($media-block-grid-gutter-width);
    align-items: center;
    flex-direction: column-reverse;

    @include media-breakpoint-up($media-block-switch-breakpoint) {
      flex-direction: row;

      &--reversed {
        flex-direction: row-reverse;
      }
    }
  }

  &__content,
  &__media {
    @include make-col-ready($media-block-grid-gutter-width);
  }

  &__content {
    background-color: $light;
    padding: space(5) space(4);

    @include media-breakpoint-up($media-block-switch-breakpoint) {
      @include make-col(4);
    }
  }

  &__media {
    @include media-breakpoint-up($media-block-switch-breakpoint) {
      @include make-col(8);
    }
  }
}
