﻿/****
    Webinar Notification Banners
*/

.banner-notification {
    background-color: #17468F !important;
    opacity: 0.9 !important;

    .navbar-inverse {
        max-width: 1320px;
        width: auto !important;
        background-color: transparent !important;
        position: relative !important;
        width: 940px;
        margin: 0 auto !important;
        border-width: 0;
    }
}

.blogs .navbar-inverse {
    width: 1170px;
}

.banner-notification {
    .disclosure {
        font-size: x-small !important;
        color: white !important;
        text-decoration: underline !important;
        font-weight: normal !important;
    }

    .message {
        font-weight: bold;
        font-size: 24px !important;
        line-height: 28px !important;
        max-width: 650px;
    }

    .static {
        font-weight: normal;
        font-size: 12px;
        line-height: 2.0em;

        span {
            padding: 0 5px;
        }
    }

    .navbar-font {
        color: white;
    }

    .navbar {
        padding: 15px;
    }

    .btn {
        position: relative !important;
        font-size: 14px !important;
        border-radius: 5px;
    }

    .btn-default {
        color: #ffffff !important;
        background-color: #17468F !important;
        border: 2px solid #ffffff;
        opacity: 2;
        margin-right: 20px;
        font-weight: bold;
        top: 0;
        right: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding: 2px 6px;

        &.register-now {
            color: #17468F !important;
            background-color: #ffffff !important;
            border: 2px solid #ffffff;
            opacity: 2;
            margin-right: 20px;
            font-weight: bold;
            top: 0;
            right: 0;
            margin-top: 0;
            margin-bottom: 0;
            padding: 2px 6px;

            &:hover {
                color: #ffffff !important;
                background-color: #17468F !important;
                border: 2px solid #ffffff;
                opacity: 2;
                margin-right: 20px;
                font-weight: bold;
                top: 0;
                right: 0;
                margin-top: 0;
                margin-bottom: 0;
                padding: 2px 6px;
            }
        }

        &:hover {
            background-color: #ffffff !important;
            color: #17468F !important;
            border: 2px solid #ffffff !important;
        }
    }

    .cta {
        display: flex !important;
        justify-content: flex-end;
        flex-wrap: wrap;
        text-align: right;
        display: inline-block;
        float: right;
        margin: 30px 0 0;
        padding: 0;

        > button {
            height: 40px;
            margin-top: 10px !important;
            margin-right: 20px !important;
        }
    }

    .webinar-content {
        display: inline-block;
        padding: 0;
    }

    .btn-default.js-no-thanks {
        margin-right: 0;
    }

    .button-link {
        position: relative !important;
        font-size: 14px !important;
        border-radius: unset;
        background: none;
        color: white;
        font-weight: 800;
    }

    .more {
        text-align: center;
        margin: 5px;
        min-height: 17px;
    }
}


@media all and (min-width: 700px) and (max-width: 768px) {
    .banner-notification .cta {
        justify-content: flex-end;

        > button {
            margin-right: 0 !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .banner-notification .navbar-inverse {
        width: 95%;
        margin-bottom: 25px;
    }

    .banner-notification .cta {
        float: left;
        text-align: left;
        margin-top: 10px;
    }

    .banner-notification .message {
        width: unset;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .banner-notification .cta {
        display: inline-block;
        transform: inherit;
    }

    .banner-notification .message {
        width: 92%;
    }

    .banner-notification .navbar-inverse {
        width: 100%;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1024px) {
    .blogs .navbar-inverse {
        width: 100%;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .banner-notification .cta {
        float: none;
        text-align: initial;
    }
}


@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}


@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


@keyframes fadeInUp {
    from {
        opacity: 0.9;
    }

    to {
        opacity: 0.9;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0.9;
    }

    to {
        opacity: 0.9;
    }
}
