@import '../style/common';

.alert-bar {
    background-image: url('#{$images-path}share-form-bg.svg');
    background-position: 80% 100%;
    background-repeat: no-repeat;
    background-size: cover;

/*    &:hover {
        .alert-bar__left-label {
            text-decoration: underline;
        }
    }
*/
    &__left-label {
        color: $gray-9;
        font-size: $font-size-md;
        font-weight: $font-weight-normal;
        line-height: $h4-line-height;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }

        &.bar-label {
            @include link-focus-outline;
        }

        &.dark-bg {
            color: $white;
        }
    }

    &__barlink {
        @include plain-hover-focus {
            text-decoration: none;
        }
    }

    &__group-icon {
        color: $primary;
        transform: scale(1);

        &.pulse {
            animation: pulse 6s infinite;
            border-radius: 50%;
            width: space(4);
            height: space(4);
        }

        &.dark-bg {
            color: $white;
        }

        @keyframes pulse {
            0% {
                transform: scale(1);
                box-shadow: 0 0 0 0 $primary;
            }

            60% {
                box-shadow: 0 0 0 8px $transparent;
            }

            70% {
                transform: scale(1.05);
                box-shadow: 0 0 0 10px $transparent;
            }

            100% {
                transform: scale(1);
                box-shadow: 0 0 0 0 $transparent;
            }
        }
    }

    &__left {
        font-size: $h3-font-size;

        @include media-breakpoint-down(md) {
            font-size: $font-size-md;
        }
    }

    &__ctalink {
        text-transform: uppercase;
        font-weight: $font-weight-semi-bold;
        font-size: $font-size-xs;
        color: $primary;
        letter-spacing: $text-util-md-letter-spacing;
        padding: space(2);

        &.close-bar {
            @include link-focus-outline;
        }

        &.dark-bg {
            color: $white;
        }
    }

    &__title {
        &.bar {
            @include link-focus-outline;
        }
    }
}