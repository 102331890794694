@import '../style/common';

.capabilities-insight-card {
  height: 100%;
  border: 0;

  &__heading {
    @include make-heading(3);
    color: $white;
    border-bottom: 0;
    padding: space(4);

    background-color: $tertiary;
    background-image: url('./assets/images/abstract-background-tertiary.svg');
    background-size: cover;
    background-position: 25% 50%;
    background-repeat: no-repeat;
  }

  &__insights {
    padding: 0 space(4);
    border: $border-style;
  }

  &__eyebrow {
    @include make-text-util(sm);
  }

  &__link {
    padding: space(4) 0;

    &:not(:last-child) {
      border-bottom: $border-style;
    }
  }
}
