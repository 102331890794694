@import '../style/common';

.event-block {
  padding: space(5);
  position: relative;
  z-index: 0;

  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;

    &--overlay {
      background-position: center left;
      z-index: -1;
    }
  }

  &--light {
    background-color: $white;
    border-top: $border-style;
    border-bottom: $border-style;
    color: $body-color;
  }

  &--dark {
    background-color: $tertiary;
    color: $white;
  }

  &__topic {
    margin-bottom: space(2);
  }

  &__title {
    margin-bottom: space(4);
  }

  &__description {
    margin-bottom: space(5);
  }

  &__contributors-label {
    margin-bottom: space(4);
  }

  &__contributor-list {
    @include list-unstyled;
    display: inline-block;
    margin-bottom: space(3);
  }

  &__contributor-list-item {
    display: inline-block;
    margin: 0 space(5) space(3) 0;
  }

  &__cta-container {
    position: relative;
    text-align: center;
    z-index: 0;

    &:before {
      content: '';
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: $border-width * 2;
      background-color: $transparent;
      z-index: 1;
    }

    @include media-breakpoint-up(lg) {
      padding: space(4);

      &:before {
        display: block;
      }
    }
  }

  &--light &__cta-container:before {
    background-color: $light;
  }

  &--dark &__cta-container:before {
    background-color: $secondary;
  }

  &__cta {
    margin-top: space(4);
  }
}
