/* TODO: move this to a main-*.css file: this is used in every single template */

@font-face {
    font-family: "Helvetica-Light";
    src: url('/static/fonts/255493165-HelveticaNeueLTCom-Lt.eot');
    src: url('/static/fonts/255493165-HelveticaNeueLTCom-Lt.eot?#iefix') format('embedded-opentype'), url('/static/fonts/255493165-HelveticaNeueLTCom-Lt.svg#Helvetica Neue LT Com') format('svg'), url('/static/fonts/255493165-HelveticaNeueLTCom-Lt.woff') format('woff'), url('/static/fonts/255493165-HelveticaNeueLTCom-Lt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Helvetica-Medium";
    src: url('/static/fonts/255493165-HelveticaNeueLTCom-Lt.eot');
    src: url('/static/fonts/255493165-HelveticaNeueLTCom-Lt.eot?#iefix') format('embedded-opentype'), url('/static/fonts/299102784-HelveticaNeueLTCom-Md.svg#Helvetica Neue LT Com') format('svg'), url('/static/fonts/299102784-HelveticaNeueLTCom-Md.woff') format('woff'), url('/static/fonts/299102784-HelveticaNeueLTCom-Md.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "HelveticaNeue-Bold";
    src: url('/static/fonts/404083252-HelveticaNeueLTCom-Bd.eot');
    src: url('/static/fonts/404083252-HelveticaNeueLTCom-Bd.eot?#iefix') format('embedded-opentype'), url('/static/fonts/404083252-HelveticaNeueLTCom-Bd.svg#Helvetica Neue LT Com') format('svg'), url('/static/fonts/404083252-HelveticaNeueLTCom-Bd.woff') format('woff'), url('/static/fonts/404083252-HelveticaNeueLTCom-Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/* Global banner specific styles */

#global-banner-wrapper {
    font-family: Helvetica;
    font-size: 14px;
    width: 100% !important;
    background-color: #333436 !important;
}

    #global-banner-wrapper #global-nav-slidedown-wrapper {
        position: absolute;
        width: 100%;
        z-index:9999;
    }

    #global-banner-wrapper a {
        color: #fff;
        text-decoration: none;
        width: auto;
        cursor: pointer;
    }

#global-banner-wrapper .global-nav-slidedown {
    overflow: hidden;
    max-height: 0;
    box-sizing: border-box;
    background-color: #fff;
    color: #eee;
    -webkit-transition: max-height 0.5s cubic-bezier(1,0,0,1);
    transition: max-height 0.5s cubic-bezier(1,0,0,1);
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &.active {
        overflow: initial;
        overflow-y: initial;

        .global-nav-slidedown-internal {
            overflow: initial;
        }
    }
}

        #global-banner-wrapper .global-nav-slidedown::-webkit-scrollbar {
            display: none;
        }

        #global-banner-wrapper .global-nav-slidedown .global-nav-slidedown-internal {
            padding: 40px 20px;
            overflow: hidden;
            width: 970px;
            margin: 0 auto;
        }

        #global-banner-wrapper .global-nav-slidedown ul {
            padding-left: 0 !important;
        }

        #global-banner-wrapper .global-nav-slidedown li {
            width: auto;
            box-sizing: border-box;
            padding-left: 10px;
        }

        #global-banner-wrapper .global-nav-slidedown a {
            color: #000;
        }

            #global-banner-wrapper .global-nav-slidedown a.btn {
                color: #a1a1a2;
            }

        #global-banner-wrapper .global-nav-slidedown h3 {
            color: #fff;
        }

        #global-banner-wrapper .global-nav-slidedown.active {
            max-height: 100vh!important;
        }

    #global-banner-wrapper form {
        margin-bottom: 0;
    }

    #global-banner-wrapper #global-nav-corporate a {
        border-top-color: #003a68;
    }

    #global-banner-wrapper #global-nav-wrapper {
        width: 970px;
        position: relative;
        background-color: #333436 !important;
        margin: 0 auto;
        z-index: 10;
        display: block;
        right: initial;
    }

        #global-banner-wrapper #global-nav-wrapper:after {
            display: block;
            clear: both;
            content: ' ';
        }

    #global-banner-wrapper #global-nav {
        float: right;
        padding: 0;
        margin: 0;
    }

        #global-banner-wrapper #global-nav li {
            display: block;
            line-height: 1.2em;
            float: left;
        }

#global-nav li a {
    display: block;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 36px;
    color: #fff;
}

#global-nav-site-selector-li a {
    padding: 10px 15px 10px !important;
}

h4.modal-title a.changecountry-rest-of-world {
    width: auto;
    padding-left: 45px !important;
    margin-left: 0;
    height: auto !important;
}

#global-nav li a.active {
    margin-left: 0px;
    color: #fff;
    background-position: 8px 7px;
    width: inherit;
}

#global-nav li a:hover {
    background-color: #000000;
    color: #fff;
    border-top-color: #000000;
    width: inherit;
}

#global-nav li a span {
    padding-left: 16px;
}

.global-nav-input-group {
    width: 25%;
    float: left;
    position: relative;
    left: 25%;
    padding: 0 10px;
    box-sizing: border-box;
}

    .global-nav-input-group input[type="text"],
    .global-nav-input-group input[type="password"],
    .global-nav-input-group input[type="submit"] {
        display: block;
        width: 100%;
        height: 35px;
        box-sizing: border-box;
        margin-bottom: 5px;
    }

    .global-nav-input-group input[type="text"],
    .global-nav-input-group input[type="password"] {
        padding: 5px 10px;
        border: 1px solid #ddd;
        border-radius: 3px;
    }

    .global-nav-input-group label {
        font-size: 12px;
    }

    .global-nav-input-group input[type="submit"],
    .global-nav-slidedown a.btn {
        border: none;
        color: #fff !important;
        background-color: #006BB4;
        border-radius: 6px;
    }

        .global-nav-input-group input[type="submit"]:hover,
        .global-nav-slidedown a.btn:hover {
            background-color: #285e8e;
        }


a.global-nav-flag {
    background: url('/Static/images/ahp-country-flags-sprite.png?m=20191312') no-repeat !important;
    padding-left: 29px !important;
    width: 29px;
    height: auto;
    position: relative;
}

button.global-nav-flag {
    background: url('/Static/images/ahp-country-flags-sprite.png?m=20191312') no-repeat !important;
    padding-left: 38px !important;
    width: 29px;
    height: auto;
    position: relative;
    background-color: #ffffff !important;
}

#slidedown-audience {
    position: relative;
}

#global-nav-slidedown-wrapper .global-nav-audience-selection-3 a.btn {
    position: absolute;
    top: 165px;
}

.global-nav-audience-selection-2 {
    float: left;
    width: 50%;
    padding: 0 40px;
}

.global-nav-audience-selection-3 {
    float: left;
    width: 33%;
    padding: 0 40px 40px;
}

.global-nav-slidedown-internal .country-austria {
    background-position: 4px -53px !important;
}

.global-nav-slidedown-internal .country-australia {
    background-position: 4px -6px !important;
}

.global-nav-slidedown-internal .country-belgium {
    background-position: 4px -1162px !important;
}

.global-nav-slidedown-internal .country-chile {
    background-position: 4px -150px !important;
}

.global-nav-slidedown-internal .country-china {
    background-position: 4px -198px !important;
}

.global-nav-slidedown-internal .country-canada {
    background-position: 4px -102px !important;
}

.global-nav-slidedown-internal .country-denmark {
    background-position: 4px -244px !important;
}

.global-nav-slidedown-internal .country-finland {
    background-position: 4px -293px !important;
}

.global-nav-slidedown-internal .country-iceland {
    background-position: 4px -1370px !important;
}

.global-nav-slidedown-internal .country-ireland {
    background-position: 4px -389px !important;
}

.global-nav-slidedown-internal .country-italy {
    background-position: 4px -436px !important;
}

.global-nav-slidedown-internal .country-germany {
    background-position: 4px -340px !important;
}

.global-nav-slidedown-internal .country-luxembourg {
    background-position: 4px -483px !important;
}

.global-nav-slidedown-internal .country-netherlands {
    background-position: 4px -532px !important;
}

.global-nav-slidedown-internal .country-norway {
    background-position: 4px -579px !important;
}

.global-nav-slidedown-internal .country-sweden {
    background-position: 4px -675px !important;
}

.global-nav-slidedown-internal .country-switzerland {
    background-position: 4px -814px !important;
}

.global-nav-slidedown-internal .country-united-states {
    background-position: 4px -771px !important;
}

.global-nav-slidedown-internal .country-united-kingdom {
    background-position: 4px -721px !important;
}

.global-nav-slidedown-internal .country-spain {
    background-position: 4px -626px !important;
}

.global-nav-slidedown-internal .country-portugal {
    background-position: 4px -1020px !important;
}

.global-nav-slidedown-internal .country-liechtenstein {
    background-position: 4px -1068px !important;
}

.global-nav-slidedown-internal .country-france {
    background-position: 4px -1114px !important;
}

.global-nav-slidedown-internal .country-poland {
    background-position: 4px -1211px !important;
}

.global-nav-slidedown-internal .country-mexico {
    background-position: 4px -1327px !important;
}

.global-nav-slidedown-internal .country-brazil {
    background-position: 4px -1274px !important;
}

.global-nav-slidedown-internal .country-rest-of-world {
    background-position: 4px -921px !important;
}

    .global-nav-slidedown-internal .country-rest-of-world:before {
        margin-left: 1px !important;
        top: 9px !important;
    }

.global-nav-slidedown-internal .country-rest-of-asia {
    background-position: 3px -860px !important;
}

.global-nav-slidedown-internal .country-peru {
    background-position: 5px -970px !important;
}

.global-nav-slidedown-internal .country-rest-of-world:before {
    font-family: FontAwesome;
    content: none !important;
    position: absolute;
    left: -1px;
    font-size: 1.8em;
}

#global-banner-wrapper .country-rest-of-world:before {
    left: -1px;
    top: 0px;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-united-states.active, a.global-nav-slidedown-trigger.global-nav-flag.country-united-states, a.header-nav-slidedown-trigger.country-united-states {
    background-position: 15px -765px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-australia, a.global-nav-slidedown-trigger.global-nav-flag.country-australia.active, a.header-nav-slidedown-trigger.country-australia {
    background-position: 15px -2px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-italy, a.global-nav-slidedown-trigger.global-nav-flag.country-italy.active, a.header-nav-slidedown-trigger.country-italy {
    background-position: 15px -431px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-luxembourg, a.global-nav-slidedown-trigger.global-nav-flag.country-luxembourg.active, a.header-nav-slidedown-trigger.country-luxembourg {
    background-position: 15px -477px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-austria, a.global-nav-slidedown-trigger.global-nav-flag.country-austria.active, a.header-nav-slidedown-trigger.country-austria {
    background-position: 15px -48px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-belgium, a.global-nav-slidedown-trigger.global-nav-flag.country-belgium.active, a.header-nav-slidedown-trigger.country-belgium {
    background-position: 15px -1157px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-netherlands, a.global-nav-slidedown-trigger.global-nav-flag.country-netherlands.active, a.header-nav-slidedown-trigger.country-netherlands {
    background-position: 15px -526px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-canada, a.global-nav-slidedown-trigger.global-nav-flag.country-canada.active, a.header-nav-slidedown-trigger.country-canada {
    background-position: 15px -97px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-chile, a.global-nav-slidedown-trigger.global-nav-flag.country-chile.active, a.header-nav-slidedown-trigger.country-chile {
    background-position: 15px -144px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-china, a.global-nav-slidedown-trigger.global-nav-flag.country-china.active, a.header-nav-slidedown-trigger.country-china {
    background-position: 15px -191px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-norway, a.global-nav-slidedown-trigger.global-nav-flag.country-norway.active, a.header-nav-slidedown-trigger.country-norway {
    background-position: 15px -573px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-denmark, a.global-nav-slidedown-trigger.global-nav-flag.country-denmark.active, a.header-nav-slidedown-trigger.country-denmark {
    background-position: 15px -239px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-spain, a.global-nav-slidedown-trigger.global-nav-flag.country-spain.active, a.header-nav-slidedown-trigger.country-spain {
    background-position: 15px -622px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-finland, a.global-nav-slidedown-trigger.global-nav-flag.country-finland.active, a.header-nav-slidedown-trigger.country-finland {
    background-position: 15px -289px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-sweden, a.global-nav-slidedown-trigger.global-nav-flag.country-sweden.active, a.header-nav-slidedown-trigger.country-sweden {
    background-position: 15px -668px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-germany, a.global-nav-slidedown-trigger.global-nav-flag.country-germany.active, a.header-nav-slidedown-trigger.country-germany {
    background-position: 15px -337px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-switzerland, a.global-nav-slidedown-trigger.global-nav-flag.country-switzerland.active, a.header-nav-slidedown-trigger.country-switzerland {
    background-position: 15px -810px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-iceland, a.global-nav-slidedown-trigger.global-nav-flag.country-iceland.active, a.header-nav-slidedown-trigger.country-iceland {
    background-position: 15px -1365px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-ireland, a.global-nav-slidedown-trigger.global-nav-flag.country-ireland.active, a.header-nav-slidedown-trigger.country-ireland {
    background-position: 15px -384px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-united-kingdom, a.global-nav-slidedown-trigger.global-nav-flag.country-united-kingdom.active, a.header-nav-slidedown-trigger.country-united-kingdom {
    background-position: 15px -716px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-portugal, a.global-nav-slidedown-trigger.global-nav-flag.country-portugal.active, a.header-nav-slidedown-trigger.country-portugal {
    background-position: 15px -716px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-liechtenstein, a.global-nav-slidedown-trigger.global-nav-flag.country-liechtenstein.active, a.header-nav-slidedown-trigger.country-liechtenstein {
    background-position: 15px -716px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-france, a.global-nav-slidedown-trigger.global-nav-flag.country-france.active, a.header-nav-slidedown-trigger.country-france {
    background-position: 15px -716px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-poland, a.global-nav-slidedown-trigger.global-nav-flag.country-poland.active, a.header-nav-slidedown-trigger.country-poland {
    background-position: 15px -1206px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-brazil, a.global-nav-slidedown-trigger.global-nav-flag.country-brazil.active, a.header-nav-slidedown-trigger.country-brazil {
    background-position: 15px -1268px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-mexico, a.global-nav-slidedown-trigger.global-nav-flag.country-mexico.active, a.header-nav-slidedown-trigger.country-mexico {
    background-position: 4px -1327px !important;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-asia, a.global-nav-slidedown-trigger.global-nav-flag.country-asia.active, a.header-nav-slidedown-trigger.country-asia, a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-asia, a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-asia.active, a.header-nav-slidedown-trigger.country-rest-of-asia {
    background-position: 15px -856px !important;
}

    a.global-nav-slidedown-trigger.global-nav-flag.country-asia.active, a.global-nav-slidedown-trigger.global-nav-flag.country-asia:hover, a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-asia.active, a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-asia:hover {
        background-position: 15px -834px !important;
    }

a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-world, a.global-nav-slidedown-trigger.global-nav-flag.country--rest-of-world.active, a.header-nav-slidedown-trigger.country--rest-of-world {
    background-position: 15px -916px !important;
}

a.global-nav-flag {
    padding-left: 40px !important;
}

a.header-nav-slidedown-trigger.global-nav-flag {
    padding-left: 29px !important;
}

#global-banner-wrapper .country-rest-of-world:before {
    line-height: 17px;
    font-family: FontAwesome;
    content: none !important;
    position: absolute;
    font-size: 1.8em;
}

.global-nav-slidedown-internal.country-rest-of-world:before {
    left: -1px !important;
}

a.global-nav-flag.country-rest-of-world {
    background: none;
}

a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-world, a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-world.active {
    padding-left: 30px !important;
}

    a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-world::before, a.global-nav-slidedown-trigger.global-nav-flag.country-rest-of-world.active::before {
        left: 20px !important;
        margin: 9px -4px !important
    }

#global-nav-search {
    background-color: #fff;
    border: none;
    position: absolute;
    right: 0;
    height: 51px;
    top: -12px;
    border-top: 6px solid #363844;
    width: 0;
    padding: 5px 10px;
    z-index: 1;
    opacity: 0;
    margin-top: 6px;
    color: #000;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

#global-nav-search:focus {
    outline-color: transparent;
    outline-style: none;
}

#global-nav-search.active {
    opacity: 1;
    display: block;
    width: 60%;
}

#global-nav-btn-search {
    z-index: 2;
    position: relative;
}

.global-nav-slidedown-trigger {
    background-size: 10px auto;
}

/* -- Safari fix */
.ahp-selector-modal .modal-content {
    border: 0 !important;
}

.ahp-selector-modal iframe#selector {
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .global-nav-slidedown-trigger {
        background-size: auto;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .modal-open {
        position: relative !important;
    }
}

.modal-open {
    position: fixed;
    width: 100%;
    padding-right: 0 !important;
}

#global-nav-country-selector-li a.header-nav-slidedown-trigger.global-nav-flag {
    height: auto;
}

.ahp-selector-modal {
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow: hidden !important;
}

    .ahp-selector-modal iframe#selector {
        width: 903px;
        height: 700px;
    }

    .ahp-selector-modal .modal-dialog {
        width: 902px;
        margin: 10px auto;
    }

    .ahp-selector-modal .modal-body {
        padding: 0px;
        overflow: hidden;
    }

@-moz-document url-prefix() {
    .ahp-selector-modal.deep-link .modal-body {
        overflow: hidden;
    }
}

.ahp-selector-modal .modal-header .close {
    left: -2px;
    position: relative;
}
/* Deep Link styles */
.ahp-selector-modal.deep-link-audience iframe#selector, .ahp-selector-modal.deep-link iframe#selector {
    width: 595px !important;
    height: 466px !important;
}

.ahp-selector-modal.deep-link-audience .modal-dialog, .ahp-selector-modal.deep-link .modal-dialog {
    width: 595px !important;
}


.ahp-selector-modal.deep-link-audience.selector iframe#selector, .ahp-selector-modal.deep-link.selector iframe#selector {
    width: 903px !important;
    height: 700px !important;
}

.ahp-selector-modal.deep-link-audience.selector .modal-dialog, .ahp-selector-modal.deep-link.selector .modal-dialog {
    width: 902px !important;
}

/* Deep link styles to target mobile/phone screens */
@media screen and (min-width:0px) and (max-device-width:592px) {
    .ahp-selector-modal.deep-link iframe#selector, .ahp-selector-modal.deep-link-audience iframe#selector, .ahp-selector-modal.deep-link-nocookie iframe#selector {
        width: 100% !important;
        height: 466px !important;
    }

    .ahp-selector-modal.deep-link.selector iframe#selector, .ahp-selector-modal.deep-link-audience.selector iframe#selector, .ahp-selector-modal.deep-link-nocookie.selector iframe#selector {
        width: 100% !important;
        height: 700px !important;
    }

    .ahp-selector-modal.deep-link-audience .modal-dialog, .ahp-selector-modal.deep-link .modal-dialog,
    .ahp-selector-modal.deep-link-audience.selector .modal-dialog, .ahp-selector-modal.deep-link.selector .modal-dialog {
        width: auto !important;
        margin: 5px 0;
    }

    .modal-open .modal {
        overflow: hidden !important;
        margin: 10px;
    }

    .ahp-selector-modal button.close {
        margin-right: 5px;
    }
}

@media screen and (min-width:593px) and (max-device-width:768px) {
    .ahp-selector-modal.deep-link iframe#selector, .ahp-selector-modal.deep-link-audience iframe#selector, .ahp-selector-modal.deep-link-nocookie iframe#selector {
        width: 592px !important;
    }

    .ahp-selector-modal.deep-link-audience .modal-dialog, .ahp-selector-modal.deep-link .modal-dialog {
        width: 592px !important;
    }

    .ahp-selector-modal {
        margin: 5px auto;
    }

        .ahp-selector-modal.deep-link {
            max-width: 592px;
            overflow-y: hidden;
            height: auto;
        }
}

@media only screen and (min-device-width: 593px) and (max-device-width: 1024px) and (orientation: landscape) {
    .ahp-selector-modal .modal-body {
        padding: 0px;
        margin: 0;
    }

    .ahp-selector-modal body {
        margin-left: 0;
    }
}

@media print {
    iframe#selector {
        width: 100% !important;
    }

    ahp-selector-modal.deep-link-audience iframe#selector, .ahp-selector-modal.deep-link iframe#selector {
        height: 700px !important;
    }

    .modal-open {
        position: relative !important;
        width: auto;
        padding-right: 0 !important;
    }

    a.back-to-top {
        display: none;
    }
}

@media print and (-ms-high-contrast: active), print and (-ms-high-contrast: none) {
    .modal.ahp-selector-modal {
        display: none !important;
    }

    .ahp-selector-modal.deep-link-audience, .ahp-selector-modal.deep-link {
        display: none !important;
    }

        .ahp-selector-modal.deep-link-audience iframe#selector, .ahp-selector-modal.deep-link iframe#selector {
            display: none !important;
        }
}

/* end of deep link ahp styles */

.ahp-selector-modal.no-cookies .modal-content {
    background: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

/* Remove IE arrow */
.language-selector::-ms-expand {
    display: none;
}

.language-selector {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    flex: 1;
    padding: 0 .5em;
    cursor: pointer;
    color: #003a68;
    border-radius: .3em;
    border: 1px solid #b2b3b2;
    font-size: 12px;
}

.language-selector--active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.language-selector__wrap {
    position: relative;
    display: block;
    font-size: 14px;
}

    .language-selector__wrap::after {
        position: absolute;
        top: 0px;
        right: 0;
        cursor: pointer;
        pointer-events: none;
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
        color: #b2b3b2;
        height: 27px;
        line-height: 2.2;
    }

    .language-selector__wrap:hover::after {
        color: #006bb4;
    }

    .language-selector__wrap ul.dropdown-menu {
        min-width: 96px;
    }

    .language-selector__wrap ul li {
        float: none !important;
    }

        .language-selector__wrap ul li a {
            color: #000 !important;
            background-color: #fff;
            line-height: 20px !important;
        }

            .language-selector__wrap ul li a:hover {
                color: #fff !important;
            }
/* Srpint 20 fix for HTML 5 DOCTYPE that affected all iframes */
iframe {
    display: block;
}

.btn-group.countries {
    float: left;
    width: 50%;
    min-width: 410px;
    display: block;
}

.btn-group.investortype {
    width: 50%;
    min-width: 410px;
    float: left;
    display: block;
}

    .btn-group.countries .btn, .btn-group.investortype .btn {
        width: 440px;
    }

.btn-group.go {
    float: right;
    width: 4%;
    padding-top: 43px;
    min-width: 45px;
}

/*.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}*/

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
/*    min-width: 410px;*/
    margin: 0;
    list-style: none;
    font-size: 16px;
    text-align: left;
    background-color: white;
    border: 1px solid #b2b2b2;
    border-radius: 0;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: border-box;
}

    .dropdown-menu.pull-right {
        right: 0;
        left: auto;
    }

    .dropdown-menu .divider {
        height: 1px;
        margin: 9px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }

    .dropdown-menu > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.428571429;
        color: #333333;
        white-space: nowrap;
    }

        .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
            text-decoration: none;
            color: #262626;
            background-color: whitesmoke;
        }

    .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
        color: white;
        text-decoration: none;
        outline: 0;
        background-color: #428bca;
    }

    .dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
        color: #999999;
    }

        .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
            text-decoration: none;
            background-color: transparent;
            background-image: none;
            filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
            cursor: not-allowed;
        }

.show > .dropdown-menu {
    display: block;
}

.show > a {
    outline: 0;
}

.dropdown-menu-right {
    left: auto;
    right: 0;
}

.dropdown-menu-left {
    left: 0;
    right: auto;
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.428571429;
    color: #999999;
}

.dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    float: right;
    margin-top: 8px;
}

.global-nav-slidedown .dropdown-toggle {
    background: #fff;
    color: #000;
    text-align: left;
}

.disclaimer h1 {
    left: 0px;
    margin-bottom: 18px;
    width: 100%;
    font-family: HelveticaNeue, 'Helvetica LT Std', sans-serif;
    font-size: 18px;
    color: #FFFFFF;
}

.disclaimer-button {
    text-align: center;
    padding-top: 10px;
}

#global-banner-wrapper .scrollable-menu {
    height: auto !important;
    max-height: 200px;
    overflow-x: hidden;
    width: 300px;
    position: relative !important;
    transform: none !important;
    cursor: pointer;
    min-width: 408px;
}

.global-nav-slidedown-internal h4 {
    font-family: "Open Sans","Helvetica",sans-serif;
    padding-bottom: 10px;
    color: #000 !important;
    margin: 0px;
    font-size: 14px;
}

.btn-group.countries.show .dropdown-toggle, .btn-group.investortype.show .dropdown-toggle {
    background-color: #ffffff !important;
    color: #363844 !important;
}


.btn-group.countries .dropdown-toggle:hover, .btn-group.investortype .dropdown-toggle:hover {
    background-color: #ffffff !important;
    color: #363844 !important;
}

.btn-group.investortype button.btn.btn-default.dropdown-toggle,
.btn-group.countries button.btn.btn-default.dropdown-toggle {
    border: 1px solid #ccc;
    border-radius: 5px;
    text-transform: uppercase;
}

.btn-group.investortype button.btn.btn-default.dropdown-toggle:focus,
.btn-group.countries button.btn.btn-default.dropdown-toggle:focus {
    outline: none;
}

#ahp-lang-dropdown {
    width: auto;
    min-width: auto;
    padding: 0;
    margin-top: 0px;
    border-top: 0;
}

    #ahp-lang-dropdown li {
        display: block;
        float: none;
        color: #000;
    }

    #ahp-lang-dropdown.dropdown-menu li a, #ahp-lang-dropdown.dropdown-menu li a:hover {
        color: #000;
        background-color: #fff;
        padding: 0 15px;
    }

        #ahp-lang-dropdown.dropdown-menu li a:hover {
            color: #fff;
            background-color: #000;
            padding: 0 15px;
        }

        #ahp-lang-dropdown.dropdown-menu li a.active {
            color: #fff;
        }

.btn-group.countries .dropdown-toggle:after, .btn-group.investortype .dropdown-toggle:after {
    content: initial;
}

#global-nav .fa.fa-angle-up, #global-nav .fa.fa-angle-down {
    font-size: 26px;
    top: 4px;
    left: 3px;
    position: relative;
    line-height: 26px;
}

.dropdown-toggle .fa:before {
    font-size: 26px;
}

.fa.fa-angle-up.disable, .fa.fa-angle-down.disable {
    color: #838288;
}

.dropdown-toggle span {
    float: right;
}

.go a.btn {
    border: none;
    margin-left: 15px;
    color: #fff !important;
    background-color: #838288;
    border-radius: 10px !important;
    padding: 9px 12px;
    font-weight: bold;
    font-size: 16px;
}

    .go a.btn.disabled, .go a.btn.disabled:hover {
        color: #fff !important;
        background-color: #838288 !important;
    }

    .go a.btn.active {
        color: #fff !important;
        background-color: #17468F;
    }

        .go a.btn.active:hover {
            color: #17468F !important;
            background-color: #fff;
        }

.global-nav-input-group input[type="submit"]:hover,
.global-nav-slidedown a.btn:hover {
    background-color: #17468F;
}

/*make country and invester type dropdown absolute*/
#global-banner-wrapper .btn-group {
    position:relative;
}

#global-banner-wrapper .dropdown-menu.scrollable-menu {
    position: absolute !important;
    z-index: 9999;
}

.active.overflow-visible, .active .overflow-visible { /* to force overflow when dropdown is opened, else dropdown will not be visible. */
    overflow: visible !important;
}

.scrollable-menu li a, .btn-group.countries .dropdown-toggle, .btn-group.investortype .dropdown-toggle {
    font-family: "Open Sans","Helvetica",sans-serif;
    font-size: 14px;
    color: #333436;
    padding: 6px 12px;
    line-height: 23px;
    border-radius: 0px;
    padding-top: 8px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.investortype__option {
    font-weight: 400;
    font-family: Open Sans, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 23px;
    padding: 8px 12px 6px;
    text-transform: uppercase;

    &:hover {
        background-color: #f5f5f5;
        color: #000;
        text-shadow: none;
    }
}

.disclaimer-text p {
    font-family: HelveticaNeue,Helvetica,sans-serif;
    color: #333436;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
}

.disclaimer-button {
    margin-top: 30px;
}

    .disclaimer-button a.agree {
        background-color: #17468F;
        padding: 12px 20px;
        cursor: pointer;
        transition: background-color .4s ease-out, color .4s ease-out;
        border: 1px solid #17468F;
    }

        .disclaimer-button a.agree:hover {
            background-color: #fff;
            color: #17468F !important;
        }

    .disclaimer-button a.decline {
        background-color: transparent !important;
        border-radius: 6px !important;
        border: 1px solid #323232 !important;
        padding: 12px 20px !important;
        cursor: pointer !important;
        color: #323232 !important;
        transition: background-color .4s ease-out, color .4s ease-out;
    }


    .disclaimer-button a.decline:hover {
        background-color: #323232 !important;
        color: #fff !important;
    }


    .disclaimer-button a {
        color: #fff;
        border-radius: 10px;
        font-family: HelveticaNeue,Helvetica,sans-serif;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
    }

        .disclaimer-button a:first-child {
            margin-right: 20px;
        }

.overlay-image {
    height: 600%;
    position: absolute;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 10;
    height: 100vh; //for new layout, percentage does not work as this component is within the header, so we use vh to cover the screen
}


#no-funds-close {
    font-size: 15px;
    float: right;
    padding-right: 4px;
    padding-top: 4px;
}

.no-funds-wrapper {
    text-align: center;
    width: 970px;
    position: relative;
    margin: 0 auto;
}

.global-nav-slidedown-internal .disclaimer .disclaimer-text {
    font-family: HelveticaNeue, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #363844;
}

.global-nav-slidedown-internal .disclaimer h1#disclaimer-header {
    font-family: HelveticaNeue-Bold;
    font-size: 18px;
    color: #363844;
}

#global-banner-wrapper .global-nav-slidedown-trigger.global-nav-flag {
    font-family: HelveticaNeue, Helvetica, sans-serif;
    font-size: 14px;
    color: #363844;
    text-align: right;
}

#global-banner-wrapper #global-nav-btn-search {
    font-family: HelveticaNeue, Helvetica, sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    text-align: right;
}

.global-nav-slidedown-internal .welcome-header {
    font-family: "Open Sans","Helvetica",sans-serif;
    font-size: 24px;
    color: #000;
    padding-bottom: 15px;
    font-weight: bold;
}

.global-nav-slidedown-internal .welcome-description {
    font-family: "Open Sans","Helvetica",sans-serif;
    font-size: 14px;
    color: #000;
    line-height: 18px;
    padding-bottom: 15px;
}

ul.scrollable-menu li a:hover {
    color: #000 !important;
    text-shadow: none !important;
}

.subscription-form-success-us .btn-group.go {
    padding-top: 32px !important;
}

.subscription-form-success-ucits .btn-group.go {
    padding-top: 32px !important;
}

.subscription-form-success-us #global-banner-wrapper .scrollable-menu {
    width: 408px;
}

.subscription-form-success-ucits #global-banner-wrapper .scrollable-menu {
    width: 408px;
}

/* Mobile */
@media all and (max-width: 991px) {
    #mobile-nav .global-nav-slidedown-trigger {
        line-height: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        text-transform: uppercase;
    }

    .hideDesktop {
        display: none;
    }

    #ahpMobile {
        background: #333436 !important;
        color: #fff;
        font-size: 13px;
        width: 100%;
        height: 18px;
        padding-bottom: 27px;
        border-bottom: 4px solid #333436;
    }

    #global-banner-wrapper {
        position: static!important;
        z-index: 1040;
    }

    #global-nav-slidedown-wrapper {
        /*padding-top: 42px;*/
    }

    #global-nav-slidedown-wrapper .global-nav-slidedown.active {
        min-height: calc(100vh - 42px);
    }

    #global-banner-wrapper .global-nav-slidedown .global-nav-slidedown-internal {
        width: 94%;
        min-width: 300px;
    }

    .btn-group.countries .btn, .btn-group.investortype .btn {
        width: 100%;
    }

    .btn-group.investortype {
        padding-top: 30px;
        width: 100%;
        min-width: 300px;
    }

    .btn-group.countries {
        padding-top: 20px;
        width: 100%;
        min-width: 300px;
    }

    .btn-group.go {
        width: 100%;
        min-width: 300px;
    }

    #global-banner-wrapper .global-nav-slidedown .go a.btn {
        margin-left: 0px;
        width: 100%;
        display: inline-block;
        text-align: center;
        background: #17468F;
    }

    #global-banner-wrapper .global-nav-slidedown.active {
        max-height: 100vh!important;
    }

    .disclaimer, .disclaimer-home, .ETFdisclaimer {
        margin: 5px 16px;
    }

    #global-banner-wrapper .scrollable-menu {
        height: 100%;
        width: 100%;
        min-width: 0 !important;
    }

    .disclaimer-deeplink #mobile {
        display: none;
    }

    .disclaimer-deeplink #ahpMobile {
        display: block !important;
    }

    .disclaimer-deeplink .overlay-image {
        display: none;
    }

    .disclaimer-deeplink .mainWrapper, .disclaimer-deeplink .wrapper {
        display: none;
    }

    .disclaimer-deeplink #closeAhpModal {
        display: none;
    }

    #global-banner-wrapper .global-nav-slidedown a {
        float: left;
        width: 100%;
        box-sizing: border-box;
    }

    #global-nav-no-funds {
        top: 41px;
        padding-bottom: 12px;
        position: relative;
    }

        #global-nav-no-funds .no-funds-wrapper {
            width: 97%;
        }

            #global-nav-no-funds .no-funds-wrapper #no-funds-close {
                padding-top: 0px;
            }

    .global-nav-slidedown-internal .disclaimer .disclaimer-text {
        height: 340px;
        overflow: scroll;
    }

    .global-nav-slidedown-internal .disclaimer .disclaimer-button a {
        float: inherit !important;
    }
}

.disclaimer, .row.disclaimer-home, .row.ETFdisclaimer {
    margin: 15px 5px !important;
    width: 100%;
}

/**********
New AU style
**********/
body.no-overflow {
    overflow-y: hidden;
}

/*header {
    display: none;
}*/

.page-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0);
    z-index: -1;
    position: absolute;
    transition: background .3s ease-in-out, z-index .3s ease .3s;
}

.page-overlay.active {
    background: rgba(0,0,0,0.8);
    z-index: 10;
    transition: background .3s ease-in-out;
}

#global-banner-wrapper {
    background-color: #fff !important;
    position: relative;
    z-index: 9999;
}

#global-banner-wrapper #global-nav-wrapper {
    background-color: #fff !important;
    max-width: 1300px;
    width: 100%;
}

#global-banner-wrapper #global-nav-wrapper input{
    font-family: "Open Sans","Helvetica",sans-serif;
    letter-spacing: 1px;
    padding-right: 100px;
}

#global-nav-wrapper ul#global-nav {
    letter-spacing: 1px;
}

#global-nav-wrapper ul#global-nav li a {
    font-family: "Open Sans","Helvetica",sans-serif;
    text-transform: uppercase;
    color: #000 !important;
}

input#global-nav-search {
    border-bottom: 1px solid #ccc;
    padding: 5px;
}

#global-nav-wrapper ul#global-nav #global-nav-search-li a:hover {
    background-color: #ccc;
}

.wrapper.home div.utility-links-global {
    display: none;
}

#global-nav-wrapper .header-search-overlay {
    display: block;
    max-height: 0;
    transition: background-color .3s ease-in-out;
}

#global-nav-wrapper .header-search-overlay.active {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    z-index: 1000000;
    max-height: 100vh;
}

    #global-nav-wrapper .header-search-overlay>* {
        display: none;
    }

    #global-nav-wrapper .header-search-overlay.active > * {
        display: block;
    }

    #global-nav-wrapper .header-search-overlay .white-backdrop {
        width: 100%;
        max-height: 470px;
        min-height: 400px;
        background-color: #fff;
        border-bottom: 5px solid #17468f;
        background-image: url(/Static/au/images/abstract-background.png);
        background-size: cover;
        background-position: center;
    }

    #global-nav-wrapper .header-search-overlay .white-backdrop .input-container {
        width: 80%;
        margin: auto;
        display: block;
    }

        #global-nav-wrapper .header-search-overlay .white-backdrop .input-container input {
            all: unset;
            display: block;
            width: 100%;
            border: 1px solid #ccc;
            padding: 15px;
            border-radius: 25px;
            padding-right: 70px !important;
            right: 70px;
            position: relative;
            margin-top: 100px;
            transition: background-color .3s ease-in-out;
            letter-spacing: 2px !important;
            background-color: #fff;
        }

        #global-nav-wrapper .header-search-overlay .white-backdrop .input-container input:hover {
            background-color: #eee;
        }

        #global-nav-wrapper .header-search-overlay .white-backdrop .input-container .fa.fa-search {
            float: right;
            font-size: 32px;
            font-weight: 700;
            position: relative;
            top: -40px;
            cursor: pointer;
            transition: color .3s ease-in-out;
        }

        #global-nav-wrapper .header-search-overlay .white-backdrop .input-container:hover .fa.fa-search {
            color: #17468f;
        }

        #global-nav-wrapper .header-search-overlay .white-backdrop .input-container .input-placeholder {
            position: relative;
            top: -31px;
            font-size: 22px;
            transition: margin-left .3s ease-out, opacity .4s ease-out;
            pointer-events: none;
            left: -52px
        }

        #global-nav-wrapper .header-search-overlay .white-backdrop .input-container .input-placeholder.active {
            margin-left: 200px;
            opacity: 0;
        }

        #global-nav-wrapper .header-search-overlay .white-backdrop .filler {
            width: 100%;
            height: 1px;
            display: block;
        }

    #global-nav-wrapper .header-search-overlay .white-backdrop .close-btn-container {
        padding-top: 10px;
        float: right;
        margin-right: 20px;
        font-size: 24px;
        cursor: pointer;
        transition: color .3s ease-in-out;
        display: inline-block
    }

    #global-nav-wrapper .header-search-overlay .white-backdrop .close-btn-container:hover {
        color: #17468f;
    }

        #global-nav-wrapper .header-search-overlay .white-backdrop .close-btn-container .fa.fa-times{
            margin-left: 10px;
        }

.popular-items-container .title-popular {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
}

#autocomplete-results {
    ul {
        position: static !important;
        display: inline-block;
        width: 100% !important;
        margin-left: -50px;

        li {
            line-height: 1.666em !important;
        }

        a {
            clear: both;
            display: block;
            float: left;
            width: inherit;
            line-height: 1.666;
            padding-left: 2rem;
            text-overflow: ellipsis;

            &.ui-menu-item {
                &:hover {
                    background-color: #e7ecf2;
                }
                div {
                    margin: .5em;
                }

                .highlight {
                    padding: 0;
                    color: #001973;
                }
            }

            &:last-child {
                padding: 1rem 0 1rem .75rem;
                border-top: 1px solid #c0c2c5 !important;
            }
        }

        .group-title {
            clear: both;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1rem;
            padding: 5px 15px 0;
        }
    }
}

#autocomplete-mobile-results {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;

    &.autocomplete-mobile-results__ready {
        background-color: white;
        width: calc(90% - 4px);
        margin: -12px auto -12px;
        padding: 35px 10px 0;
    }

    ul {
        margin: auto;
        padding: 10px 40px 0;
        list-style-type: none;

        a {
            color: inherit;
            line-height: 1.8rem;

            &:last-child {
                line-height: 4rem;
                text-transform: uppercase;
            }

            .highlight {
                padding: 0;
                color: #0046ff;
            }
        }

        .group-title {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.8rem;
            padding: 5px 0;
        }
    }
}

.autocomplete-dropdown-open {
    border-radius: 50px 50px 0 0!important;
}

#global-nav-wrapper ul#global-nav li .popular-items-container a {
    padding: 0;
    display: inline-block;
    float: left;
    clear: both;
    transition: color .2s ease-in-out;
}

    #global-nav-wrapper ul#global-nav li .popular-items-container a:hover {
        text-decoration: underline;
        font-weight: 700;
        color: #17468f !important;
        background-color: unset !important;
    }

body {
    transition: filter .3s ease-in-out;
}

body.blur{
    overflow: hidden;
}

.wrapper {
    transition: filter .4s ease-in-out;
}

.wrapper.blur {
    filter: blur(2px);
}


@media screen and (max-width: 768px) {
    div #global-nav-slidedown-wrapper {
        display: block;
    }

    #global-banner-wrapper .global-nav-slidedown .global-nav-slidedown-internal {
        min-width: unset;
    }

    .btn-group.countries {
        min-width: unset;
        width: 100%;
    }

    .btn-group.investortype {
        min-width: unset;
        width: 100%;
    }
}

/* position fix (sticky) desktop menu */
@media screen and (min-width: 992px) {
    #global-banner-wrapper {
        position: fixed;

        .scrollable-menu {
            min-width: 440px;
        }
    }

    .page-overlay {
        height: unset;
    }

    .page-overlay.active {
        height: 10000px;
    }

    .menu-container-inner {
        max-width: 1300px;
        margin: auto;
    }
}


/* Investment Menu Hover  */
div.marketvectors .drop {
    width: 100%;

    &.ss-overflow.selected.smaller-drop-element {
        max-height: 400px;
    }

    .drop_contetn {
        .title-part.new {
            display: inline-block;
            margin-right: 50px;
            position: relative;
            top: 4px;
            margin-bottom: 0;
            max-width: 350px;
            color: #17468f;
        }

        .title-part.selected {
            border-bottom: 4px solid #17468f;
        }

        .title-block {
            display: block;
            border-bottom: 4px solid #ccc;
            margin-bottom: 10px
        }
        /*    .first, .last {
            width: 100%;
            display: inline-block;
        }*/

        ul.lists {
            display: inline-block;
            margin-top: 20px;
        }

        .lists li span:first-child {
            width: 400px;
        }

        ul.lists.aquamarine li.colored, ul.lists li {
            background: none;
        }

        .fund-section {
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-content: flex-start;
            height: 660px;
        }

        .fund-section.selected {
            display: flex;
        }

        .fund-section.fixed.income,
        .fund-section.alternative.assets {
            height: 100px;
            flex-direction: initial;
        }

        ul.lists li {
            padding: 0;
        }
    }
}

/* End of Investment Menu Hover */