@import '../style/common';

.billboard-block {
  $billboard-block-switch-breakpoint: md;

  &__wrapper {
    position: relative;
    z-index: 0;
  }

  &__heading {
    padding: 0 2.5rem;

    @include media-breakpoint-down(md) {
      padding: 0 space(4);
    }

    @include media-breakpoint-down(sm) {
      padding: 0 2.5rem;
    }
  }

  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;

    &--overlay {
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      @include media-breakpoint-down(sm) {
        background-position: right;
        background-size: cover;
      }
    }
  }

  &__badge {
    border-radius: 0;
    background-color: $white;
    color: $primary;
    letter-spacing: $text-util-sm-letter-spacing;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }

  &__image-icon {
    color: $white;
    padding: space(1);

    svg {
      position: absolute;
      bottom: space(3);

      @include media-breakpoint-down(sm) {
        position: initial;
      }
    }
  }

  &__container {
    @include media-breakpoint-up(lg) {
      padding: 0 2rem;
    }
    @include media-breakpoint-up(xs) {
      padding: 0 space(4);
    }
  }

  &__col {
    @include media-breakpoint-up($billboard-block-switch-breakpoint) {
      padding: 0;
    }

    &:last-child {
      .billboard-block__link-content {
        border: 0;
      }
    }
  }

  &__card {
    position: relative;
    height: 100%;

    &-link {
      display: inline-block;
      color: $white;
      height: 100%;

      @include hover-focus {
        color: $white;
        text-decoration: none;

        .billboard-block__title {
          text-decoration: underline;
        }
      }
    }

    @include media-breakpoint-up($billboard-block-switch-breakpoint) {
      padding: 0;
    }
  }

  &__title {
    line-height: $h4-line-height;

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  &__link-content {
    padding: 0 space(4);
    border-right: 0.063rem solid $white;
    height: 100%;

    @include media-breakpoint-down($billboard-block-switch-breakpoint) {
      padding: 0 space(3);
    }

    @include media-breakpoint-down(sm) {
      border-right: 0;
      border-bottom: 0.063rem solid $white;
      padding: 2rem space(3);
    }
  }

  &__author {
    margin: space(4) 0;

    span {
      font-size: $font-size-xxs;
      font-weight: $font-weight-semi-bold;
      line-height: $h4-line-height;
      letter-spacing: $text-util-sm-letter-spacing;
      display: block;
    }
  }
}
