
.btn.btn-primary:not(.no-animation) {
    transition: 200ms ease-in-out;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: none;

    &:after, &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
        height: 100%;
        clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
    }

    &:after {
        width: 100%;
        z-index: -2;
    }

    &:before {
        width: 0%;
        background-color: #0ab2bf;
        transition: 200ms ease-in-out;
        z-index: -1;
    }

    &:hover {
        color: #fff;
        border: none;
        background-color: #17468f;

        &:before {
            width: 150%;
        }
    }
}

/*make sure that fixed back to top animations are not broken*/
.btn.btn-primary.table-sticky.back-to-top {
    position:absolute;
}

.btn.btn-primary.sticky-fixed.back-to-top {
    position:fixed;
}