@import '../style/common';

.rich-text-block {
  &.text-white {
    a {
      @include plain-hover-focus {
        color: $white;
      }
    }
  }

  &--insight-content {
    a {
      color: $body-color;
      border-bottom: solid 2px $blue;
      transition: color $trans-duration $trans-easing, background-color $trans-duration $trans-easing;

      padding-left: 0.125em;
      padding-right: 0.125em;

      @include hover-focus {
        background-color: $blue;
        color: $white;
        text-decoration: none;
      }
    }

    blockquote {
      @include make-heading(2);
      position: relative;
      padding-left: space(4);
      margin: space(5) auto;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: $accent;
        left: 0;
        top: 0;
      }
    }
  }
}
