@import '../style/common';

.jumbotron {
  background-color: $jumbotron-bg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $jumbotron-color;
  padding: $jumbotron-padding-sm;

  @include media-breakpoint-up(lg) {
    padding: $jumbotron-padding;
  }

  &__quote {
    margin-bottom: space(3);

    &:before {
      content: '“';
      display: block;
    }
  }

  &__heading {
    margin-bottom: space(4);
  }

  &__lead {
    margin-bottom: space(4);
  }

  &__cta {
    margin-top: space(4);
  }
}
