@import '../style/common';

.tab-nav {
  background-color: $gray-1;
  padding-top: space(3);

  &__list-item &__link {
    background-color: $nav-pills-link-bg;
    border-radius: 0;
    color: $nav-pills-link-color;
    padding: space(4) space(5);
    text-transform: uppercase;
    font-size: $font-size-xxs;
    font-weight: $font-weight-semi-bold;

    @include hover-focus {
      background-color: darken($nav-pills-link-bg, 10%);

      &.active {
        background-color: darken($nav-pills-link-active-bg, 10%);
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 1.5rem 2rem;
    }
  }
}
