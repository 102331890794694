@import '../style/common';

.insight-card {
  background-color: $body-bg;
  display: block;
  padding: space(4) space(4); // $card-spacer-y $card-spacer-x;
  position: relative;
  z-index: 0;

  @include plain-hover-focus {
    color: $body-color;
    text-decoration: none;
  }

  &:before {
    background-image: url('#{$images-path}abstract-background.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity $trans-duration $trans-easing;
  }

  &__image-container {
    position: relative;
  }

  &__image {
    margin-bottom: space(3);
  }

  &__image-icon {
    background-color: $primary;
    color: $white;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: space(2);
    transition: color $trans-duration $trans-easing;
  }

  &__eyebrow {
    margin: 0 space(6) space(3) 0;
  }

  &__contributors {
    position: absolute;
    top: space(4);
    right: space(4);
  }

  &__contributor {
    display: inline-block;
    margin-left: space(1);
  }

  &__description {
    margin-bottom: space(3);
  }

  &__cta {
    display: inline-block;
    margin-right: space(4);

    &-label {
      color: $link-color;
    }

    &-icon {
      color: $dark-blue;
      vertical-align: middle;
    }
  }

  @include hover-focus {
    z-index: 1;

    .insight-card__image-icon {
      color: $teal;
    }

    &:before {
      opacity: 1;
    }
  }
}
