@import '../style/common';

.secondary-nav {
  height: 0;

  &__inner {
    visibility: hidden;

    &.sticky {
      visibility: visible;
      position: fixed;
      top: $site-header-height;
      left: 0;
      right: 0;
      z-index: 1050;
      box-shadow: 0 0.3125rem 0.225rem 0 rgba($gray-6, 0.2);
    }
  }

  &__link {
    @include padding(null space(5));
    color: $white;

    @include hover-focus {
      color: $white;
      text-decoration: underline;
    }

    &.active {
      background-color: $white;
      color: $body-color;
      font-weight: $font-weight-bold;
    }
  }

  &__nav-list {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    &__inner {
      background-color: $tertiary;
    }

    &__nav-list-mobile {
      display: none;
    }

    &__nav-list {
      display: flex;
    }
  }
}
