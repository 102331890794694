// AU Blog Post Page - make related media right grid sticky
.sticky-top-main {
    position: sticky;
    top: 108px;
}

// AU News (temporary) - remove after category dropdown is moved to block level
@media (max-width: 768px) {
    .page-class-insights {
        .section-header {
            margin: 0 -1rem;
        }
    }
}

// AU Insights and Viewpoint grid - reduce spacing in the grid cards
.grid-card__body {
    margin-top: 0;
}
.grid-card__header {
    margin-bottom: 0.5rem;
}

// AU News - coloured background for category title
.media-coverage-card__type {

    &.portfolio-update {
        background-color: #FF9200;
    }

    &.media-release {
        background-color: #0BB1BF;
    }

    &.announcement {
        background-color: #932092;
    }

    color: white;
    border-radius: 5px;
    padding: 0.1rem 0.5rem;
    display: inline;
}

// AU Insights - remove read time, reduce space in hero section of blog post page
.text-hero__divider {
    margin-top: 0.5rem;
}

//AU right widget to move below disclaimer in mobile/tab view
@media (max-width: 992px) {
    .right-content {
        order: 3;
        margin-bottom: 1.5rem;
    }
}

//AU Insights back to top - move to a separate stylesheet if entire nav is adopted sitewide
.expandable-nav #back-to-top-wrapper .scroll-to-top.back-to-top.btn {
    bottom: 85px;

    &.anchored-to-bottom {
        /* AI -- this is not needed anymore since the "Help Button", which used to be at the far bottom is now moved to the expandable nav. */
        bottom: 0;
    }
}

.expandable-nav.visible {
    .expandable-nav__blocks {
        .expandable-nav__block.can-be-hidden {
            &:hover, &.active {
                background-color: #17468f;
            }
        }
    }
}

@media (max-height: 450px) and (orientation: landscape), (max-width: 767px) {
    .expandable-nav {
        &__block.show {
            position: relative;
        }

        #back-to-top-wrapper .scroll-to-top.back-to-top.btn {
            border: 0;
            height: 50px;
            justify-content: center;
            width: 50px;

            > svg {
                height: 25px;
                width: 25px;
            }

            > .text-util-sm {
                display: none;
            }
        }
    }
}


//AU Insights grid card summary fix
.grid-card__body {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 125px; //roughly the size of 5 lines as per the font size and line spacing used here, change this as per the number of lines in the line clamp
}

//AU Insights Blog Post Pages
.byline__author-text {
    font-size: 0.875rem;
}
.video-playlist__video-wrapper {
    flex: 0 0 auto;
    max-width: 100%;
}
.blog-post.content {
    table, td, th, tr {
        border: 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    p {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

//AU Insights Breadcrumb Dropdown (dropdown on a separate line)
.breadcrumb.dropdown {
    display: inline-block;

    .dropdown__button {
        margin-left: 0.5rem;

        &:before {
            display: none;
        }

        .dropdown__label, .dropdown__icon {
            color: #323232;
        }
    }
}

//AU Insights Author Page Pillboxes
.insights-bio {
    &__pillboxlist {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 1rem;
        padding: 0.5rem;
    }

    &__pillboxitems {
        flex: 0 1 auto;
        color: #fff;
        background-color: navy;
        border: 1px solid navy;
        border-radius: 5px;
        padding: 0.25rem 0.5rem;
    }
}


//AU Insights widgets
.textWidget {
    margin-top: 18px;
    border: 2px solid #000;
    padding: 20px 20px 10px 18px;
    text-align: left;

    a {
        color: #000;
    }

    span {
        line-height: 1.1315;
    }
}

//AU Insights pullquotes
aside.pullquote {
    width: 40%;
    padding-left: 1.2rem;
    margin-left: .5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    float: right;
    font-style: italic;
    font-size: 18.5px;
    font-family: 'Open Sans', Arial, Verdana;
    color: #58595b;
}

//AU Insights Page Disclaimer
.disclaimer-au {
    color: #808080;
    font-size: 8pt
}

// Styles for Search bar in insights grid for AU Insights page
.insights-grid {
    .search-bar {
        position: relative;
        padding: 10px 0;
        margin-left: 50px;

        #insights-search {
            border: 1px solid #ccc;
            font-size: 12px;
            font-family: "Open Sans", "Helvetica", sans-serif;
            padding: 0.5rem;
            height: fit-content;
        }
    }

    .reset-insights {
        padding: 8px 0;
    }
}
