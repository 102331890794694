@import '../style/common';

.site-footer {
  color: $white;

  &__nav {
    background-color: $tertiary;
    padding: space(5) 0;
    text-align: center;
  }

  &__social-list,
  &__nav-list {
    @include list-unstyled;
  }

  &__social-list {
    border-bottom: $border-width solid $white;
    display: inline-block;
    margin-bottom: space(4);
    padding: 0 space(5) space(4);
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    &-item {
      display: inline-block;

      & ~ & {
        margin-left: space(2);
      }
    }
  }

  &__nav-list {
    margin-bottom: 0;
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    &-item {
      margin-bottom: space(3);

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        display: inline-block;
      }

      & ~ & {
        @include media-breakpoint-up(md) {
          margin-left: space(4);
        }
      }
    }
  }

  &__social-link,
  &__nav-link {
    @include plain-hover-focus {
      color: $white;
    }
  }

  &__social-link {
    font-size: $font-size-lg;
  }

  &__content {
    background-color: $black;
    padding: space(6) 0;
  }

  &__legal {
    margin-top: space(5);
  }
}
