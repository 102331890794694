@import '../style/common';

.search-results {
  &__header {
    @include make-row;
    margin-bottom: space(4);
    padding-bottom: space(4);
    position: relative;

    &:after {
      content: '';
      border-bottom: $border-style;
      display: block;
      position: absolute;
      right: $grid-gutter-width / 2;
      bottom: 0;
      left: $grid-gutter-width / 2;
    }
  }

  &__meta,
  &__form {
    @include make-col-ready;
    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }
  }

  &__results-count {
    color: $tertiary;
    margin-bottom: space(2);
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__label {
    @include sr-only;
  }

  &__input {
    width: 100%;
    margin-right: space(3);
  }

  &__list {
    @include list-unstyled;
  }
}
