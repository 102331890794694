﻿/****
    SECTION 7 - Background images
    These override the VShift CSS preventing unnessary 404s from the CSS.
****/

.alert-bar {
    background-image: url(/globalassets/home/us/common/share-form-bg.svg);
    background-position: 80% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.media-coverage-card__toggle:before {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/abstract-background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.insight-card:before, .download-card:before {
    background-image: url(/globalassets/home/us/common/abstract-background.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
}

.event-registration__content {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/abstract-background.png);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.subscribe-block__content-bg-inner:after {
    background-image: url(/globalassets/home/us/common/abstract-background-light.svg);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.preview-card:before, .search-results-card:before, .interactive-image:before {
    background-image: url(/globalassets/home/us/common/abstract-background.png);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.submit-block {
    background-color: #001e6d;
    background-image: url(/globalassets/home/us/common/abstract-background-tertiary.svg);
    background-position: 35% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.capabilities-insight-card__heading {
    background-color: #001e6d;
    background-image: url(/globalassets/home/us/common/abstract-background-tertiary.svg);
    background-size: cover;
    background-position: 25% 50%;
    background-repeat: no-repeat;
}

.promo-card--dark {
    background-color: #001e6d;
    background-image: url(/globalassets/home/us/common/promo-background-dark.svg);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.find-an-etf__select-block {
    background-color: #001e6d;
    background-image: url(/globalassets/home/us/common/find-an-etf-main-background.svg);
    padding: 1.5rem;
    background-position: 35% 96%;
}

.find-an-etf__content {
    background-image: url(/globalassets/home/us/common/find-an-etf-content-background.svg);
    background-position: 23% 0%;
}

.site-header__submenu {
    background-image: url(/globalassets/home/global/submenu-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
}

.subscribe-block__content:after {
    background-image: url(/globalassets/home/corp/common/abstract-background-light.svg);
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
}

.slick-loading .slick-list {
    background: #fff url("/static/fonts/slick/ajax-loader.gif") center center no-repeat;
}

.fund-card {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/abstract-background.png);
    background-position: 60% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.your-information__header {
    background-color: #001e6d;
    background-image: url(/globalassets/home/us/common/abstract-background-tertiary.svg);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    color: #fff;
    padding: 1.5rem;
}

.fund-card--dark {
    color: #fff;
    background-color: #001e6d;
    min-height: 225px;
    background-image: url(/globalassets/home/us/common/abstract-card-background-dark.svg);
}

.media-coverage-card .media-coverage-card__toggle:before {
    background-image: url(/globalassets/home/corp/common/abstract-background-light.svg);
}

.share-with-a-friend-form__content {
    background-image: url(/globalassets/home/us/common/form-bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.attachment-block__content-bg-inner:after {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/share-form-bg.svg);
    background-position: 64% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.attachment-block.dark .attachment-block__content-bg-inner:after {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/strip-background-dark.svg);
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 786px) {
        min-height: 7rem;
    }

    @media (max-width: 768px) {
        background-image: url(/globalassets/home/us/common/strip-background-dark.svg);
        background-position: 71% 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.register-form__content {
    background-color: #f0f3f6;
    background-image: url(/globalassets/home/us/common/form-bg.svg);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
}

.utility-menu__body {
    background-image: url(/globalassets/home/us/common/utility-bg.svg);
    background-size: 100vh;
}

.thought-leaders-card:before {
    background: url(/globalassets/home/us/common/abstract-background.svg) no-repeat center;
    background-size: cover;
}
/* Icons */
@font-face {
    font-family: "slick";
    src: url("/static/fonts/slick/slick.eot");
    src: url("/static/fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("/static/fonts/slick/slick.woff") format("woff"), url("/static/fonts/slick/slick.ttf") format("truetype"), url("/static/fonts/slick/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@media (min-width: 992px) {
    .carousel-block__item {
        &:before, &:hover:before, &:focus:before {
            background-image: none;
            background-color: #f0f3f6;
        }
    }
}
