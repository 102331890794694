.link-farm {

    &__list-title {
        font-size: 1.125rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0.5rem;
            min-height: 42px;
            display: flex;
            align-items: start;
            align-items: flex-start;
        }
    }

    &__link {
        margin-bottom: 0.25rem;
    }

    &__outer-container {

        &:not(:last-child) {
            .link-farm__inner-container {
                @include media-breakpoint-up(lg) {
                    border-right: $border-style;
                }
            }
        }
    }

    &__inner-container {
    }
}
