﻿/*
     VIDEOS
*/

// Issue with the vjs player.
@media all and (min-width:992px) {
    .video-no-iframe video.vjs-tech {
        min-height: 370px;
    }
}

@media all and (min-width:1200px) {
    .video-no-iframe video.vjs-tech {
        min-height: 451px;
    }
}

@media all and (min-width:1400px) {
    .video-no-iframe video.vjs-tech {
        min-height: 527px;
    }
}

.video-playlist__preview-image-overlay {
    opacity: 0.9;
    background-image: linear-gradient(180deg, rgba(238,238,238,0.00) 0%, #000000 91%);
}

.lazy-bg-force:not(.interactive-image__image) {
    background-color: gray;
}

.passport-video > {
    .passport__content {
        margin-left: 0;

        > {
            .passport__subtitle {
                font-size: 0.9rem;
                color: #545E6B;
            }

            .passport__title {
                font-size: 1rem;
            }
        }
    }

    .porthole.porthole--lg.passport__image {
        margin-right: 17px;
    }
}
/***
    Overrides for general bootstrap classes, append f to indicate these are forced over any existing bs class.
*/

.pb-0-f, .py-0-f {
    padding-bottom: 0 !important;
}

.pt-0-f, .py-0-f {
    padding-top: 0 !important;
}

.video-no-iframe .embed-responsive::before {
    content: none;
}

.video-no-iframe video.vjs-tech {
    position: relative;
}

.page-class-videos .dropdown .dropdown__menu > a:hover {
    text-decoration: none;
}

@media (max-width: 991px) {
    .page-class-videos .video-playlist__preview-image-overlay {
        max-width: 50%;
    }
}





/****
     WEBCAST PAGES
*/
/* IE Fix for the dropdowns and remove the underline on the dropdowns for all browsers */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .page-webreplays .dropdown-menu.show {
        min-width: calc(100% - 17px);
    }
}

.page-webreplays .dropdown-menu.dropdown__menu.text-util-sm.show > a:hover {
    text-decoration: none;
}

/****
     SECTION 16 - Webinar page fixes
*/

.us-webinar-page .Form__Element.FormStep {
    margin: 0;
}

.us-webinar-page .Form__Status .Form__Status__Message.hide {
    padding: 0;
    margin: 0;
}

/* Interstitial styles */

.interstitial-view-btn {
    font-size: 0.9rem;
}





/****
    Related Insights Card
*/
.related-insights_centered-card {
    &.hasBtn {
        position: relative;
        padding-bottom: 110px;
    }

    .insight-card__title, .insight-card__text {
        text-align: center;
    }

    .insight-card__title {
        line-height: 1.4;
    }

    .insight-card__text {
        text-transform: none;
        font-weight: 500;
        letter-spacing: 0.02rem;
        font-size: 0.73rem;
    }

    .btn {
        display: table;
        margin: 0 auto;
    }

    &.hasBtn .btn-wrapper {
        position: absolute;
        bottom: 1.5rem;
        left: 0;
        z-index: 2;
    }
}

.related-insights_hero-card {
    padding: 2.5rem 2.5rem;

    &.hasBtn {
        position: relative;
        padding-bottom: 110px;
    }

    .insight-card__title, .insight-card__text {
        text-align: left;
    }

    .insight-card__title {
        color: #ffffff;
        font-size: 2.5rem;
    }

    &:hover:before {
        opacity: 0;
    }

    .insight-card__text {
        color: #ffffff;
        font-size: 1.1rem;
        font-family: "Open Sans", "Helvetica", sans-serif;
        font-weight: 500;
        text-transform: none;
    }

    .btn {
        display: table;
        margin: 0 auto;
    }

    &.hasBtn .btn-wrapper {
        position: absolute;
        bottom: 1.5rem;
        left: 0;
        z-index: 2;
    }
}

@media screen and (max-width: 430px) {
    .related-insights_hero-card {
        .insight-card__title {
            font-size: 2rem;
        }

        .insight-card__text {
            font-size: 1rem;
        }
    }
}


/****
     Grid Content Block
*/
.grid-content-block {
    display: flex;
    flex-direction: column;

    h2 {
        font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, "sans-serif" !important;
        color: #333436;
        font-size: 38px;
        letter-spacing: 0;
        line-height: 50px;
        font-weight: 300;
    }

    .row-item {
        display: flex;
    }
}

@media all and (max-width: 1020px) {
    .grid-content-block {
        .row-item {
            display: block;
            text-align: center;
        }

        h2 {
            font-size: 28px;
            line-height: 35px;
        }
    }
}

.slick-dots li button:before {
    font-size: 1rem !important;
}




/****
   VOICES PAGE
*/
.voices-page {
    .related-voices .insight-card__image {
        background-position: top;
    }

    .insights-bio__image {
        background-position: top center;
        min-height: 300px;
    }

    .insights-bio__social-icon {
        vertical-align: -0.3em;
        font-size: 1.75rem;
        margin-left: 0;
    }

    .insight-card__eyebrow {
        margin: 0 1rem 1rem 0;
    }

    .related-voices .carousel-block__main--mobile .insight-card {
        padding: 1.5rem 1.5rem;
        border: 1px solid #e6e7e8 !important;
        margin-bottom: 1.5rem !important;
    }

    .mobile-dropdown {
        display: none;
    }

    .person-title {
        font-family: "Open Sans Lite", "Helvetica", sans-serif;
    }
}

@media all and (max-width: 990px) {
    .filter-wrapper {
        margin-top: 20px;
    }

    .voices-page {
        .desktop-dropdown {
            display: none;
        }

        .mobile-dropdown {
            display: block;
        }
    }
}

@media all and (max-width: 426px) {
    .voices-page {
        .insights-bio__social-link {
            padding-top: 5px;
        }

        .insight-card {
            padding: 1.5rem 0rem;
        }
    }
}

/* Code for the mini video player here... */
@include media-breakpoint-up(xl) {
    .video-player .embed-block .sticky {
        overflow:visible;

        .embed-block__close-player {
            border-radius: 50%;
            background-color: #fff;

            &:hover, &:focus {
                background-color: #17468f;

                > .svg-inline--fa {
                    color: #fff
                }
            }
        }
    }
}
