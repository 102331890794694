@import '../style/common';

.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__img {
    @include sr-only;
  }
}
