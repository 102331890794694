@import '../style/common';

.paginator {
  $paginator-switch-breakpoint: md;

  display: inline-block;
  font-size: $font-size-xs;
  font-weight: $font-weight-semi-bold;

  @include media-breakpoint-up($paginator-switch-breakpoint) {
    font-size: $font-size-sm;
  }

  &__list-item {
    display: inline-block;
  }

  &__list-item ~ &__list-item {
    margin-left: $pagination-margin-left;
  }

  &__button {
    padding: $pagination-padding-y-sm $pagination-padding-x-sm;
    border-radius: $pagination-border-radius;
    height: 2rem;
    min-width: 2rem;
    text-align: center;

    @include media-breakpoint-up($paginator-switch-breakpoint) {
      padding: $pagination-padding-y $pagination-padding-x;
      height: space(5);
      min-width: space(5);
    }

    &[role='button'] { /* Overrides BS4 styles for anchors without an href */
      color: $pagination-color;

      &:hover {
        color: $pagination-hover-color;
      }

      .active &:hover {
        background-color: $primary;
        color: $pagination-active-color;
      }
    }


    &.page-link {
      &.paginator__button--previous {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }

      &.paginator__button--next {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }
  }
}
