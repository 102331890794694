@import '../style/common';

.featured-insight-card {
  background-color: $tertiary;
  color: $white;
  padding: space(6) space(5);
  position: relative;
  z-index: 0;

  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;

    &--overlay {
      background-position: center left;
      z-index: -1;
    }
  }
}
