@import '../style/common';

.insights-bio {
  &__main-content {
    margin-top: space(3);
    margin-bottom: space(3);

    @include media-breakpoint-up(lg) {
      margin-top: space(5);
      margin-bottom: space(5);
    }
  }

  &__image {
    height: 100%;

    .image__img {
      @media print {
        width: 100%;
        height: 100%;
        clip: auto;
      }
    }
  }

  &__social-links-block {
    margin-top: space(4);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__social-link {
    margin-right: space(4);

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-top: space(1);

      &:last-child {
        margin-left: space(3);
      }
    }
  }

  &__social-icon {
    color: $dark;
    margin-left: space(2);
    vertical-align: -0.3em;
  }

  &__share-links {

    @include media-breakpoint-down(md) {
      flex-direction: row;
    }

    @include media-breakpoint-down(sm) {
      align-items: start;
      flex-direction: row;
      margin-top: 20px;
    }
  }
}
