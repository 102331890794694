@import '../style/common';

.cta-link {
  display: inline-block;
  transition: transform $trans-duration $trans-easing;

  &:hover {
    text-decoration: none;
    transform: translateX(1rem);

    .cta-link__icon {
      color: $dark;
    }
  }

  &__icon {
    color: $dark-blue;
    transition: color $trans-duration $trans-easing;
  }

  &--white:hover {
    .cta-link__icon {
      color: $white;
    }
  }

  &--white {
    .cta-link__icon {
      color: $pastel;
    }

    .cta-link__text {
      color: $white;
    }
  }
}
