@import '../style/common';

.checkbox-group {
  $checkbox-group-columns-by-breakpoint: (
    'xxl': 2,
    'xl': 2,
    'lg': 2,
    'md': 2,
    'sm': 1,
  );

  &__header {
    background-color: $tertiary;
    background-image: url('#{$images-path}abstract-background-tertiary.svg');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    padding: space(4);
  }

  &__heading {
    display: inline-block;
    margin: 0 space(4) 0 0;
  }

  &__body {
    border: $border-style;
    padding: space(4) space(3);
  }

  &__options {
    @include make-row(0);
  }

  &__option {
    @include make-col-ready(0);
    @include padding(space(4) space(3));
    @include borderless-grid($checkbox-group-columns-by-breakpoint);

    @each $breakpoint, $column-count in $checkbox-group-columns-by-breakpoint {
      @include media-breakpoint-only($breakpoint) {
        @include make-col($grid-columns / $column-count);
      }
    }
  }
}
