@import '../style/common';

.stylized-link-list {
  &__top {
    background-image: url('#{$images-path}background-find-etf.svg');
    background-size: cover;
    padding: space(4);
    padding-left: 2rem;
  }

  &__header,
  &__description,
  &__label {
    color: $white;
  }

  &__header {
    margin-bottom: space(3);
  }

  &__label {
    font-size: $font-size-xs;
    font-weight: $font-weight-semi-bold;
    line-height: $h4-line-height;
    letter-spacing: 0.1rem;
    text-align: right;
  }

  &__bottom {
    background-image: url('#{$images-path}background-find-etf-content.svg');
    background-size: cover;
    border-bottom: $border-width solid $primary;
    padding: 2.5rem 2rem;
  }

  &__title {
    line-height: $h4-line-height;
  }

  &__list {
    list-style: none;
    padding: 0;

    &-item:nth-child(odd) {
      border-right: $border-width solid $border-color;
    }

    &-item:nth-child(even) {
      padding-left: 2rem;
    }

    &-title {
      margin-bottom: space(4);
    }
  }

  &__subtitle {
    line-height: $line-height-base;
    margin-bottom: 2.5rem;
    padding-right: space(5);
  }

  &__body {
    font-weight: $font-weight-bold;
  }

  &__heading {
    color: $dark-blue;
    font-size: $h1-font-size;
    font-weight: $font-weight-extra-bold;
    letter-spacing: -0.033rem;
    line-height: $h3-line-height;
    margin-right: space(3);
  }

  .dropdown {
    &__menu {
      max-height: none;
    }

    &__button {
      svg {
        width: 1.125rem;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__label {
      text-align: left;
      margin-left: space(3);
    }

    &__bottom {
      border-right: $border-width solid $primary;
    }
  }

  @include media-breakpoint-down(sm) {
    &__list-item {
      &:nth-child(odd) {
        border-right: none;
      }

      &:nth-child(even) {
        padding-left: space(3);
      }
    }

    &__heading {
      display: block;
    }
  }
}
