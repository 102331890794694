// Styles from react components

// FeatureCallOutBlock
.feature-hero {
    background-color: #001e6d;
    color: #fff;
    height: 100%;
    padding: 3rem 0;
    position: relative;
    z-index: 0;

    &.white-bg {
        background-color: #fff;
        color: #323232;
        padding: 0;
    }

    &.item-3 {
        .feature-card {
            flex-basis: 33%;
        }
    }

    &.w-100 {
        .feature-content-inner {
            width: 100%;
        }
    }
}

.feature-content-inner, .feature-text {
    width: 75%;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .feature-content-inner, .feature-text {
        width: 100%;
    }
}

.feature-card-wrapper {
    display: flex;
    justify-content: space-evenly;
}

@media (max-width: 767px) and (min-height: 400px) {
    .feature-card-wrapper {
        flex-direction: column;
        gap: 2rem;
    }
}

.feature-card {
    display: flex;
    flex-direction: column;
}

.feature-label {
    margin: 1rem auto 0 auto;
    width: 75%;
}

//------------------------------

// FundCarouselBlock
.fund-carousel-au-campaign {
    .slick-slide {
        height: auto;

        > div {
            height: 100%;

            > div {
                height: 100%;
            }
        }
    }

    .fund-carousel-block__placement {
        bottom: -35px;
    }
}

.fund-listing-card {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    height: 100%;
    padding-left: 1px;

    &__ticker {
        position: absolute;
        top: 0;
        background: #001e6d;
        color: #fff;
        padding: 0.5rem 1rem;
    }

    &__image {
        padding-bottom: 1rem;
        position: relative;

        img {
            width: 250px;
            height: 250px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__type {
        color: #001e6d;
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 0.25rem;
    }

    &__name {
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 0.25rem;
        flex-grow: 1;
        line-height: 1.2;

        a {
            color: #323232;
        }
    }

    &__links {
        display: flex;
        gap: 1rem;
        grid-gap: 1rem;
        margin: 0.5rem 0 1rem 0;
    }

    &__link-item {
        background: #001e6d;
        color: #fff;
        padding: 0.25rem 0.5rem;
        border: 1px solid #001e6d;

        &:hover {
            color: #001e6d;
            background: #fff;
            text-decoration: none;
        }
    }
}

.fund-listing-card.horizontal-layout {
    flex-direction: row;
    max-width: 720px;
    width: 720px;
    gap: 2rem;

    .fund-listing-card__links {
        margin-top: 1.5rem;
    }

    .fund-listing-card__body {
        padding: 0.5rem 0 3.5rem 0;
    }
}

@media (min-width: 768px) {
    .fund-carousel-block__main--desktop .slick-list {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 992px) {
    .fund-listing-card {
        max-width: 300px;
        margin: auto;

        &__image {
            img {
                width: 300px;
                height: 300px;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .fund-carousel-block__placement {
        right: 55px;
    }
}

@media (min-width: 992px) and (max-width: 1400px) {
    .fund-listing-card {
        max-width: 250px;

        &__image {
            img {
                width: 250px;
                height: 250px;
            }
        }
    }
}

@media (max-width: 767px) {
    .fund-carousel-block__main--desktop, .fund-carousel-block__main--desktop-count, .fund-carousel-block__control-container {
        display: none;
    }

    .fund-listing-card.horizontal-layout {
        flex-direction: column;
        max-width: 300px;
        width: 300px;
        gap: 0;

        .fund-listing-card__links {
            margin-top: 0.5rem;
        }

        .fund-listing-card__body {
            display: block;
            padding: 0;
        }
    }
}

//------------------------------

//ArticleSourceBlock
.article-source-block {
    .accordion {
        padding: 0;
        border-bottom: none;
    }

    .accordion__toggle {
        padding: 0.5rem 0;
        width: fit-content;
        display: inline-block;
        font-weight: 500;
        font-size: 0.9375rem;
        cursor: pointer;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .accordion__icon {
        position: relative;
        margin: auto 0.5rem;
        bottom: 0;
        top: 0;
    }
}

