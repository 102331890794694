@import '../style/common';

.featured-block {
  $featured-block-switch-breakpoint: md;

  &__fundcard-data {
    h3 {
      @include make-heading(2);
    }
  }

  &__fundcard-link {
    @include link-focus-outline;

    color: $gray-5;
    display: block;

    &:last-child {
      h3 {
        border: 0;
      }
    }

    h3 {
      border-bottom: $border-style;

      @include media-breakpoint-only($featured-block-switch-breakpoint) {
        border: 0;
      }
    }

    @include hover-focus {
      color: $gray-5;
    }
  }

  &__fundcard-title,
  &__perspective h3 {
    font-weight: $font-weight-normal;
    line-height: $h4-line-height;
  }

  &__recommended-wrapper {
    border-bottom: $border-style;
  }

  @include media-breakpoint-only($featured-block-switch-breakpoint) {
    .grid-card {
      &__divider {
        height: 0;
      }
    }
  }

  &__recommended-fund {
    width: 100%;
  }

  &__editors {
    @include media-breakpoint-only($featured-block-switch-breakpoint) {
      border-bottom: $border-style;
    }
/*    @include media-breakpoint-down(sm) {
      .grid-card:nth-child(2),
      .grid-card:nth-child(3) {
        max-width: 50%;
      }
    }*/
  }

  &__image-icon {
    color: $white;
    display: flex;
    position: absolute;
    bottom: space(3);
    right: space(3);
    padding: 0.125rem;
    transition: color $trans-duration $trans-easing;
  }

  &__image {
    transition: transform 1s;

    @include hover-focus {
      transform: scale(1.07);
    }

    &-container {
      overflow: hidden;
    }
  }
}
