@import '../style/common';

.search {
  $search-switch-breakpoint: lg;

  &__container {
    @include make-container;
    position: relative;

    @include media-breakpoint-up($search-switch-breakpoint) {
      @include padding(null space(5));
    }
  }

  &__input {
    border: 0;
    border-radius: $input-border-radius-lg;

    &--open {
      border-radius: $input-border-radius-lg $input-border-radius-lg 0 0;
    }
  }

  &__results {
    background-color: $light;
    border: 0;
    border-radius: 0 0 $input-border-radius-lg $input-border-radius-lg;
  }

  &__list {
    @include list-unstyled;
    margin-bottom: 0;
  }

  &__group-header {
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg 0;
  }

  &__group-label {
    color: $body-color;
    font-weight: $font-weight-extra-bold;
    font-size: $font-size-xxs;
    text-transform: uppercase;
    margin-right: space(3);
  }

  &__group-cta {
    float: right;
  }

  &__link {
    display: block;
    padding: $input-btn-padding-y-lg space(5);
    color: $body-color;

    @include hover-focus {
      background-color: $primary;
      color: $white;
      text-decoration: none;
    }
  }

  &__cta-container {
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
  }
}
