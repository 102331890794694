@import '../style/common';

.fund-card {
  background-color: $light;
  background-image: url('./assets/images/abstract-background.png');
  background-position: 60% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  padding: space(4);
  height: 100%;

  &__ticker {
    @include make-display-heading(2);
  }

  &__name, &__subtitle {
    margin-bottom: space(2);
  }

  &__subtitle {
    font-weight: $font-weight-bold;
  }

  &__body {
    font-size: $font-size-xs;
  }

  &__cta-block {
    margin-top: auto;
  }

  &__cta {
    display: block;
  }

  &--dark {
    color: $white;
    background-color: $tertiary;
    background-image: url('./assets/images/abstract-card-background-dark.svg');
  }
}
