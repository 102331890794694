@import '../style/common';

.event-registration {
  &__content {
    background-color: $light;
    background-image: url('./assets/images/abstract-background.png');
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: space(4);
  }

  &__eyebrow {
    font-weight: $text-util-font-weight;
    text-transform: uppercase;
    font-size: $text-util-size-sm;
    letter-spacing: $text-util-sm-letter-spacing;
    margin-bottom: space(2);
  }

  &__heading {
    @include make-heading(2);
    margin-bottom: space(4);
  }

  &__disclaimer {
    font-size: $font-size-xs;
    padding: space(4);
  }
}
