@import '../style/common';

.media-coverage-card {
  height: 100%;

  &__toggle {
    position: relative;
    padding: space(4);
    height: 100%;
    color: $body-color;

    &:before {
      background-color: $light;
      background-image: url('./assets/images/abstract-background.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: opacity $trans-duration $trans-easing;
    }

    @include hover-focus {
      text-decoration: none;
      color: $body-color;
      z-index: 1;

      &:before {
        opacity: 1;
      }
    }
  }

  &__eyebrow, &__title, &__image {
    margin-bottom: space(3);
  }

  &__title {
    @include make-heading(3);
  }

  &__date, &__type {
    @include make-text-util(sm);
  }

  &__modal {
    .modal-body {
      padding: space(5);
      max-height: 75vh;
      overflow: auto;
    }

    .modal-dialog {
      margin-top: space(6);

      @include media-breakpoint-up(lg) {
        max-width: 790px;
      }
    }
  }

  &__modal-close {
    position: absolute;
    bottom: 100%;
    right: 0;

    @include plain-hover-focus {
      color: $white;
    }

    @include media-breakpoint-up(md) {
      right: auto;
      left: 100%;
    }
  }
}
