@import '../style/common';

.attachment-block {
  &__content {
    position: relative;
    padding: space(4);
  }

  &__content-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -2;

    &-inner {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        min-height: space(7);
        /*background-color: $light;
        background-image: url('#{$images-path}share-form-bg.svg');
        background-position: 64% 100%;
        background-repeat: no-repeat;
        background-size: cover;*/
      }
    }

    &-shimmer.gleam-animation {
      position: relative;
      height: 100%;
      overflow: hidden;

      &:before {
        animation: slide 8s;
        content: '';
        position: absolute;
        animation-timing-function: cubic-bezier(0.75, 0, 0.05, 1);
        background: $gleam-linear-gradient;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 50%;
        opacity: 0%;
      }

      &:after {
        animation: slide2 8s;
        animation-timing-function: cubic-bezier(0.75, 0, 0.05, 0.8);
        content: '';
        position: absolute;
        background: $gleam-linear-gradient;
        top: 0;
        bottom: 0;
        width: 50%;
        left: 104%;
        opacity: 0%;
      }

      @keyframes slide {
        0% {
          left: 100%;
          opacity: 0%;
        }
        20% {
          opacity: 0%;
        }
        40% {
          left: 50%;
          opacity: 100%;
        }
        65% {
          left: 50%;
          opacity: 100%;
        }
        85% {
          opacity: 0%;
        }
        100% {
          left: 100%;
          opacity: 0%;
        }
      }

      @keyframes slide2 {
        0% {
          left: 104%;
          opacity: 0%;
        }
        20% {
          opacity: 0%;
        }
        40% {
          left: 54%;
        }
        55% {
          opacity: 80%;
        }
        65% {
          left: 54%;
          opacity: 65%;
        }
        85% {
          opacity: 0%;
        }
        100% {
          left: 104%;
          opacity: 0%;
        }
      }

      @include media-breakpoint-down(lg) {
        &:before {
          width: 100%;
          left: 100%;
        }

        &:after {
          left: 105%;
          width: 100%;
        }

        @keyframes slide {
          0% {
            left: 100%;
            opacity: 0%;
          }
          20% {
            opacity: 0%;
          }
          40% {
            left: 35%;
            opacity: 100%;
          }
          65% {
            left: 35%;
            opacity: 100%;
          }
          85% {
            opacity: 0%;
          }
          100% {
            left: 100%;
            opacity: 0%;
          }
        }

        @keyframes slide2 {
          0% {
            left: 105%;
            opacity: 0%;
          }
          20% {
            opacity: 0%;
          }
          40% {
            left: 40%;
          }
          55% {
            opacity: 80%;
          }
          65% {
            left: 40%;
            opacity: 65%;
          }
          85% {
            opacity: 0%;
          }
          100% {
            left: 105%;
            opacity: 0%;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        &:before {
          left: 110%;
          width: 100%;
        }

        &:after {
          left: 110%;
          width: 100%;
        }

        @keyframes slide {
          0% {
            left: 100%;
            opacity: 0%;
          }
          20% {
            opacity: 0%;
          }
          40% {
            left: 0%;
            opacity: 100%;
          }
          65% {
            left: 0%;
            opacity: 100%;
          }
          85% {
            opacity: 0%;
          }
          100% {
            left: 100%;
            opacity: 0%;
          }
        }

        @keyframes slide2 {
          0% {
            left: 110%;
            opacity: 0%;
          }
          20% {
            opacity: 0%;
          }
          40% {
            left: 10%;
          }
          55% {
            opacity: 80%;
          }
          65% {
            left: 10%;
            opacity: 65%;
          }
          85% {
            opacity: 0%;
          }
          100% {
            left: 110%;
            opacity: 0%;
          }
        }
      }
    }
  }
}
