@import '../style/common';

.message-block {
  background-color: $light;
  padding: space(5) space(4);

  @include media-breakpoint-up(lg) {
    padding: space(5) space(6);
  }

  &__body {
    margin-bottom: space(4);
  }
}
