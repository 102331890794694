
@import '../style/common';

.insights-grid {
    &__item {
        margin-bottom: 1.75rem;
    }

    &__button {
        width: 16rem;
    }
}
