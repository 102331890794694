
.page-overlay {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0);
    z-index: -1;
    position: absolute;
    transition: background .3s ease-in-out, z-index .3s ease .3s;
}

#overlay-search-input, #overlay-search-submit {
    display: none;
}


.disclaimer {
    font-size: 0.95em;
    line-height: 1.2;
}

.btn-group.countries {
    float: left;
    width: 50%;
    min-width: 410px;
    display: block;
}

.btn-group.investortype {
    width: 50%;
    min-width: 410px;
    float: left;
    display: block;
}

header {
    .top-search {
        display: none
    }

    #global-banner-wrapper {
        position: fixed;
        top: 0px;
        z-index: 9999;
        background-color: #fff;
        font-family: Helvetica, sans-serif;
        font-size: 14px;
        width: 100%;

        .btn-group {
            position: relative;
        }

        #global-nav-wrapper {
            width: 100%;
            max-width: 1300px;
            position: relative;
            margin: 0 auto;
        }

        #global-nav-slidedown-wrapper {
            position: absolute;
            width: 100%;
            z-index: 9999;

            #slidedown-country {
                overflow: hidden;
                max-height: 0;
                box-sizing: border-box;
                background-color: #fff;
                color: #eee;
                -webkit-transition: max-height 0.5s cubic-bezier(1,0,0,1);
                transition: max-height 0.5s cubic-bezier(1,0,0,1);
                overflow-y: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &.active {
                    overflow-y: unset;
                    overflow: visible;
                }

                .global-nav-slidedown-internal {
                    padding: 40px 20px;
                    overflow: visible;
                    width: 970px;
                    margin: 0 auto;

                    .welcome-header {
                        font-family: "Open Sans","Helvetica",sans-serif;
                        font-size: 24px;
                        color: #000;
                        padding-bottom: 15px;
                        font-weight: bold;
                        margin: 0;
                    }

                    .welcome-description {
                        font-family: "Open Sans","Helvetica",sans-serif;
                        font-size: 14px;
                        color: #000;
                        line-height: 18px;
                        padding-bottom: 15px;
                        margin: 0;
                    }
                }
            }
        }
    }


    .vaneck-logo {
        display: block;
        border: 0;
        float: left;
    }

    a.hp-etf-tab {
        font-family: "Open Sans","Helvetica",sans-serif;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        display: block;
        float: left;
        font-size: 12px;
        font-weight: 600;
        top: 8px;
        position: relative;
        margin-left: 80px;
        margin-right: 20px;
        line-height: 38px;
        padding: 0 8px;
        color: #333;
    }

    .drop {
        border: 0;
        width: 100%;
        background-color: #fff;
        background: url(/Static/au/images/submenu-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 10px 5px 10px rgba(0,0,0,.2);
        font-family: Arial, sans-serif;
        position: absolute;
        top: 108px;
        left: 0;
        z-index: 9999;
        display: none;

        &.selected {
            display: block;
            max-height: calc(100vh - 70px);
            overflow-y: scroll;
        }

        .drop_contetn {
            border: 0;
            background: transparent;
            min-height: 430px;
            max-width: 1300px;
            margin: auto;
            margin-top: 12px;
            padding: 10px;

            a {
                color: #191919;
            }

            .part {
                float: left;
                width: 700px;

                &.second {
                    padding-left: 0px;
                    float: left;
                    margin-left: 10px;
                    width: 330px;
                }

                .title-part {
                    font-size: 22px;
                    display: block;
                    border-bottom: 2px solid #ccc;
                    font-family: "Open Sans","Helvetica",sans-serif;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                    line-height: 39px;

                    a {
                        color: #17468f;
                    }
                }
            }

            .for_lists {
                float: left;
                width: 350px;

                .lists {
                    width: 350px;
                    margin-bottom: 30px;
                    float: left;
                    list-style-type: none;
                    padding: 0;

                    li {
                        font-family: "Open Sans","Helvetica",sans-serif;
                        text-transform: uppercase;

                        &.title {
                            background-color: transparent;
                            border-bottom: none;
                            line-height: 15px;
                            color: #001e6d;
                            height: 21px;
                            font-weight: bold;

                            a {
                                transition: transform .4s ease-out;
                                display: block;
                                font-size: 15px;
                                color: #001e6d;

                                &:hover {
                                    transform: translateX(5px);
                                    text-decoration: none;
                                }
                            }
                        }

                        &.colored {
                            background-color: transparent;
                        }

                        span.etf-name {
                            float: left;
                            width: 350px;
                            font-size: 11px;
                            line-height: 35px;
                            transition: transform .4s ease-out;

                            strong {
                                color: #17468f;
                            }

                            &:hover {
                                transform: translateX(5px);
                                text-decoration: none;
                            }
                        }
                    }
                }

                &.last {
                    padding: 0;

                    .lists {
                        span.etf-name {
                            width: 420px;
                        }
                    }
                }
            }
        }
    }

    .marketvectors {
        #drop_tab_green:hover {
            text-decoration: none;
        }
    }

    .menu-item-container {
        .top_new {
            .level1 {
                height: 39px;
                margin: 7px 0 0;
                padding: 0;
                line-height: 39px;
                float: left;
                list-style: none;

                &:hover {
                    .menu-overlay {
                        display: block;
                        z-index: 1000;
                        position: absolute;
                        background-color: #fff;
                        min-height: 550px;
                        width: 100%;
                        background: url(/Static/au/images/submenu-bg.png);
                        background-size: cover;
                        background-repeat: no-repeat;
                        box-shadow: 10px 5px 10px rgba(0,0,0,.2);
                        left: 0;
                        margin-top: -2px;
                    }
                }

                > li {
                    display: inline;
                    font-size: 12px;
                    margin-left: 20px;
                    margin-right: 20px;
                    line-height: 39px;
                    font-weight: 600;
                    float: left;
                    font-family: "Open Sans","Helvetica",sans-serif;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    border-bottom: 2px solid transparent;

                    a {
                        display: block;
                        padding: 0 8px;
                        text-decoration: none;
                        color: #333333;
                        letter-spacing: 1.5px;
                    }

                    .level2-container {
                        display: none;

                        .level1-title {
                            display: none;
                        }

                        .level2 {
                            background-color: #fff;
                            margin-left: -35px;
                            width: 200px;
                            padding: 0 0 8px 0;
                            left: -2000px;
                            position: absolute;
                            list-style: none;
                            display: inline-block;
                            float: left;
                            z-index: 20000;

                            li {
                                font-family: "Open Sans","Helvetica",sans-serif;
                                text-align: center;
                                font-size: 11px;
                                margin: 0;
                                margin-top: 10px;

                                a {
                                    padding: 5px 5px 5px 15px;
                                    line-height: normal;
                                    font-weight: bold;
                                }
                            }
                        }
                    }

                    &:hover {
                        .level2-container {
                            display: block;
                            z-index: 1000;
                            position: absolute;
                            background-color: #fff;
                            min-height: 380px;
                            width: 350px;
                            /* background-image: linear-gradient(140deg, #fff 80%, #17468f 100%); */
                            background: transparent;
                            background-size: cover;
                            background-repeat: no-repeat;
                            border-left: 1px dotted rgba(0,0,0,.2);

                            .level1-title {
                                font-size: 22px;
                                display: inline;
                                position: absolute;
                                left: 14px;
                                right: 0;
                                max-width: 1300px;
                                margin: auto;
                                border-bottom: 2px solid #ccc;
                                margin-top: 20px;
                                font-weight: normal;
                                color: #17468f;
                            }

                            .level2 {
                                margin-left: unset;
                                left: unset;
                                max-width: 1300px;
                                right: 830px;
                                margin: auto;
                                margin-top: 62px;
                                text-align: left;
                                left: 0;
                                width: 350px;
                                background: transparent;

                                li {
                                    text-align: left;
                                    padding-top: 5px;
                                    margin-top: 0;

                                    a {
                                        transition: transform .4s ease-out;

                                        &:hover {
                                            transform: translateX(20px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1320px) {
    .menu-container.top-menu {
        .menu-container-inner {
            .vaneck-logo {
                margin-left: 20px;
            }
        }
    }
    .menu-item-container:last-child ul.level1 li:hover .level2-container {
        margin-left: -200px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    header {
        a.hp-etf-tab {
            margin-left: 30px;
            margin-right: 10px;
        }

        .menu-item-container .top_new .level1>li {
            margin: 0 10px;
        }
    }
}

@media screen and (min-width: 992px) {
    .page-overlay {
        height: unset
    }

    header {
        .menu-container.top-menu {
            font-family: "Open Sans", "Helvetica", sans-serif;
            //position: fixed;
            width: 100%;
            padding-top: 50px;
            background-color: #fff;
            display: inline-block;
            margin: auto;
            box-shadow: 0px -10px 20px;
            z-index: 100;
            top: -4px;

            .menu-container-inner {
                max-width: 1300px;
                margin: auto;
            }
        }

        .hideMobile {
            display: none;
        }
    }
}

    .page-overlay.active.search-active .overlay-search {
        display: block;
        position: sticky;
        top: 90px;
        #overlay-search-input, #overlay-search-submit {
            display: block;
            position: relative;
            z-index: 2;
        }
    }

    #overlay-search-input {
        width: 90%;
        padding: 10px;
        border-radius: 50px;
        margin: auto;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: "Open Sans","Helvetica",sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
    }

    #overlay-search-submit {
        width: 90%;
        padding: 10px;
        border-radius: 50px;
        margin: auto;
        font-size: 14px;
        font-family: "Open Sans","Helvetica",sans-serif;
        text-transform: uppercase;
        letter-spacing: 2px;
        background-color: #17468f;
        color: #fff;
        font-weight: bold;
    }

@media screen and (max-width: 992px) and (max-height: 640px) {
    header #global-banner-wrapper #global-nav-slidedown-wrapper #slidedown-country .global-nav-slidedown-internal {
        min-height: 175vh;
    }
}


//media query for max-width 991.9px
@media screen and (max-width: 991.9px) {

    header {
        &.site-header {
            padding-top: 0;
        }

        #global-banner-wrapper {
            #global-nav-country-selector-li a.global-nav-slidedown-trigger.global-nav-flag {
                display: flex;
                height: 38px !important;
                margin-top: 0px;

                span.hidden-xs {
                    display: flex !important;
                    align-items: center;
                    height: 34px;
                    //text-util-xs styles applied here
                    font-size: .624375rem;
                    font-weight: 600;
                    letter-spacing: .0625rem;
                    text-transform: uppercase;
                    line-height: 30px;
                }
            }

            #global-nav-slidedown-wrapper {
                #slidedown-country .global-nav-slidedown-internal {
                    max-width: 100vw;
                    max-height: calc(100vh - 38px);
                    overflow: auto;
                }

                .disclaimer-button {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0.5rem;
                }
            }
            //hide search when on mobile.
            .global-nav-search-toggle, #global-nav li#global-nav-search-li {
                display: none;
            }
        }
    }

    .search-active .global-nav-search-toggle {
        display: block!important;
    }

    .page-overlay.active.search-active .overlay-search {
        top: 105px;
    }

}