//stylelint-disable
@import '../style/common';

.links-hero {
  @include link-focus-outline;
  position: relative;

  &__insights-right {
    font-size: $font-size-xs;
    text-transform: uppercase;

    &__nav-label {
      color: $black;
      font-weight: $font-weight-bold;
      letter-spacing: 0.09375rem;
      min-width: 7rem;
      padding: space(1) 0;
    }
  }

  &__links {
    display: inline-block;
    border-right: $border-width solid $gray-2;
    font-weight: $font-weight-semi-bold;
    letter-spacing: $text-util-md-letter-spacing;
    padding: space(1) space(4);

    &:last-child {
      padding-right: 0;
      border-right: 0;
    }

    &__action {
      @include link-focus-outline;
      color: $gray-5;
    }
  }

  .dropdown {
    @include media-breakpoint-down(sm) {
      position: inherit;
    }

    &__label {
      font-size: $font-size-sm;
      letter-spacing: $text-util-lg-letter-spacing;
      padding-right: space(1);
    }

    &__button {
      padding: space(1) space(4) space(1) space(1);
    }

    &__icon-condensed {
      font-size: $font-size-lg;
      margin: auto 0;
    }

    .dropdown-item {
      @include media-breakpoint-down(md) {
        word-break: break-word;
        white-space: normal;
      }
    }

    .dropdown-menu.show {
      transform: translate3d(0%, 40px, 0) !important;
    }
  }
}
