@import '../style/common';

.share-with-a-friend-form {
    &__close {
        position: absolute;
        top: space(2);
        right: space(2);
        z-index: 9;

        @include plain-hover-focus {
            color: $tertiary;
        }
    }

    &__heading {
        font-size: $h3-font-size;
    }

    &__require-label {
        font-size: $font-size-xs;
    }

    &__privacy-notice {
        font-weight: $font-weight-semi-bold;
        font-size: $h6-font-size;
        line-height: $h4-line-height;
    }

    &__dialog {
        max-width: 50rem;
    }

/*    &__content {
        background-image: url('#{$images-path}form-bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }*/

    &__left-block {
        border-right: $border-width solid $gray-2;

        @include media-breakpoint-down(md) {
            padding-right: space(4);
        }

        @include media-breakpoint-down(sm) {
            border-right: none;
            margin-bottom: space(4);
            position: relative;

            &:before {
                content: '';
                position: absolute;
                border-bottom: $border-width solid $gray-2;
                bottom: 0;
                left: 0;
                right: 0;
                width: 60%;
                margin: auto;
            }
        }
    }

    &__right-block {
        padding: 0 5rem 0 space(5);

        @include media-breakpoint-down(sm) {
            padding: 0 space(5);
        }
    }

    &__success-body {
        color: $gray-9;
        font-size: $font-size-lg;
    }

    &__body {
        padding: space(5) 2rem;
    }

    .btn {
        width: 16rem;
    }

    .close-icon {
        @include link-focus-outline;
    }

    &__form-success {
        position: relative;
/*        background-image: url('#{$images-path}share-form-success-bg.svg');
        background-repeat: no-repeat;
        background-size: cover;*/
        padding: space(4) 2rem 4rem;

        @include media-breakpoint-down(sm) {
            padding: space(4) 2rem space(5);
        }

        &__close {
            position: absolute;
            top: space(2);
            right: space(2);
            z-index: 9;
            color: $primary;
        }
    }
}
