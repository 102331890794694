@import '../style/common';

.video-player {
    &__accordion-icon {
        margin-left: space(1);
        transform: rotate(0);
        transition: $trans-duration $trans-easing;
    }

    &__accordion-button.collapsed &__accordion-icon {
        transform: rotate(180deg);
    }

    &__accordion-button {
        margin-top: 2rem;
    }

    &__accordion-text {
        &--hidden {
            display: none;
        }

        .collapsed > &--shown {
            display: none;
        }

        .collapsed > &--hidden {
            display: inline;
        }
    }

    .button-label {
        @include link-focus-outline;
    }

    @media print {
        .collapse:not(.show) {
            display: block;
        }
    }

    .embed-block__close-player {
        display: none;
    }

    @include media-breakpoint-up(xl) {
        .embed-block .sticky {
            position: fixed;
            bottom: space(4);
            right: space(4);
            width: 400px;
            z-index: 2000;
            max-width: 100%;
        }
        // stylelint-disable selector-max-compound-selectors
        .embed-block .sticky .embed-block__close-player {
            @include link-focus-outline;
            display: block;
            position: fixed;
            bottom: 250px;
            right: 1.25rem;
        }
    }
}
