@import '../style/common';

.preview-card {
  border-bottom: $border-width solid $border-color;
  display: block;
  padding: $card-spacer-y $card-spacer-x;
  position: relative;
  z-index: 0;

  @include plain-hover-focus {
    color: $body-color;
    text-decoration: none;
  }

  &:before {
    background-image: url('#{$images-path}abstract-background.svg');
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity $trans-duration $trans-easing;
  }

  &__image-container {
    margin-bottom: space(4);

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: space(3);
  }

  &__description {
    margin-bottom: space(4);
  }

  &__cta {
    display: inline-block;
    opacity: 1;
    transition: opacity $trans-duration $trans-easing;

    @include media-breakpoint-up(lg) {
      opacity: 0;
    }

    &-label {
      color: $link-color;
    }

    &-icon {
      color: $dark-blue;
    }
  }

  @include hover-focus {
    &:before {
      opacity: 1;
    }

    .preview-card__cta {
      opacity: 1;
    }
  }
}
