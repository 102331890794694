@import '../style/common';

.ordered-list {
  counter-reset: display-number-counter;
  list-style: none;

  &__item {
    counter-increment: display-number-counter;
    position: relative;
    margin-left: space(3);
    margin-top: space(1);
    margin-bottom: space(4) + 0.835rem;

    &:before {
      content: counter(display-number-counter)'.'; // stylelint-disable-line function-whitespace-after
      font-weight: $display1-weight;
      font-size: $display1-size;
      line-height: $display1-size;
      position: absolute;
      top: -#{space(1)};
      left: -$display1-size - space(3);
    }
  }
}
