@import '../style/common';

.more-from-category {
  $more-from-category-switch-breakpoint: md;
  $more-from-category-control-size: 2.1875rem;
  position: relative;

  &__heading {
    @include make-heading(4);
    margin: 0 space(6) 0 0;
  }

  &__main {
    margin-top: space(3);
    position: relative;

    &--desktop {
      .slick-track {
        display: flex;
      }

      .slick-slide {
        height: auto;
      }

      .slick-list {
        @include media-breakpoint-up($more-from-category-switch-breakpoint) {
          @include margin(null $grid-gutter-width / -2);
        }
      }

      &-count {
        height: $more-from-category-control-size;
        padding: 0 space(2);
        background-color: $gray-1;
        display: none;

        b, span {
          padding: 0 space(1);
        }

        @include media-breakpoint-down($more-from-category-switch-breakpoint) {
          display: flex;
        }
      }
    }
    @include media-breakpoint-down($more-from-category-switch-breakpoint) {
      margin-bottom: space(3);
    }
  }

  &__placement {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;

    @include media-breakpoint-down($more-from-category-switch-breakpoint) {
      display: flex;
    }
  }

  .slick-initialized .slick-slide {
    display: flex;
  }

  .slick-disabled {
    background-color: $gray-3;
    pointer-events: none;
  }

  &__item {
    height: 100%;
    margin-top: space(3);

    @include media-breakpoint-up($more-from-category-switch-breakpoint) {
      @include margin(0 $grid-gutter-width / 2);
    }

    @include media-breakpoint-down($more-from-category-switch-breakpoint) {
      margin-top: space(3);
    }

    @include media-breakpoint-down(sm) {
      margin: space(4) space(2) 0;
    }
  }

  &__control {
    transition: $btn-transition;
    height: $more-from-category-control-size;
    width: $more-from-category-control-size;
    background-color: $dark-blue;
    color: $white;

    &--prev {
      right: $more-from-category-control-size;
    }
  }
}
