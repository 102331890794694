﻿/*Consist print styles for us pages*/
@media print {

    @page {
        size: letter;
        margin: 25mm auto 10mm;
    }

    .site-footer, #back-to-top-wrapper {
        display: none;
    }

    .text-hero:first-child:before {
        content: url(/static/corp/images/logo-blue.svg);
        display: block;
        height: 50px;
    }

    .site-header.sticky {
        position: static;

        #navbar-nav {
            display: none !important;
        }

        .gateway, .navbar .d-lg-none {
            display: none;
        }

        .container {
            max-width: 100% !important;
        }

        .navbar {
            max-width: 95% !important;
        }
    }
    .subscription-center-page {
        .checkbox-group__options {
            display: block;
        }

        .checkbox-group__option:nth-child(4), .site-footer__body, .subscription-form p:nth-child(4) {
            page-break-after: always;
        }
    }

    * {
        -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
        color-adjust: exact !important; /*Firefox*/
    }


}


        


