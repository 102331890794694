@import '../style/common';

.nested-links {
  padding-left: 0;

  &, &__inner-list {
    list-style-type: none;
  }

  &__inner-item {
    display: block;
    margin-bottom: space(4);
  }
}
