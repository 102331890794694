.banner {
    position: relative;
    z-index: 0;
    padding: 6rem 0 1.5rem;

    &__bg {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -2;
    }

    &__content-container {
        color: #fff;
        min-height: 150px;
    }
}

@media (min-width: 768px) {
    .banner {
        padding-bottom: 3rem;
        padding-top: 9.875rem;
    }
}

// Shift banner image to bring either top or bottom into focus
.banner.top-focus .banner__bg {
    background-position-y: 10%;
}

.banner.bottom-focus .banner__bg {
    background-position-y: 90%;
}

// Shift banner image to bring either left or right side into focus on mobile screens
@media (max-width: 576px) {
    .banner.left-focus .banner__bg {
        background-position-x: 40%;
    }

    .banner.right-focus .banner__bg {
        background-position-x: 60%;
    }
}

.quote-wrapper {
    max-width: 80%;
    margin: 0 auto;
    color: #fff;
}

@media (min-width: 1400px) {
    .quote-wrapper {
        max-width: 550px;
    }
}

.quote {

    * {
        position: relative;
    }

    :first-child:before {
        content: '';
        background-image: url(/globalassets/home.au/special/about-us/quotes.png);
        background-repeat: no-repeat;
        top: -20px;
        left: -45px;
        width: 43px;
        height: 94px;
        background-size: 100%;
        position: absolute;
    }

    :last-child:after {
        content: '';
        background-image: url(/globalassets/home.au/special/about-us/quotes.png);
        background-repeat: no-repeat;
        bottom: -70px;
        width: 43px;
        height: 94px;
        background-size: 100%;
        position: absolute;
    }
}
