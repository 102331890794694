@import '../style/common';

.card-grid {
  $cols-variants: (
    1,
    2,
    3,
  );

  &__card-container {
    @each $col in $cols-variants {
      &--#{$col} {
        @include borderless-grid(('lg': $col, 'md': 1));
      }
    }
  }

  &__card {
    height: 100%;
  }
}
