﻿body.react-portfolio-page.au-portfolio-page {
    .fund-key-points {
        br {
            display: initial;
        }
    }

    .fund-page-header-wrp {
        @media (min-width: 992px) {
            margin-top: -13px !important;
        }
    }
    //Specific content tables that are added through tinymce.
    .fund-explorer-content-table table.fund-listing-table {
        border-width: 0px;
        border-collapse: collapse;
        table-layout: fixed;

        tr td {
            text-align: left;
            white-space: initial;
        }

        th, td {
            &:first-child {
                width: 130px;

                @media(min-width:450px) {
                    width: 200px;
                }
            }
        }

        ul {
            text-wrap: wrap;
        }
    }
    //Collapse and Expand Dispcliamers
    .collapse-text {
        display: block;
    }

    .expanded-footer.chart-disclaimer {
        padding-bottom: 30px;
    }
    /*
   STYLES NEEDED FOR THE BROKER CLICKOUT MODAL
    ==================
*/

    .modal[id*="id-how"].broker-clickout {
        color: #323232;

        .modal-content {
            background-image: none;
        }

        &:not(.no-margin-top) {
            top: -15px;
        }

        @media (max-width: 767.98px) {
            &:not(.no-margin-top) {
                top: 0;
            }

            .modal-content {
                width: calc(100vw - 10px) !important;
                height: auto !important;
            }
        }

        .model-dialog {
            max-width: 720px;
        }

        .modal-body {
            @media (max-height: 450px) and (max-width: 768px) {
                overflow-y: auto;
            }

            > h4, h4 {
                font-size: 16px;
                line-height: 26px;
                font-weight: 700;
                border-bottom: none;
                margin: 0;
            }

            h3 {
                font-size: 19px;
                letter-spacing: 0;
                line-height: 30px;
                font-weight: 700;
                padding: 32px 45px 0 45px;
                max-width: 90%;
                margin: 0 0 25px;
            }
        }

        .broker-clickout {
            &__main {
                padding: 0px;
            }

            &__subheading {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 15px;
                padding: 0px 45px 20px 45px;
            }

            &__close {
                color: #17468f;
                position: absolute;
                right: 1.5rem;
                top: 1.5rem;
                z-index: 500;
                cursor: pointer;
            }

            &__body {
                padding: 15px 45px 0 45px;

                .border-right-desktop {
                    @media (min-width:768px) {
                        border-right: 1px solid gray;
                    }
                }
            }

            &__disclaimer-content {
                padding-top: 40px;

                h4 {
                    padding: 0 45px;
                }

                p {
                    padding: 0 45px;
                }

                .disclaimer-buttons {
                    margin-top: 1.5rem;
                    padding: 0 45px 20px;
                    display: flex;

                    > button {
                        margin-right: 10px;
                    }
                }
            }
        }
    }


    #id-how-to-buy-etf-modal.eu-broker-clickout-modal > .modal-dialog {
        /*height: 100vh;*/
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto !important;

        @media (max-width: 767px) {
            top: 0px;
        }
    }

    #id-how-to-buy-etf-modal.modal > .modal-dialog, #id-how-to-buy-mf-modal > .modal-dialog {
        width: 720px;
    }
    /*  
    These two styles should be merged and use only the common ones
    Overrides from Main ETF for the broker clickout
*/
    .modal[id*="id-how"] {
        padding: 0 !important;
        box-sizing: border-box;
        background-color: rgba(0,0,0,.85)
    }

    .modal[id*="id-how"] .modal-content {
        border: none;
        border-radius: 0;
        color: #323232;
        font-family: "Open Sans","Helvetica",sans-serif;
        background-image: url(/Static/images/modal_bg@3x.jpg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center
    }

    .modal[id*="id-how"] .modal-header {
        padding: 0;
        border: none
    }

    .modal[id*="id-how"] .modal-header:after {
        content: "";
        clear: both;
        display: table
    }

    .modal[id*="id-how"] .modal-body {
        padding: 0px;
        margin: 0;
        box-sizing: border-box;
        /*overflow-y: scroll;*/
    }

    .modal[id*="id-how"] .close {
        position: relative;
        background-color: #17468f;
        color: #fff;
        opacity: 1;
        z-index: 1;
        width: 42px;
        height: 42px;
        margin: 0;
        border: none
    }

    .modal[id*="id-how"] .close:before, .modal[id*="id-how"] .close:after {
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #fff;
        border-radius: 1px;
        top: 50%;
        left: 50%;
        margin: -1px 0 0 -15px
    }

    .modal[id*="id-how"] .close:before {
        transform: rotateZ(45deg)
    }

    .modal[id*="id-how"] .close:after {
        transform: rotateZ(-45deg)
    }

    .modal[id*="id-how"] p {
        font-size: 16px;
        line-height: 24px;
        margin: 16px 0 0
    }

    .modal[id*="id-how"] ul {
        margin-top: 10px;
        list-style-type: disc
    }

    .modal[id*="id-how"] ul li {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 15px 1.4em
    }

    .modal[id*="id-how"] .modal-dialog {
        width: 945px
    }

    @media screen and (max-width: 1199px) {
        .modal[id*="id-how"] .modal-dialog {
            width: 638px !important;
            max-height: 90vh;
            overflow-x: hidden;
            //overflow-y: auto
        }

        .modal[id*="id-how"] .modal-body {
            padding: 0px !important
        }

        .modal[id*="id-how"] h3 {
            font-size: 28px
        }
    }

    @media screen and (max-width: 767px) {
        .modal[id*="id-how"] .modal-dialog {
            width: auto !important;
            margin: 5px
        }

        .modal[id*="id-how"] .close {
            width: 32px;
            height: 32px
        }

        .modal[id*="id-how"] .close:before, .modal[id*="id-how"] .close:after {
            width: 26px;
            margin: -1px 0 0 -13px
        }

        .modal[id*="id-how"] h3 {
            font-size: 20px;
            margin: 0 0 6px
        }

        .modal[id*="id-how"] p {
            font-size: 14px;
            line-height: 18px;
            margin: 10px 0 0
        }

        .modal[id*="id-how"] ul {
            margin-top: 6px
        }

        .modal[id*="id-how"] ul li {
            font-size: 14px;
            line-height: 18px;
            margin: 0 0 10px 1.4em
        }

        .modal#id-how-to-buy-etf-modal {
            z-index: 12000;
            overflow: hidden !important
        }

        .modal#id-how-to-buy-etf-modal .modal-content {
            height: 100vh;
        }

        .modal#id-how-to-buy-etf-modal .modal-dialog {
            max-height: 100vh;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        .modal#id-how-to-buy-etf-modal .modal-body {
            text-align: left
        }

        .modal#id-how-to-buy-etf-modal {
            max-height: 100vh;
        }

        .modal#id-how-to-buy-etf-modal .custom-video-wrapper iframe {
            height: 300px;
        }
    }

    #id-how-to-buy-etf-modal button.close {
        margin: 0 0 0 0;
    }

    #id-how-to-buy-etf-modal .modal-header {
        justify-content: flex-end;
    }

    #id-how-to-buy-etf-modal .modal-content {
        max-height: 85vh;
    }
    /*End of styles*/

    .overflow-wrap-break-word {
        overflow-wrap: anywhere;

        &__sm {
            @media (min-width: 768) {
                overflow-wrap: normal;
            }
        }

        &__md {
            @media (min-width: 992) {
                overflow-wrap: normal;
            }
        }
    }
}
