@import '../style/common';

.video-playlist {
  $video-playlist-switch-breakpoint: lg;
  $video-playlist-preview-gutter-width: space(3);

  &__container {
    @include make-row;
  }

  &__video-wrapper,
  &__content {
    @include make-col-ready;
    margin-bottom: space(3);

    @include media-breakpoint-up($video-playlist-switch-breakpoint) {
      @include make-col(9);
      margin-bottom: 0;
    }
  }

  &__content {
    order: 2;
    margin: space(3) 0;

    @include media-breakpoint-up($video-playlist-switch-breakpoint) {
      order: 3;
    }
  }

  &__preview-track {
    @include list-unstyled;
    @include make-col-ready;
    margin-top: space(3);
    order: 3;

    @include media-breakpoint-up($video-playlist-switch-breakpoint) {
      @include make-col(3);
      order: 2;
      display: flex;
      flex-direction: column;
      margin: $video-playlist-preview-gutter-width / -2 0;
    }
  }

  &__preview {
    font-size: $font-size-sm;
    font-weight: $font-weight-semi-bold;
    position: relative;
    margin-bottom: space(2);

    @include media-breakpoint-up($video-playlist-switch-breakpoint) {
      margin-bottom: 0;
      padding: $video-playlist-preview-gutter-width / 2 0;
      flex: 0 0 percentage(4 / $grid-columns);
      max-height: percentage(4 / $grid-columns);
    }

    &-link {
      display: flex;

      @include plain-hover-focus {
        color: $body-color;
      }

      @include media-breakpoint-up($video-playlist-switch-breakpoint) {
        position: absolute;
        top: $video-playlist-preview-gutter-width / 2;
        right: 0;
        bottom: $video-playlist-preview-gutter-width / 2;
        left: 0;

        @include plain-hover-focus {
          color: $white;
        }
      }

      @include hover-focus {
        * {
          text-decoration: underline;
        }
      }
    }

    &-image-wrapper {
      flex: 0 0 50%;
      max-width: 50%;

      @include media-breakpoint-up($video-playlist-switch-breakpoint) {
        flex: 0 0 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &-title {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: space(4);

      @include media-breakpoint-up($video-playlist-switch-breakpoint) {
        flex: 0 0 100%;
        max-width: 100%;
        position: absolute;
        right: space(3);
        bottom: $video-playlist-preview-gutter-width / 2;
        left: space(3);
        padding: 0;
      }
    }
  }
}
