@import '../style/common';

.podcast-player {
  &__embed {
    display: block;
    width: 100%;
  }

  &__accordion-icon {
    margin-left: space(1);
    transform: rotate(0);
    transition: $trans-duration $trans-easing;
  }

  &__accordion-button.collapsed &__accordion-icon {
    transform: rotate(180deg);
  }

  &__accordion-button {
    margin-top: 2rem;
  }

  &__accordion-text {
    &--hidden {
      display: none;
    }

    .collapsed > &--shown {
      display: none;
    }

    .collapsed > &--hidden {
      display: inline;
    }
  }

  .button-label {
    @include link-focus-outline;
  }

  @media print {
    .collapse:not(.show) {
      display: block;
    }
  }
}
