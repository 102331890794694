﻿/****
    HEADER
*/

.modal-backdrop {
    z-index: 1010;
}

header#sticky-site-header {
    z-index: 1050 !important;
}

//when modal is open, pull this down.
body.modal-open header#sticky-site-header {
    z-index: 1000 !important;
}

//however the search backdrop needs to be a bit behind
.modal-backdrop.show.bs4-search-backdrop {
    z-index: 990 !important;
}

header.site-header.no-drop-shadow {
    box-shadow: none;
}

header.site-header > nav {
    position: relative;
}

.site-header.sticky {
    z-index: 1040 !important;
}

.site-header__mobile-sub-submenu-inner.container {
    padding-bottom: 40px;
}

.site-header__mobile-menu {
    height: calc(100vh - 5rem);
}




/****
    MEGAMENU
*/

// Issue with low resolution laptops, where Investments L2 is broken into two lines.
// Fixed by reducing margins only until 1400px and reduce margin + width until 1200px
#investments-desktop .site-header__submenu-inner {
    @media (max-width:1400px) {
        .nav-tabs > li.nav-item {
            margin-right: -0.4rem; //reduce margin between each heading.
        }
    }

    @media (max-width:1200px) {
        > .container.d-block {
            max-width: calc(100% - 40px); //use max width except for small margins on either side.
        }

        .nav-tabs > li.nav-item {
            margin-right: 0; //zero margin betweeb each heading.
        }
    }

    //add scrollbars to the tab content when the screen height is too small
    .tabs__content.tab-content {
        max-height: calc(100vh - 236px); //236px = allowance for sticker header + top portion of investments menu.
        overflow: hidden;
        overflow-y: auto;
    }
}


// 1. Set Heights for various sections, since these were switched to flex-column.

.tab-content #investment-ideas-desktop > .row {
    max-height: 15rem;
}

.site-header .tab-pane#webinars-desktop > .list-unstyled {
    max-height: 14rem;
}

.investments-tabs .tab-content {
    #equity-etfs-pane > .row {
        .col-lg-4:nth-child(3) {
            min-height: 37rem;
        }

        flex-direction: column;
        max-height: 41rem;
    }

    #income-etfs-pane > .row {
        flex-direction: column;
        max-height: 27rem;
    }

    #mutual-funds-pane > .row {
        flex-direction: column;
        max-height: 32rem;
    }
}

@-moz-document url-prefix() {
    .investments-tabs .tab-content #equity-etfs-pane > .row {
        flex-direction: column;
        max-height: 41rem;
    }
}
.site-header .tab-pane#research-desktop ul {
    max-height: 320px;
}

/* 2. Make l3 items in mainnav lowercase */

.site-header .navbar #trending-desktop .text-util-md {
    text-transform: capitalize;
}

.site-header__mobile-submenu #trending-mobile-sub-submenu .text-util-md {
	text-transform: capitalize;
}

/* 3. IE Fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .investments-tabs .tab-content {
        #equity-etfs-pane > .row {
            flex-direction: column;
            height: 44rem;
        }

        #mutual-funds-pane > .row {
            flex-direction: column;
            height: 38rem;
            max-height: 38rem;
        }

        #income-etfs-pane > .row {
            flex-direction: column;
            height: 30rem;
            max-height: 30rem;

            .col-lg-4 {
                flex: 1 1 auto;
            }
        }
    }

    .navbar a.navbar-brand {
        text-indent: 0px;

        > img {
            height: 35px;
            text-indent: 0px;
            width: 145px;
        }
    }

    .gateway .dropdown > .dropdown-menu.countries {
        min-width: calc(100% - 17px);
    }

    div.site-header__submenu-content .tab-pane ul {
        li {
            flex: 1 1 20px;
            max-height: 20px;
        }

        height: 320px;
    }

    /* a height is required for IE 11 */
}

/*L1 navigation spacing changes*/
@media (min-width: 992px) and (max-width: 1200px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}

/*L1 navigation spacing changes*/
@media (min-width: 992px) and (max-width: 1200px) {
    .navbar-expand-lg .navbar-nav #investments-desktop .nav-link{
        padding-right: 0.3rem;
        padding-left: 0.3rem;
    }
}

/*L1 navigation subscription btn on mobile and tab*/

.mob-subscription-btn {
    display: none;
}

.tab-subscribe-btn {
    display: inline-block;
}

@media screen and (max-width: 480px) {
    .mob-subscription-btn {
        display: inline-block;
    }

    .tab-subscribe-btn {
        display: none;
    }
}

/*L2 header*/
@media (min-width: 768px) {
    div.display-1 {
        font-size: 1.75rem;
        font-weight: 700;
    }
}

/*L2 List group changes*/

.site-header a.list-group-item, div.site-header__submenu-content .tab-pane ul li {
    line-height: 1.25rem;
}

@media (max-width: 425px) {
    .site-header__mobile-submenu-open {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1500px) {
    .col-lg-4 {
        &.custom-lg-4 {
            flex: 0 0 37.3333333333%;
            max-width: 37.3333333333%;
        }

        &.custom-md-4 {
            flex: 0 0 35.3333333333%;
            max-width: 35.3333333333%;
        }

        &.custom-sm-4 {
            flex: 0 0 31.3333333333%;
            max-width: 31.3333333333%;
        }

        &.custom-xs-4 {
            flex: 0 0 27.3333333333%;
            max-width: 27.3333333333%;
        }
    }
}

/*L2 cta changes*/

.site-header__mobile-submenu .site-header__mobile-submenu-icon-wrapper {
    position: relative;
    margin-left: 10px;
}

/*L2 Blurbs font size*/

div.site-header__submenu-content .font-size-lg {
    font-size: 1rem;
    line-height: 1.25rem;
}

/*L2 fund-link changes on mobile and tab*/
@media (max-width: 1200px) {
    span.fund-link__text {
        letter-spacing: 0.04375rem;
    }
}

@media (max-width: 320px) {
    span.fund-link__text {
        font-size: 0.55rem;
    }
}

/*L2 change height and the cta links order */

.site-header__submenu-content {
    min-height: 20rem;
}

.site-header__submenu {
    min-height: auto;
}

.site-header__submenu-content .tab-pane {
    ul {
        flex-direction: column;
        max-height: 300px;
    }

    &#thought-leaders-desktop ul {
        flex-direction: column;
        max-height: 225px;
    }
}

.list-group-item + .list-group-item.active {
    margin-top: 0px;
}

/* L3 in header to show as is */

.site-header .text-util-md {
    text-transform: none;
}

/**
    For custom header menus that have custom spacing.
*/
.custom-aligned-header .site-header {
    #navbar-nav {
        margin-bottom: 0.375rem;
    }

    .navbar-brand {
        margin-top: 0.3rem;
        margin-bottom: 0.2rem;
    }
}



/****
    AHP FLAGS
*/
.global-nav-flag .dropdown__button, .global-nav-flag.dropdown__item {
    background: url("/Static/images/ahp-country-flags-sprite.png?m=20210813") no-repeat;
    padding-left: 55px;
    background-position-x: 16px;
}

.global-nav-flag.dropdown__item:hover, .global-nav-flag.dropdown__item:focus {
    background-color: #17468f !important;
}

/* global-nav flags position */
.global-nav-flag.country-austria .dropdown__button, .global-nav-flag.country-austria.dropdown__item {
    background-position: 16px -49px !important;
}

.global-nav-flag.country-australia .dropdown__button, .global-nav-flag.country-australia.dropdown__item {
    background-position: 16px 0px !important;
}

.global-nav-flag.country-chile .dropdown__button, .global-nav-flag.country-chile.dropdown__item {
    background-position: 16px -143px !important;
}

.global-nav-flag.country-china .dropdown__button, .global-nav-flag.country-china.dropdown__item {
    background-position: 16px -192px !important;
}

.global-nav-flag.country-canada .dropdown__button, .global-nav-flag.country-canada.dropdown__item {
    background-position: 16px -95px !important;
}

.global-nav-flag.country-denmark .dropdown__button, .global-nav-flag.country-denmark.dropdown__item {
    background-position: 16px -238px !important;
}

.global-nav-flag.country-finland .dropdown__button, .global-nav-flag.country-finland.dropdown__item {
    background-position: 16px -286px !important;
}

.global-nav-flag.country-ireland .dropdown__button, .global-nav-flag.country-ireland.dropdown__item {
    background-position: 16px -381px !important;
}

.global-nav-flag.country-italy .dropdown__button, .global-nav-flag.country-italy.dropdown__item {
    background-position: 16px -428px !important;
}

.global-nav-flag.country-germany .dropdown__button, .global-nav-flag.country-germany.dropdown__item {
    background-position: 16px -334px !important;
}

.global-nav-flag.country-luxembourg .dropdown__button, .global-nav-flag.country-luxembourg.dropdown__item {
    background-position: 16px -478px !important;
}

.global-nav-flag.country-netherlands .dropdown__button, .global-nav-flag.country-netherlands.dropdown__item {
    background-position: 16px -525px !important;
}

.global-nav-flag.country-norway .dropdown__button, .global-nav-flag.country-norway.dropdown__item {
    background-position: 16px -573px !important;
}

.global-nav-flag.country-sweden .dropdown__button, .global-nav-flag.country-sweden.dropdown__item {
    background-position: 16px -668px !important;
}

.global-nav-flag.country-switzerland .dropdown__button, .global-nav-flag.country-switzerland.dropdown__item {
    background-position: 16px -809px !important;
}

.global-nav-flag.country-united-states .dropdown__button, .global-nav-flag.country-united-states.dropdown__item {
    background-position: 16px -764px !important;
}

.global-nav-flag.country-united-kingdom .dropdown__button, .global-nav-flag.country-united-kingdom.dropdown__item {
    background-position: 16px -716px !important;
}

.global-nav-flag.country-spain .dropdown__button, .global-nav-flag.country-spain.dropdown__item {
    background-position: 16px -620px !important;
}

.global-nav-flag.country-rest-of-world .dropdown__button, .global-nav-flag.country-rest-of-world.dropdown__item {
    background-position: 16px -915px !important;
}

.global-nav-flag.country-peru .dropdown__button, .global-nav-flag.country-peru.dropdown__item {
    background-position: 16px -966px !important;
}

.global-nav-flag.country-rest-of-asia .dropdown__button, .global-nav-flag.country-rest-of-asia.dropdown__item {
    background-position: 16px -854px !important;
}

.global-nav-flag.country-portugal .dropdown__button, .global-nav-flag.country-portugal.dropdown__item {
    background-position: 16px -1014px !important;
}

.global-nav-flag.country-liechtenstein .dropdown__button, .global-nav-flag.country-liechtenstein.dropdown__item {
    background-position: 16px -1062px !important;
}

.global-nav-flag.country-france .dropdown__button, .global-nav-flag.country-france.dropdown__item {
    background-position: 16px -1110px !important;
}

.global-nav-flag.country-belgium .dropdown__button, .global-nav-flag.country-belgium.dropdown__item {
    background-position: 16px -1157px !important;
}

.global-nav-flag.country-poland .dropdown__button, .global-nav-flag.country-poland.dropdown__item {
    background-position: 16px -1206px !important;
}

.global-nav-flag.country-brazil .dropdown__button, .global-nav-flag.country-brazil.dropdown__item {
    background-position: 16px -1268px !important;
}

.global-nav-flag.country-mexico .dropdown__button, .global-nav-flag.country-mexico.dropdown__item {
    background-position: 16px -1321px !important;
}



/****
    FOOTER
*/

footer .site-footer__content {
    a, a:hover {
        color: #fff !important;
        text-decoration:none;
        border-bottom:2px solid #fff;
    }
}

/* Utility links */

/* blog-only -- hide for all pages unless body has the blog class*/
body:not(.blogs):not(.voices) .site-footer__social-list .blog-only {
    display: none;
}

/* blog-only -- hide for all themepages -- even though they have the blog page class*/
body.theme-page:not(.voices-landing) .site-footer__social-list .blog-only {
    display: none;
}

/* Footer social media styles*/
.site-footer__social-link .fa-envelope, .site-footer__social-link .fa-share-alt-square {
    font-size: 1.2em;
}


@media all and (max-width: 450px) {
    ul.site-footer__social-list {
        padding: 0 1.25rem 1.5rem;
    }
}


/**
    WEBP-4250 - button styles in disable cookies
*/
.cookie-gateway .gateway__container .btn-primary.btn {
    -webkit-appearance: none;
}

/*Adjust font size for header disclamier for EU only */
@media screen and (min-width: 0px) and (max-width: 768px) {
    .gateway__container #disclaimer_block.disclaimer-eu {
        font-size: 0.66rem;
    }
}

/*Drop down styles if gateway menu opens upwards*/
.gateway__container .dropdown__menu.show[x-placement="top-start"] ~ .dropdown__button {
    border-radius: 0 0 .3125rem .3125rem;
}

.gateway__container .dropdown .dropdown__menu.show[x-placement="top-start"] {
    border-radius: .3125rem .3125rem 0 0;
    border-top: 1px solid #939598;
    border-bottom: none;
}

//Cursor pointer added to investment main nav items
.investments-menu {
    .tabs a.nav-link {
        cursor: pointer;
    }
    //WEBUS-26 - underline color changed to #7D868C only in hover for investment menu items (WEBUS-33)
    .nav-item:hover > .nav-link:not(.active):after {
        background-color: #7D868C;
    }
}
//special size reduction for the Education Advisor education section in menu - WEBP-4820
//assumed that advisor education will always be the first menu item under Education
.institutional.main-top-menu__item .site-header__submenu-content .tab-pane:first-child ul {
    max-height: 11rem;
}

body.focus-on-content {
    &.manage-subscription {
        //specifically remove the Hi John Doe message on the manage-subscriptions page
        form.EPiServerForms .Form__MainBody > .Form__Element:first-child .FormParagraphText:first-child {
            display: none;
        }
    }

    .hero {
        display: none;
    }

    .root .sticky-wrapper {
        display: none;
    }

    footer.site-footer {
        display: none;
    }
}