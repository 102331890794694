@import '../style/common';

.dropdown-checkbox {
  position: relative;

  &.show {
    .dropdown-checkbox-button {
      border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;
      background: $white;
      border-bottom: none;
      padding-bottom: 1.25rem;

      ~ .dropdown-checkbox-menu {
        display: inline-block;
        padding-top: 0.875rem;
      }
      .dropdown-checkbox__icon {
        transform: rotate(180deg);
      }
    }
  }

  &-button {
    background-color: $dropdown-bg;
    border: $dropdown-border-width solid $dropdown-border-color;
    border-radius: $dropdown-border-radius;
    color: $dropdown-color;
    display: block;
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
    line-height: $h4-line-height;
    padding: 0.75rem 2.5rem 0.75rem $dropdown-item-padding-x;
    position: relative;
    text-align: left;
    transition: $btn-transition;
    text-transform: capitalize;
    letter-spacing: normal;
    width: 100%;

    @include hover-focus {
      background-color: $light;
    }
  }

  &__group {
    border-right: $dropdown-border-width solid $dropdown-border-color;
    margin-bottom: 2rem;
    .border-bottom-title {
      border-bottom: $border-width solid $gray-2;
    }
    &:last-child {
      border: none;
    }
  }

  &.rows-view {
    .dropdown-checkbox__menu {
      width: 200%;

      .dropdown-border-bottom {
        border-bottom: $border-width solid $gray-2;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .group-title {
      font-size: $font-size-xxs;
      letter-spacing: normal;
      line-height: $h4-font-size;
      text-transform: capitalize;
    }
  }

  &__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
    max-width: 100%;
  }

  &__icon {
    bottom: 0;
    color: $dark-blue;
    font-size: $h3-font-size;
    margin: auto 0;
    position: absolute;
    right: $dropdown-item-padding-x;
    top: 0;
  }

  &__menu {
    border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
    border: $dropdown-border-width solid $dropdown-border-color;
    border-top: none;
    background: $white;
    display: none;
    position: absolute;
    width: 152.8%;
    z-index: 2;
    border-top-right-radius: 0.313rem;

    .padding-bottom {
      padding-bottom: space(3);
    }

    &.single-col {
      width: 100%;
      border-top-right-radius: 0;
    }

    .custom-control {
      padding-left: 2.5rem;

      &-label {
        font-size: $font-size-xxs;
        letter-spacing: normal;
        line-height: $h4-line-height;
        text-transform: capitalize;
        padding: space(1) 0 0 space(2);
      }

      &-input {
        cursor: pointer;
        width: 100%;
        z-index: 1;

        ~ .custom-control-label:before {
          height: 1.875rem;
          left: -2.25rem;
          top: -0.25rem;
          width: 1.875rem;
        }

        &:checked ~ .custom-control-label:after {
          background-image: url('#{$images-path}checkbox-selected.svg');
          background-size: cover;
          height: 1.875rem;
          left: -2.25rem;
          width: 1.875rem;
          top: -0.25rem;
        }
      }
    }
  }

  &__item {
    &:first-child {
      border-radius: 0;
    }
  }

  &__item ~ &__item {
    border-top: $dropdown-border-width solid $dropdown-border-color;
  }

  &__image {
    display: inline-block;
    vertical-align: bottom;
    margin-right: space(2);
    max-height: 1.15rem;
    max-width: 1.5rem;
    overflow: hidden;

    > img {
      max-height: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    &__group {
      &:nth-of-type(2n) {
        border: none;
        position: relative;
        &:before {
          content: '';
          border-right: $border-width solid $gray-2;
          right: -0.475rem;
          position: absolute;
          height: 17.75rem;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.show {
      .dropdown-checkbox__button {
        padding-bottom: 0.75rem;

        ~ .dropdown-checkbox-menu {
          border-top-right-radius: 0;
        }
      }
    }

    &__menu,
    &.rows-view &__menu {
      width: 100%;
    }

    &__group {
      border-right: none;
      border-bottom: $dropdown-border-width solid $dropdown-border-color;
      margin-bottom: space(3);
      &:last-child {
        margin-bottom: 0;
        .border-bottom-title {
          border: none;
        }
      }
    }

    &-button {
      border: none;
    }

    &.rows-view {
      .group-title {
        margin-bottom: 0;
      }
    }
  }
}
