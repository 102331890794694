@import '../style/common';

.ticker-nav {
    &__content {
        padding-right: space(5);
        position: relative;
    }

    &__content-inner {
        overflow-x: auto;
        padding-bottom: space(3);
    }

    &__tabs {

        .home-fund-dropdown {
            border-bottom: $border-style;
        }
    }
    /*  &__cta {
    margin: space(2) 0;

    @include media-breakpoint-up(md) {
      padding: space(1) 0;
      position: absolute;
      right: 0;
      bottom: 100%;
      margin-bottom: space(4);
    }
  }*/

    &__toggle {
        position: absolute;
        top: space(1) * -1;
        right: 0;
        background-color: $light;
        color: $primary;
        padding: space(1);
        width: 2.1875rem;
        height: 2.1875rem;
        transform: rotate(180deg);
        text-align: center;

        &.active {
            @include sr-only;
        }

        @include hover-focus {
            background-color: darken($light, 10%);
        }
    }

    &__row {
        display: flex;
        flex-wrap: nowrap;
    }

    &__item {
        @include padding(space(1) space(5));
        font-size: $font-size-xs;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        justify-content: space-between;
    }

    &__column {
        min-width: 15.5rem;
        flex: 0 0 20%;
        // &:not(:nth-child(5n)) {
        &:not(:last-child) {
            border-right: $border-style;
        }
    }

    &__ticker-text {
        font-weight: $font-weight-bold;
        margin-right: space(3);
    }

    &__price-text {
        margin-right: space(1);
    }

    &__delta-text {
        &--increase {
            color: $success;
        }

        &--decrease {
            color: $danger;
        }
    }

    &__timestamp {
        font-size: $font-size-xxs;
        margin-top: space(2);
    }
}
