@import '../style/common';

.your-information {
  &__header {
    background-color: $tertiary;
    background-image: url('#{$images-path}abstract-background-tertiary.svg');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    color: $white;
    padding: space(4);
  }

  &__heading {
    display: inline-block;
    margin: 0 space(4) 0 0;
  }

  &__body {
    border: $border-style;
    padding: space(4) space(3);
  }
}
