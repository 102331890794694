@import '../style/common';

.carousel-block {
  $carousel-block-switch-breakpoint: md;
  $fund-carousel-block-control-size: 3.1875rem;
  position: relative;

  &__heading {
    display: inline-block;
    margin: 0 space(4) 0 0;
  }

  &__cta {
    display: inline-block;
  }

  &__main {
    margin-top: space(4);
    position: relative;

    &--mobile {
      display: block;

      @include media-breakpoint-up($carousel-block-switch-breakpoint) {
        display: none;
      }
    }

    &--desktop {
      visibility: hidden;
      position: absolute;
      z-index: 0;
      width: 0;
      height: 0;

      .slick-track {
        display: flex;
        margin-left: 0;
      }

      .slick-slide {
        height: auto;
      }

      &-count {
        height: $fund-carousel-block-control-size;
        padding: 0 space(2);
        background-color: $gray-1;
        display: none;

        b,
        span {
          padding: 0 space(1);
        }
      }

      @include media-breakpoint-up($carousel-block-switch-breakpoint) {
        visibility: visible;
        position: relative;
        width: auto;
        height: auto;
      }
    }
  }

  // stylelint-disable selector-max-compound-selectors
  .slick-dots {
    li {
      margin: 0 0.125rem;

      button:before {
        color: $gray-2;
        opacity: 1;
      }
    }
    .slick-active button:before {
      color: $gray-3;
      opacity: 1;
    }
  }
  // stylelint-enable selector-max-compound-selectors

  .slick-disabled {
    color: $gray-3;
    pointer-events: none;
  }

  &__item {
    height: 100%;

    @include plain-hover-focus {
      box-shadow: none;

      &:before {
        background-image: none;
        background-color: $gray-2;
      }
    }
  }

  &__actions {
    display: none;

    @include media-breakpoint-up($carousel-block-switch-breakpoint) {
      display: flex;
    }
  }

  &__control {
    border-radius: 50%;
    transition: $btn-transition;
    height: $fund-carousel-block-control-size;
    width: $fund-carousel-block-control-size;
    color: $dark-blue;
    position: absolute;
    z-index: 9;
    top: space(7);

    &:hover {
      background-color: $light;
    }

    &--prev {
      left: -4.5rem;

      @include media-breakpoint-down(lg) {
        left: -2.5rem;
      }
    }

    &--next {
      right: -4.5rem;

      @include media-breakpoint-down(lg) {
        right: -2.5rem;
      }
    }
  }
}
