.fund-card.fund-card--dark {
    background-image: none !important;
    overflow: hidden;
    position: relative;

    &:after {
        background-image: url(/globalassets/home/us/common/fund-overlay.png);
        background-size: contain;
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        top: 0px;
        right: -38%;
        background-position-x: right;
        transition: 200ms ease-in-out;
    }

    &:hover {

        &:after {
            right: 0px;
            transition: 200ms ease-in-out;
        }
    }

    .fund-card__cta-block .fund-card__cta {
        position: absolute;
        bottom: 10%;
        z-index: 1;
    }
}
