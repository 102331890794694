// ----- asset paths -----
$images-path: './assets/images/';
$open-sans-url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800';

// ----- typography -----

$open-sans: 'Open Sans', 'Helvetica', sans-serif;

$font-family-base: $open-sans;
$headings-font-family: $open-sans;
$input-font-family: $open-sans;


$font-size-base: 0.9375rem;
$font-size-xxxs: $font-size-base * 0.666;   // ~10px
$font-size-xxs: $font-size-base * 0.733;    // ~11px
$font-size-xs: $font-size-base * 0.8;       // ~12px
$font-size-sm: $font-size-base * 0.889;     // ~13px
$font-size-md: $font-size-base;             // ~15px
$font-size-lg: $font-size-base * 1.2;       // ~18px

$font-sizes: (
  xxxs: $font-size-xxxs,
  xxs: $font-size-xxs,
  xs: $font-size-xs,
  sm: $font-size-sm,
  md: $font-size-md,
  lg: $font-size-lg,
);

$h1-font-size: $font-size-base * 2;          // ~30px
$h2-font-size: $font-size-base * 1.6;        // ~24px
$h3-font-size: $font-size-base * 1.266;      // ~19px
$h4-font-size: $font-size-base * 1.133;      // ~17px
$h5-font-size: $font-size-base;              // ~15px
$h6-font-size: $font-size-sm;                // ~13px

$heading-font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);

$display1-size: $font-size-base * 2.533;     // ~38px
$display2-size: $font-size-base * 1.875;     // ~30px

$display-font-sizes: (
  1: $display1-size,
  2: $display2-size,
);

$text-util-size-lg: $font-size-sm;
$text-util-size-md: $font-size-xs;
$text-util-size-sm: $font-size-xxs;
$text-util-size-xs: $font-size-xxxs;

$text-util-font-sizes: (
  lg: $text-util-size-lg,
  md: $text-util-size-md,
  sm: $text-util-size-sm,
  xs: $text-util-size-xs,
);

$btn-font-size: $text-util-size-md;
$small-font-size: $text-util-size-lg;
$input-font-size: $text-util-size-sm;
$input-btn-font-size-lg: $text-util-size-lg;
$btn-font-size-sm: $font-size-xs;

$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-base: $font-weight-normal;

$headings-font-weight: $font-weight-bold;
$text-util-font-weight: $font-weight-semi-bold;
$input-font-weight: $text-util-font-weight;
$display1-weight: $font-weight-extra-bold;
$display2-weight: $font-weight-extra-bold;
$btn-font-weight: $text-util-font-weight;

$line-height-base: 1.666;

$h1-line-height: 1.166;
$h2-line-height: 1.25;
$h3-line-height: 1.1315;
$h4-line-height: 1.3529;
$h5-line-height: 1.2;
$h6-line-height: $h5-line-height;

$heading-line-heights: (
  1: $h1-line-height,
  2: $h2-line-height,
  3: $h3-line-height,
  4: $h4-line-height,
  5: $h5-line-height,
  6: $h6-line-height,
);

$input-line-height: $line-height-base;
$display-line-height: 1.1315;

$letter-spacing-base: 0;

$display1-letter-spacing: -0.04375rem;
$display2-letter-spacing: -0.03125rem;

$display-letter-spacings: (
  1: $display1-letter-spacing,
  2: $display2-letter-spacing,
);

$text-util-lg-letter-spacing: 0.125rem;
$text-util-md-letter-spacing: 0.09375rem;
$text-util-sm-letter-spacing: 0.0625rem;
$text-util-xs-letter-spacing: $text-util-sm-letter-spacing;

$text-util-letter-spacings: (
  lg: $text-util-lg-letter-spacing,
  md: $text-util-md-letter-spacing,
  sm: $text-util-sm-letter-spacing,
  xs: $text-util-xs-letter-spacing,
);

// ----- colors -----

// primary
$blue: #17468f;
$teal: #09a3ae;
$turquoise: #0ab2b0;
$light-teal: #26ecfc;
$light-blue: #8cb9e9;
$dark-blue: #001e6d;
$purple: #a200b5;
$green: #108A12;
$red: #e00;

// grays
$gray-1: #f0f3f6;
$gray-2: #e6e7e8;
$gray-3: #939598;
$gray-4: #545e6b;
$gray-5: #323232;
$gray-6: #555;
$gray-7: #d7d7d7;
$gray-8: #f1f2f3;
$gray-9: #4a4a4a;

// other
$black: #000;
$black-16: rgba($black, 0.16);
$black-20: rgba($black, 0.2);
$black-87: rgba($black, 0.87);
$white: #fff;
$transparent: transparent;
$moderate-blue: #357ebd;

// theme
$primary: $blue;
$secondary: $turquoise;
$tertiary: $dark-blue;
$pastel: $light-blue;
$accent: $purple;
$highlight: $light-teal;
$dark: $gray-5;
$light: $gray-1;
$info: $gray-4;
$muted: $gray-3;
$text-muted: $muted;
$theme-green: $green;

$theme-colors: (
  'accent': $accent,
  'highlight': $highlight,
  'muted': $muted,
  'pastel': $pastel,
  'tertiary': $tertiary,
  'theme-green': $theme-green,
);

// gradients
$accent-linear-gradient: linear-gradient(225deg, $accent, $tertiary);
$highlight-linear-gradient: linear-gradient(225deg, $highlight, $secondary);
$primary-linear-gradient: linear-gradient(225deg, $primary, $tertiary);
$secondary-linear-gradient: linear-gradient(297deg, $purple 30%, $tertiary 25%, $tertiary 50%, $tertiary 50%, $tertiary 100%);
$gleam-linear-gradient: linear-gradient(113deg, rgba($black, 0) 15%, rgba($black, 0) 15%, rgba($primary, 0.15) 15%, rgba($primary, 0.15) 100%);

$body-bg: $white;
$body-color: $dark;
$border-color: $gray-2;
$link-color: $tertiary;

$yiq-text-dark: $dark;
$yiq-text-light: $white;
$simmer-light: rgba($primary, 0.2);

// ----- spacing and layout-----
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  // custom below here
  6: $spacer * 6,
  7: $spacer * 12,
  8: $spacer * 24,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// $input-btn-padding-x: map-get($spacers, 3);
// $input-btn-padding-y: map-get($spacers, 2);
// $list-inline-padding: map-get($spacers, 3);
// $navbar-brand-font-size: $font-size-base;
// $navbar-brand-padding-y: 0;
// $navbar-nav-link-padding-x: map-get($spacers, 3);
// $navbar-padding-y: map-get($spacers, 3);
// $nav-link-padding-y: map-get($spacers, 3);
// $navbar-dark-color: $white;
// $navbar-dark-hover-color: $link-color;
// $navbar-dark-active-color: $white;
// $navbar-light-color: $dark;
// $navbar-light-hover-color: $link-color;
// $navbar-light-active-color: $dark;

// ----- other -----
$border-radius: 0.3125rem;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;
$border-width: 1px;
$border-style: $border-width solid $border-color;
// // $border-radius: 0;
// // $border-radius-sm: $border-radius;
// // $border-radius-lg: $border-radius;
$box-shadow: none; // 0 1.125rem 2.1875rem 0 $black-16;
$box-shadow-light: $box-shadow;
$box-shadow-dark: none; // 0 0.625rem 1.25rem 0 $black-20;
// $btn-border-radius: 0.375rem;
// $btn-disabled-opacity: 0.65;
$btn-padding-x: map-get($spacers, 4);
$btn-padding-y: map-get($spacers, 3);
$btn-padding-x-sm: map-get($spacers, 2);
$btn-padding-y-sm: map-get($spacers, 1);
$btn-box-shadow: none;
$trans-duration: 0.3s;
$trans-easing: ease-out;

// ----- form inputs -----
$input-padding-x: map-get($spacers, 3);
$input-padding-y: $input-padding-x;
$input-btn-padding-x-lg: map-get($spacers, 4);
$input-btn-padding-y-lg: $input-btn-padding-x-lg;
$input-color: $body-color;
$input-placeholder-color: $muted;
$input-bg: $white;
$input-border-color: $border-color;
$input-box-shadow: none;
$input-focus-border-color: $input-border-color;

$custom-control-indicator-border-color: $border-color;
$custom-control-indicator-size: 1.1875rem;
$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-checked-color: $accent;
$custom-control-indicator-checked-bg: $custom-control-indicator-bg;
$custom-control-indicator-checked-border-color: $custom-control-indicator-border-color;
$custom-control-indicator-active-bg: $light;

$enable-validation-icons: false;

$form-feedback-font-size: $font-size-xxs;

// ----- card -----
$card-border-color: $light;
$card-border-radius: 0;
$card-border-width: $border-width;
$card-spacer-x: map-get($spacers, 3);
$card-spacer-y: map-get($spacers, 4);

// ----- form inputs -----
// $custom-checkbox-indicator-box-shadow: none;
// // $custom-checkbox-indicator-checked-box-shadow: $box-shadow;
// $custom-checkbox-indicator-border-radius: 0.125rem;
// $custom-control-indicator-background-size: 100%;
// $custom-control-indicator-checked-color: $white;
// $custom-checkbox-indicator-icon-path: 'M9.22 15.77L20.09 6l1.15 1.28-12.02 10.8L3 12.47l1.15-1.27z';
// $custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='#{$custom-control-indicator-checked-color}' d='#{$custom-checkbox-indicator-icon-path}'/></svg>");
// $custom-control-indicator-size: 1rem;
// $custom-control-indicator-border-color: $tertiary;
// $custom-control-indicator-border-width: $border-width;
// $custom-control-gutter: map-get($spacers, 2);
// $custom-control-label-disabled-color: $darker-gray-70;
// $custom-control-indicator-active-bg: $secondary;

// $input-placeholder-color: $darker-gray-70;
// $input-bg: $white;
// $input-disabled-bg: $gray-75;
// $input-padding-y: map-get($spacers, 3);
// $input-padding-x: $input-padding-y;

// ----- pagination -----
$pagination-padding-y: map-get($spacers, 3);
$pagination-padding-x: 0;
$pagination-padding-y-sm: map-get($spacers, 2);
$pagination-padding-x-sm: 0;
$pagination-color: $dark;
$pagination-bg: $transparent; // $white;
$pagination-border-width: 0;
$pagination-border-radius: 50%;
$pagination-margin-left: map-get($spacers, 2);
$pagination-border-color: $pagination-bg;
// $pagination-focus-box-shadow: 0 0 0.125rem 0 $dark-blue-25;
// $pagination-focus-outline: 0;
$pagination-hover-color: $pagination-color;
// $pagination-hover-bg: $dark-blue-25;
// $pagination-hover-border-color: $pagination-hover-bg;
$pagination-active-color: $white;
$pagination-active-bg: $accent;
// $pagination-active-border-color: $pagination-active-bg;
// $pagination-disabled-color: rgba($dark, $btn-disabled-opacity);
// $pagination-disabled-bg: $pagination-bg;
// s$pagination-disabled-border-color: $pagination-disabled-bg;

// $tooltip-color: $white;
// $tooltip-bg: $tertiary;
// $tooltip-arrow-color: $tertiary;
// $tooltip-padding-y: map-get($spacers, 2);
// $tooltip-padding-x: map-get($spacers, 3);
// $tooltip-arrow-height: 0.75rem;
// $tooltip-arrow-width: 1rem;

// ----- badges/pills -----
// $badge-font-size: $font-size-xxs;
// $badge-font-weight: $font-weight-normal;
$badge-padding-y: map-get($spacers, 1);
$badge-padding-x: map-get($spacers, 2);

// // ----- dropdown menu -----
$dropdown-min-width: 100%;
$dropdown-padding-y: 0;
$dropdown-font-size: $input-font-size;
$dropdown-bg: $input-bg;
$dropdown-border-color: $border-color;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-spacer: $dropdown-border-width * -1;
$dropdown-link-color: $body-color;
$dropdown-item-padding-x: map-get($spacers, 3);
$dropdown-item-padding-y: map-get($spacers, 3);
$dropdown-link-hover-bg: $primary;
$dropdown-link-hover-color: $dropdown-bg;

// ----- tooltips -----
$tooltip-opacity: 1;
$tooltip-border-radius: 0.267rem;

// ----- nav tabs -----
$nav-tabs-border-color: $gray-2;
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: $tertiary;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: $gray-4;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg;
$nav-pills-link-color: $white;
$nav-pills-link-bg: $gray-4;
$nav-pills-link-active-color: $gray-5;
$nav-pills-link-active-bg: $white;

// ----- jumbotron -----
$jumbotron-bg: $tertiary;
$jumbotron-color: $white;
$jumbotron-padding: map-get($spacers, 6);
$jumbotron-padding-sm: map-get($spacers, 5) map-get($spacers, 4);

// ----- modals -----
$modal-content-border-radius: 0;
$modal-content-border-width: 0;
$modal-backdrop-opacity: 0.9;

// ----- navigation -----
$navbar-nav-link-padding-x: map-get($spacers, 3);
$navbar-light-color: $body-color;
$navbar-light-hover-color: lighten($navbar-light-color, 15%);
$navbar-light-active-color: $navbar-light-color;
$navbar-toggler-border-radius: 0;
$navbar-toggler-padding-x: 0;

// ----- slick -----
$slick-dot-size: 3rem;

// ----- site header height -----
$site-header-height: 5.938rem;
