@import '../style/common';

.latest-digital-grid {
  $latest-digital-grid-switch-breakpoint: md;
  $latest-digital-grid-control-size: 2.1875rem;
  position: relative;

  &__heading {
    @include make-heading(4);
    margin: 0 space(6) 0 0;
  }

  &__gridDesktop {
    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      display: none;
    }
  }

  &__grid-right {
    .grid-card__image-container {
      display: none;
    }

    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      display: block;
    }
  }

  &__main {
    position: relative;

    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      margin-top: 0;
    }

    &--desktop {
      .slick-track {
        display: flex;
      }

      .slick-slide {
        height: auto;
      }

      .slick-list {
        @include media-breakpoint-up($latest-digital-grid-switch-breakpoint) {
          @include margin(null $grid-gutter-width / -2);
        }
      }

      &-count {
        height: $latest-digital-grid-control-size;
        padding: 0 space(2);
        background-color: $gray-1;
        display: none;

        b, span {
          padding: 0 space(1);
        }

        @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
          display: flex;
        }
      }
    }
    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      margin-bottom: space(3);
    }
  }

  &__placement {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;

    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      display: flex;
    }
  }

  .slick-initialized .slick-slide {
    display: flex;

    &:first-child {
      display: none;
    }

    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      &:first-child {
        display: block;
      }
    }
  }

  .slick-disabled {
    background-color: $gray-3;
    pointer-events: none;
  }

  &__image-icon {
    color: $white;
    display: flex;
    position: absolute;
    bottom: 2rem;
    right: space(3);
    padding: 2px;
    transition: color $trans-duration $trans-easing;

    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      bottom: space(3);
    }
  }

  &__image {
    transition: transform 1s;

    @include hover-focus {
      transform: scale(1.07);
    }

    &-container {
      overflow: hidden;
    }
  }

  &__item {
    height: 100%;
    margin-top: space(3);

    @include media-breakpoint-up($latest-digital-grid-switch-breakpoint) {
      @include margin(space(2) $grid-gutter-width / 2 0);
    }

    @include media-breakpoint-down($latest-digital-grid-switch-breakpoint) {
      margin-top: space(3);
    }

    @include media-breakpoint-down(sm) {
      margin: space(4) space(2) 0;
    }
  }

  &__control {
    transition: $btn-transition;
    height: $latest-digital-grid-control-size;
    width: $latest-digital-grid-control-size;
    background-color: $dark-blue;
    color: $white;

    &--prev {
      right: $latest-digital-grid-control-size;
    }
  }
}
