﻿
#generic-teams-page {
    margin-top: -85px;

    .expandable-nav {
        display: none;
    }

    div.wrapper {
        width: 100%;
    }

    section.main {
        width: 100%;
        padding: 0;
    }

    .hero-container {
        position: relative;
        top: 80px;
    }

    .hero-container .hero-image {
        display: block;
        width: 100%;
        height: 376px;
        /*background-image: url(/globalassets/home.au/about-us/teams/team-page-hero.jpg);*/
        background-position: center;
        background-size: cover;
        padding: 5px;
    }

    .hero-container .hero-image div.title.main {
        color: #fff;
        padding-top: 90px;
        position: relative;
        display: block;
        max-width: 1300px;
        margin: auto;
        z-index: 5;
        font-size: 36px;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-weight: 100;
    }

    .hero-container .hero-overlay {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 376px;
        background-image: url(/globalassets/home.au/about-us/teams/light-overlay.svg);
        background-repeat: no-repeat;
    }

    .leadership-container {
        width: 100%;
        max-width: 1300px;
        margin: auto;
        font-family: Open Sans, Helvetica, sans-serif;
        position: relative;
        top: 80px;
        margin-bottom: 160px;
    }

    .content .leadership-team-container h2.title {
        all: unset;
        color: unset !important;
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        margin: 40px 0;
        position: relative;
        display: block;
    }

    .members-container {
        display: block;
        width: 100%;
    }

    .members-container .member {
        margin-top: 60px;
        border-bottom: 1px solid #ccc;
        padding: 0 50px;
        padding-bottom: 60px;
        min-height: 250px;
    }

    .members-container .member-image {
        width: 30%;
        display: inline-block;
        float: left;
    }

    .members-container .member-image.mobile {
        display: none;
    }

    .members-container .member-image img {
        width: 275px;
    }

    .members-container .member-info-container {
        width: 70%;
        display: inline-block;
    }

    .members-container .member-info-container .member-name {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 19px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 10px;
    }

    .members-container .member-info-container .member-region .region-pill {
        margin-right: 5px;
        font-size: 14px;
        font-family: "Open Sans", Helvetica, sans-serif;
        background-color: #17468f;
        padding: 2px 5px;
        border-radius: 5px;
        color: #fff;
    }

    .members-container .member-info-container .member-title {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 30px;
    }

    .members-container .member-info-container .member-title.has-contact-details {
        margin-bottom: 0;
    }

    .members-container .member-info-container .member-contact {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 14px;
        margin-bottom: 30px;
    }

    .members-container .member-info-container .member-contact span a {
        color: #000;
    }

    .members-container .member-info-container .member-contact span i {
        margin-right: 5px;
    }

    .members-container .member-info-container .member-contact span:nth-child(2n) i {
        margin-left: 10px;
    }

    .members-container .member-info-container .member-info {
        font-family: "Open Sans", Helvetica, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 10px;
    }

    @media screen and (max-width: 1320px) {
        .leadership-container {
            padding: 5px;
        }
    }

    @media screen and (max-width: 1100px) {
        .members-container .member-image {
            width: 50%;
            display: inline-block;
        }

        .members-container .member-info-container {
            width: 50%;
            display: inline-block;
        }
    }

    @media screen and (max-width: 991px) {
        .members-container {
            span {
                &.contact-type {
                    display: block;

                    &.contact-phone {
                        i.fa-phone {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .members-container .member-image.desktop {
            display: none;
        }

        .members-container .member-image.mobile {
            width: 100%;
            display: block;
            margin-bottom: 10px;
        }

        .members-container .member-info-container {
            width: 100%;
            display: block;
        }
    }

    @media screen and (max-width: 500px) {

        .hero-container .hero-image,
        .hero-container .hero-overlay {
            height: 200px;
        }

        .members-container .member-image img {
            width: 100%;
        }

        .members-container .member {
            padding: 5px;
        }

        .members-container .member-info-container .member-contact span {
            display: block;
            width: 100%;
        }

        .members-container .member-info-container .member-contact span:nth-child(2n) i {
            margin-left: 0;
        }
    }
}
