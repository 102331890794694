// Styles from home page css block
.footer-button-dismiss-modal {
    border: 1px solid #17468f;
    padding: 10px;
    border-radius: 5px;
    background-color: #17468f;
    color: #fff;
    transition: background-color .3s ease-out, color .3s ease-out;
    cursor: pointer;
    font-size: 10px;
    font-weight: 700;
}

.footer-button-dismiss-modal:hover {
    background-color: #fff;
    color: #17468f;
}

#complaints-whistleblower-policy-modal .modal-body ul {
    width: 100%;
}

#complaints-whistleblower-policy-modal .modal-body ul li {
    all: unset;
    background: #fff;
    display: block;
    text-align: center;
    cursor: pointer;
    padding: 10px;
    transition: background-color .3s ease-out, color .3s ease-out;
}

#complaints-whistleblower-policy-modal .modal-body ul li a {
    all: unset;
    color: #000;
    transition: background-color .3s ease-out, color .3s ease-out;
}

#complaints-whistleblower-policy-modal .modal-body ul li:hover {
    background: #17468f;
}

#complaints-whistleblower-policy-modal .modal-body ul li:hover a {
    color: #fff;
    text-decoration: none;
}

#complaints-whistleblower-policy-modal .modal-body ul li a:hover {
    text-decoration: none;
}

#complaints-whistleblower-policy-modal .modal-footer {
    border-top: none;
}

//Complaints modal
#complaints-whistleblower-policy-modal {
    .modal-dialog {
        max-width: unset;
        width: 600px;
        margin: 30px auto;

        .modal-content {
            border-radius: 6px;
            box-shadow: 0 5px 15px rgba(0,0,0,.5);

            .modal-header {
                padding: 15px;

                button.close {
                    font-size: 21px;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .2;
                }
            }

            .modal-body {
                padding: 0;
                
                ul {
                    padding: 0;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #complaints-whistleblower-policy-modal {
        margin: 0;

        .modal-dialog {
            margin: 30px;
            width: auto;
        }
    }
}


footer {
    font-family: "Open Sans", "Helvetica", sans-serif;

    .navigation.footerNav, .footerCopyRight {
        display: none;
    }

    .hp-footer-navigation {
        display: block !important; //To override an inline style display none
        width: 100%;
        background-color: #001e6d;
        color: #fff;
        font-size: 14px;
        float: left;

        .hp-social-icon {
            text-align: center;
            font-size: 30px;

            a {
                color: #fff;
            }

            i {
                transition: transform .4s ease-out;

                &:hover {
                    transform: translateY(-5px);
                }
            }
        }

        ul {
            display: table;
            margin: auto;
            padding-top: 10px;
            list-style-type: none;
        }

        li {
            float: left;
            display: inline;
            margin-right: 15px;
            text-transform: uppercase;
            font-weight: bold;
            line-height: 1.4em;

            a {
                color: #fff;
            }
        }

        .hp-copyright {
            display: table;
            margin: auto;
            margin-top: 15px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .au_address {
        margin-left: 58px;
    }
}