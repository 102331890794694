@import '../style/common';

.byline {
  &__content {
    border-top: $border-width solid $gray-2;
    border-bottom: $border-width solid $gray-2;
  }

  &__title {
    font-size: $h4-font-size;
  }

  &__author {
    display: flex;
    align-items: center;

    &-image {
      flex-shrink: 0;
    }

    &-name {
      &-link {
        @include link-focus-outline;
      }

      font-weight: $font-weight-bold;
      font-size: $font-size-md;
      display: inline-block;
      margin-bottom: space(1);
      line-height: $line-height-base;
    }

    &-title,
    &-domain {
      font-size: $font-size-md;
    }
  }

  &__contributors-label {
    font-size: $font-size-md;
    font-weight: $font-weight-normal;
    line-height: $line-height-base;
  }

  &__contributors-names {
    font-weight: $font-weight-bold;
  }

  &__list {
    &-title {
      &-link {
        @include link-focus-outline;
      }

      display: inline-flex;
      width: 7.5rem;
      font-size: $h4-font-size;
    }

    &-subtitle {
      &:before {
        content: '|';
        padding-right: space(3);

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }
}
