@import '../style/common';

.promo-banner {
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $tertiary;
  padding: space(5);

  &__heading, &__subheading {
    color: $white;
  }

  &__subheading {
    font-size: $h3-font-size;
  }
}
