﻿.tooltip-strip {
    background-color: rgba(0,200,5,1);
    background-image: initial;
    background-image: unset;
    overflow: hidden;
    position: relative;
    z-index: 400;
    min-height: 60px;
    padding: 10px 0;
    cursor: pointer;

    &:before {
        background-color: rgba(0,0,0,0.04);
        content: " ";
        position: absolute;
        right: -300px;
        display: block;
        height: 800px;
        width: 800px;
        transform: rotate(-60deg);
        margin-top: -30vh;

        @include media-breakpoint-down(xl) {
            right: -468px;
        }

        @include media-breakpoint-down(md) {
            right: -555px;
        }
        //Remove all overlays on the desktop to make it clickable.
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__strip:before {
        background-color: rgba(0,0,0,0.08);
        right: -200px;

        @include media-breakpoint-down(xl) {
            right: -368px;
        }

        @include media-breakpoint-down(md) {
            right: -455px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__strip {
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: left;
        line-height: 20px;
        font-weight: 600;

        @media (max-width: 450px)
        {
            text-align:center;
        }

        a {
            color: white;
            text-decoration: none;
        }
    }
}
