@import '../style/common';

.passport {
  display: flex;
  align-items: center;
  position: relative;

  &--light {
    color: $body-color;
  }

  &--dark {
    color: $white;
  }

  &__image {
    flex-shrink: 0;
  }

  &__content {
    margin-left: space(3);
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-md;

    &--interactive {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  &--dark &__title {
    color: inherit;
  }

  &__subtitle {
    font-size: $font-size-xs;
  }
}
