@import '../style/common';

.hero {
  position: relative;
  padding: space(6) 0 space(4);
  z-index: 0;

  @include media-breakpoint-up(md) {
    padding-top: 9.875rem;
    padding-bottom: space(5);
  }

  &__bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;

    &--overlay {
      background-position: center left;
      z-index: -1;
    }
  }

  &__content-container {
    @include media-breakpoint-up(sm) {
      max-width: 80%;
    }

    @include media-breakpoint-up(lg) {
      max-width: 50%;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 55%;
    }
  }

  &__description {
    font-size: $font-size-lg;
    margin-top: space(3);
  }

  &__nav {
    list-style: none;
    padding-left: space(4);
    border-left: 2px solid $secondary;
  }

  &__link {
    color: $white;

    @include hover-focus {
      color: $white;
      font-weight: $font-weight-bold;
      text-decoration: none;
    }
  }
}
