@import '../style/common';

.bio {
  border-bottom: $border-style;
  padding-bottom: space(4);
  margin-bottom: space(4);

  &__image {
    &-column {
      text-align: center;
    }

    &-desktop {
      width: 100%;
      max-width: 14.0625rem;
      max-height: 14.0625rem;
    }
  }
}
