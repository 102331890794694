// Webinar Page AU
.page-webreplays, .page-webcalendar {
    .hero {
        h1 {
            color: #5DBD5C;
        }
    }
}

.webinar-tabs {
    position: relative;
    margin-top: -45px;

    li {
        width: 50%;
    }

    a.nav-link {
        color: #fff;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 2px;
        background-color: rgba(255,255,255,0.16);
        line-height: 30px;
        font-weight: 700;

        &.current {
            color: #323232;
            background-color: #fff;
        }
    }
}

.au-replays-section {
    .replay-selector {
        color: #17468F;
        font-weight: 600;
        margin-bottom: 10px;

        p {
            margin-bottom: 0;
        }

        .dropdown-toggle.replay-subject-list {
            border-bottom: 2px solid #17468f;
            width: 435px;
            max-width: 100%;
            display: inline-block;
            color: #17468F;

            &::after {
                float: right;
                margin-top: 10px;
            }

            &:hover {
                text-decoration: none;
                color: #17468F;
                cursor: pointer;
            }
        }

        .dropdown-menu {
            ul {
                padding: 0;
                margin: 0;

                li.dropdown-item {
                    padding: 0.5rem 1rem;
                    line-height: 1;

                    &:hover {
                        color: #17468F;
                        background-color: inherit;
                        text-decoration: none;
                    }

                    a {
                        color: #323232;
                        display: block;
                        font-weight: 600;

                        &:hover {
                            text-decoration: none;
                            color: #17468F;
                        }
                    }

                    &.selected a {
                        color: #17468F;
                    }
                }
            }
        }
    }

    .about-cpd {
        color: #17468F;

        a.contact-link {
            text-decoration: underline;
            color: #17468F;
        }
    }

    .replays-section {
        .box-container-square, .box-inner-small, .replay-card, .replay-content {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .box-inner-small {
            padding: 1rem;
        }

        .replay-card {
            border: 1px solid rgba(0,30,109,0.24);
            padding: 1.5rem;
        }

        .replay-content {
            display: flex;
            flex-direction: column;

            .webinar-details {
                flex-grow: 1;
            }

            .webinar-credit {
                color: #008A03;
                background-color: #E3F4E3;
                padding: 0.25rem 0.5rem;
                display: inline-block;
            }
        }

        .toggle-info {
            .chevron {
                &::before {
                    border-style: solid;
                    border-width: 0.16em 0.16em 0 0;
                    border-color: #333436;
                    content: '';
                    position: relative;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    transform: rotate(135deg);
                    top: 0;
                }
            }

            .chevron.face-up {
                &::before {
                    transform: rotate(-45deg);
                    top: 5px;
                }
            }
        }
    }
}

.accordion-panel.upcoming-event {
    border: 1px solid rgba(0,30,109,0.24);
    border-radius: 5px;
}

.panel-content {
    .webinar-btn {
        width: 200px;
    }

    .webinar-credit {
        color: #008A03;
        background-color: #E3F4E3;
        font-weight: 700;
        padding: 1rem;
        width: 200px;
        display: inline-block;
        margin: auto 1rem;
        text-align: center;
        border-radius: 0.3rem;
    }
}

@media (max-width: 991px) {
    .panel-content .webinar-credit {
        display: block;
        margin: 1rem 0;
    }
}

.webinar-registration {
    .section-header {
        border-bottom: none;
    }
}

.registration-content {
    display: flex;
    justify-content: space-between;

    .webinar-credit {
        color: #008A03;
        background-color: #E3F4E3;
        font-weight: 700;
        padding: 1rem;
        width: 200px;
        display: inline-block;
        margin: auto 1rem;
        text-align: center;
        border-radius: 0.3rem;
    }
}

.registration--details {
    .passport__image img {
        width: 80%;
    }

    .passport__title {
        color: #002060;
    }

    .passport_subtitle {
        font-size: 0.9375rem;
    }
}

@media (max-width: 767px) {
    .registration-content {
        flex-direction: column;

        .webinar-credit {
            margin: 1rem auto;
        }
    }
}

.no-items-section {
    border: 1px solid #8CB9E9;
    border-radius: 6px;
    background: url(/Static/au/images/abstract-background-light.svg) center no-repeat;
    background-size: 1300px 520px;

    .replay-no-items {
        .btn {
            min-width: 200px;
        }
    }
}

.grey-text {
    color: #939598;
}

.no-replay-message {
    p:last-child {
        font-size: 1.186875rem;
        line-height: 1.1315;
    }
}

@media (max-width: 1024px) {
    .mobile-initial-hide {
        display: none;
    }
}

@media (min-width: 1025px) {
    .replay-more-less {
        display: none;
    }
}
