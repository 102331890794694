@import '../style/common';

.thought-leaders-card {
  padding: 1.875rem;
  display: block;
  position: relative;

  @include plain-hover-focus {
    color: $body-color;
    text-decoration: none;
  }

  &:before {
    /*background: url('#{$images-path}abstract-background.svg') no-repeat right center;
    background-size: cover;*/
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity $trans-duration $trans-easing;
  }

  @include hover-focus {
    z-index: 1;

    &:before {
      opacity: 1;
    }
  }


  &__image-container {
    margin-bottom: 0.625rem;
  }

  @include media-breakpoint-down(md) {
    &__item {
      margin-bottom: 1.875rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
