@import '../style/common';

.fund-link {
  display: inline-block;
  transition: transform $trans-duration $trans-easing;
  color: $black;

  &:hover {
    text-decoration: none;
    transform: translateX(1rem);

    .fund-link__icon {
      color: $dark-blue;
    }
  }

  &__icon {
    transition: color $trans-duration $trans-easing;
  }

  &__ticker {
    color: $primary;
    font-weight: $font-weight-extra-bold;
    margin-right: space(1);
  }
}
