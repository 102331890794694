@import '../style/common';

.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-link {
    @include link-focus-outline;

    display: inline-flex;
    color: $blue;

    &:hover {
      text-decoration: underline;

      .breadcrumb-link__icon {
        color: $dark;
        text-decoration: none;
      }
    }

    &__icon {
      color: $dark-blue;
      transform: rotate(180deg);
    }
  }

  .dropdown {
    margin-left: space(2);

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }

    &__button {
      @include link-focus-outline;

      position: relative;
      margin-left: space(4);

      &:before {
        content: '';
        position: absolute;
        border-left: $border-width solid $gray-3;
        left: - space(3);
        top: space(1);
        height: space(4);
      }
    }

    &__label {
      color: $primary;
      font-size: $font-size-xs;
      letter-spacing: $text-util-md-letter-spacing;
    }
  }
}
