@import '../style/common';

.thank-you {
  @include media-breakpoint-down(sm) {
    padding-bottom: space(4);
    margin-bottom: 2.5rem;
  }

  &__body {
    color: $gray-9;
    font-size: $font-size-lg;
  }

  &__left-block {
    border-right: $border-width solid $gray-2;
    padding-right: 2rem;

    @include media-breakpoint-down(md) {
      padding-right: space(4);
    }

    @include media-breakpoint-down(sm) {
      border-bottom: $border-width solid $gray-2;
      border-right: none;
      padding-bottom: space(3);
      padding-right: space(3);
      margin-bottom: 2.5rem;
    }
  }

  &__right-block {
    @include media-breakpoint-up(md) {
      padding-left: 4rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 5rem;
    }

    &-cta-button:first-child {
      margin-bottom: space(4);
    }
  }

  &__badge {
    border-radius: 0;
    background-color: $purple;
    color: $white;
    letter-spacing: $text-util-sm-letter-spacing;
    font-weight: $font-weight-semi-bold;
    line-height: $h6-line-height;

    @media print {
      color: $gray-5;
      background-color: $white;
    }
  }
}
