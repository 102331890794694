@import '../style/common';

.share-widget {
  display: inline-block;

  &__toggle-button {
    border-radius: $dropdown-border-radius;

    &.dropdown-toggle {
      @include link-focus-outline;

      &:after {
        display: none;
      }
    }

    @include hover-focus {
      color: $white;
      background-color: $primary;

      .share-widget__toggle-button-icon {
        color: $white;
      }
    }

    &-icon {
      color: $primary;
    }
  }

  &__menu {
    background-color: $white;
    border: 0;
    border-radius: 0;

    &-inner {
      display: flex;
      flex-direction: column-reverse;
    }

    &-header {
      padding: 0.75rem space(3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: $border-width solid $gray-8;
    }

    &-close {
      color: $transparent;
      pointer-events: none;

      &.menu-close {
        @include link-focus-outline;
      }

      @include hover-focus {
        color: $primary;
        pointer-events: all;
      }
    }

    &.dropdown-menu {
      margin: space(4) 0;
      min-width: $modal-sm;
      font-size: $font-size-base;

      &.widget-menu-left {
        transform: translate(0%, 0);
        right: 0;
        left: auto;
      }

      &.widget-menu-center {
        left: 50%;
        transform: translate(-50%, 0);
      }

      &.widget-menu-right {
        transform: translate(0%, 0);
        left: 0;
        right: auto;
      }
    }
  }

  &.show {
    .share-widget__overlay {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $black-20;
      pointer-events: none;
      z-index: 10;
    }

    .dropdown-toggle:after {
      display: inline-block;
      position: absolute;
      top: 100%;
      border-top: 0;
      border-right: space(3) solid $transparent;
      border-left: space(3) solid $transparent;
      border-bottom: space(4) solid $white;
      z-index: $zindex-dropdown;
    }
  }

  &__action-icon {
    margin-right: space(4);
    color: $primary;
  }

  &__action {
    width: 100%;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    border-bottom: $border-width solid $gray-8;
    color: $black;

    @include hover-focus {
      color: $white;
      background-color: $primary;
      text-decoration: none;
      transform: none;

      .share-widget__action-icon {
        color: $white;
      }
    }
  }
}
