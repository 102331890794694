@import '../style/common';

.callout-text {
  $callout-text-switch-breakpoint: md;
  position: relative;
  border: $border-width solid $gray-7;
  display: flex;

  .rich-text-block--insight-content blockquote {
    margin: 0;
    padding: space(3);

    &:before {
      display: none;
    }
  }

  &__icon {
    margin-right: space(2);
    margin-bottom: space(2);

    &-link {
      @include link-focus-outline;

      color: $blue;

      @include hover-focus {
        background-color: $blue;
        border-radius: $tooltip-border-radius;
        color: $white;
        text-decoration: none;

        .callout-text__icon-label,
        .fa-twitter-round {
          color: $white;
        }
        @include media-breakpoint-up(lg) {
          &:before {
            content: '';
            background-color: $purple;
            transform: scale(1);
          }
        }
      }

      @include media-breakpoint-up($callout-text-switch-breakpoint) {
        &:before {
          content: '';
          background-color: $transparent;
          width: 0.125rem;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transform: scale(0);
          transition: transform $trans-duration linear;
          transform-origin: top;
        }
      }
    }

    &-label {
      display: block;
      font-size: $text-util-size-sm;
      font-weight: $text-util-font-weight;
      color: $dark;
    }
  }

  @include media-breakpoint-down($callout-text-switch-breakpoint) {
    border: solid $purple;
    border-width: 0 0 0 0.125rem;
  }
}
