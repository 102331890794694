﻿

.attachment-block {
    position: relative;
    overflow:hidden;

    &:before {
        animation: slidethrough 7s infinite;
        background-image: url(/globalassets/home/us/common/attachment-block-slider.png);
        background-size: contain;
        content: " ";
        position: absolute;
        width: 10%;
        height: 100%;
        z-index: 10;
        background-repeat: no-repeat;
        left: -10%;
    }

    @keyframes slidethrough {
        0%,20.01% {
            left: -30%;
        }

        20% {
            left: 110%;
        }
    }
}
@media (max-width: 1399px){
    .attachment-block {
        &:before {
            background-image: url(/globalassets/home/us/common/attachment-block-slider-tablet.png);
            transform: rotate( 28deg );
            height: 150%;
            top: -25px;
        }
    }
}
@media (max-width: 991px){
    .attachment-block {
        &:before {
            left:-15%;
        }
    }
}
@media (max-width: 767px) {
    .attachment-block {
        &:before {
            background-image: url(/globalassets/home/us/common/attachment-block-slider-mobile.png);
            height: 200%;
            left: -40%;
            width: 20%;
        }
    }
}
@media (max-width: 425px) {
    .attachment-block {
        &:before {
            height: 200%;
            left: -40%;
            width: 25%;
        }
    }
}