.sticky-premium-content-form {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 0 !important;
  width: 100%;
  z-index: 2;

  &.text-white {
    color: $white;
  }

  button.close-icon {
      left: 8px;
      right: auto;
  }

  .h3 {
      font-size: 18.4px;
      line-height: 1.2;

      p {
          margin-bottom: 8px;

          &.sub-title {
              font-size: 16px;
              margin-bottom: 0;
          }
      }
  }

  .premium-flex-container,
  form .Form__MainBody section > * {
      width: 100%;
  }

  form .Form__MainBody {
      section {
          display: flex;
    
          > * {
              max-width: unset;
              min-width: unset;
          }
    
          .premium-content-button {
              @include media-breakpoint-up(md) {
                  padding-right: 0;
              }
          }
    
          .FormStep__Title {
              font-size: 24px;
              line-height: 24px;

              @include media-breakpoint-down(md) {
                  font-size: 19.2px;
                  line-height: 19.2px;
              }
          }
    
          .FormStep__Title,
          .FormStep__Description,
          .thank-you__title,
          .thank-you__subtitle {
              text-align: center;
              font-family: "Helvetica-Light" !important;
              font-weight: normal;
          }
    
          .thank-you__title {
              font-size: 24px;
              line-height: 24px;
              margin-bottom: 8px;

              @include media-breakpoint-down(md) {
                  font-size: 19.2px;
                  line-height: 19.2px;
              }
          }

          .thank-you__subtitle {
              font-size: 16px;
              line-height: 16px;

              @include media-breakpoint-down(md) {
                  font-size: 13px;
                  line-height: 13px;
              }
          }
    
          .Form__Element__Caption {
              display: none;
          }

          .FormTextbox {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
          }

          .FormTextbox__Input {
              position: relative;
              width: 100%;
              max-width: 300px;
              margin: 0 auto 20px;
              width: 100%;
              padding: 15px;
              border-radius: 8px;
              font-size: 16px;
              outline: none;
              height: 40px;
              line-height: 38px;
              transition: border-color 0.3s ease;
              overflow: hidden;
              padding: 0 20px 0;
              text-transform: uppercase;
              font-family: Verdana;

              + .Form__Element__ValidationError {
                  margin-top: -16px;
                  font-size: 12px;
              }
          }
    
          .FormTextbox--Textarea {
              position: relative;
              width: 100%;
              max-width: 300px;
              margin: 0 auto 20px;
    
              .Form__Element__Caption {
                  display: none;
              }
    
              textarea {
                  width: 100%;
                  padding: 15px;
                  border-radius: 8px;
                  font-size: 16px;
                  outline: none;
                  height: 40px;
                  line-height: 38px;
                  transition: border-color 0.3s ease;
                  resize: none;
                  overflow: hidden;
                  padding: 0 20px 0;
                  text-transform: uppercase;
                  font-family: Verdana;
                  white-space: pre;
              }
              
              .Form_Element__ValidationError {
                  display: none;
              }
              
              textarea:invalid + .Form_Element__ValidationError {
                  display: block;
                  color: $red;
                  font-size: 12px;
                  margin-top: 5px;
              }
          }
    
          .FormSubmitButton {
              height: 40px;
              background-color: $dark-blue;
              color: $white;
              width: 200px;
              border-radius: 8px;
              font-size: 16px;
              transform: translateX(-50%);
              left: 50%;
              position: relative;
              text-transform: uppercase;
              font-family: Verdana;
          }
      }
  }

  @include media-breakpoint-up(xl) {
      .subscribe-block__content-bg-inner:after {
          background-size: cover !important;
      }
  }

  @include media-breakpoint-down(lg) {
      .flex-parent,
      form .Form__MainBody section {
          flex-wrap: wrap;
      }

      .subscribe-block__disclaimer {
          text-align: left !important;
          padding-left: 16px;
      }

      form .Form__MainBody section > * {
          width: 50%;

          @include media-breakpoint-down(md) {
              width: 100%;
          }
      }
  }
}

.sticky-premium-content-form.text-white {
  button.close-icon svg,
  a {
      color: $white;
  }
}

body {
  &.modal-open .sticky-premium-content-form:not(.premium-content-modal) {
      display: none;
  }

  &.etf-fund-page .sticky-premium-content-form .subscribe-block__content .btn {
      width: 100%;
  }
}

.sticky-premium-content-form {
  display: none;

  &.show {
      display: block;
  }
}


.sticky-premium-content-form.premium-content-modal {
  //center on the screen
  position: fixed;
  top: 50%;
  bottom: initial;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 360px;
  max-width: 360px;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  z-index: 1100;
  background: linear-gradient(310deg, $purple 0%, $dark-blue 100%);
  background-repeat: no-repeat;
  background-size: 130% 100% !important;

  .subscribe-block__content .row div:nth-child(2) {
      width: unset;
  }

  button.close-icon {
      left: auto;
      right: 8px;
      font-size: 16px;

      &:hover svg {
          stroke: $white;
          stroke-width: 2px;
      }
  }

  &.anchored-bottom-left {
      top: initial;
      bottom: 0;
      left: 0;
      transform: translate(0, 0);

      button.close-icon {
          right: 8px;
          left: auto;
      }
  }

  form .Form__MainBody section {
      display: block;

      > * {
          @include media-breakpoint-down(lg) {
              width: 100%
          }
      }

      &.Form__Element > div {
          padding: 0;
          width: 100%;
          margin-bottom: 8px;
      }

      .premium-content-button {
          margin-bottom: 16px;
      }
  }

  form .FormChoice {
      margin-top: 16px;

      > fieldset {
          display: flex;
          justify-content: center;
          column-gap: 10px;

          @include media-breakpoint-down(md) {
              column-gap: 6px;
          }

          > div {
              border-radius: 8px;
              display: flex;
              justify-content: center;
          }
      }

      .FormChoice__Input {
          display: none;
      }
      
      .FormChoice__Label {
          display: inline-block;
          width: 40px;
          height: 40px;
          background-color: white;
          color: $gray-5;
          text-align: center;
          line-height: 40px;
          font-size: 18px;
          margin-right: 0;
          margin-bottom: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: background-color 0.3s, color 0.3s;
          font-family: Verdana;
          font-weight: 300;

          @include media-breakpoint-down(md) {
              width: 32px;
              height: 32px;
              line-height: 32px;
          }

          &:hover {
              background-color: $dark-blue;
              color: $white;
          }
      }
      
      .FormChoice__Input--Radio:checked + .FormChoice__Label {
          background-color: $dark-blue;
          color: $white;
      }
  }

  .green-button .premium-content-button button {
      background-color: $green;
      border-color: $green;
      color: $white;
  }

  &.large-body-font {
      .premium-content-form-body {
          font-size: 28px;
          line-height: 1.2;
          margin-top: 12px;
          font-weight: bold;
      }
  }

  .subscribe-block__disclaimer {
      @include media-breakpoint-up(lg) {
          padding: 0;
          text-align: left !important;
      }
  }

  &.webinar_registration_popup {
      form .Form__MainBody section {
          display: block !important;
      }

      .subscribe-block__content-bg {
          display: none;
      }
  }

  &.survey_form {
      .subscribe-block__content-bg {
          display: none;
      }

      .Form__NavigationBar {
          display: none !important;
      }
      
      .h3 {
          font-size: 30px;
          margin-bottom: 16px;
      }

      .Form__Element__ValidationError {
          color: red;
          font-size: 12px;
      }

      .premium-content-button {
          display: flex;
          justify-content: center;

          button {
              width: 180px;
          }
      }

      form .Form__MainBody section {
          display: flex;
          flex-direction: column;

          &.hide {
              display: none !important;
          }
      }

      .Form__MainBody {
          padding: 2.5rem 0;
      }

      &.initial-step-submitted {
        .Form__MainBody {
            padding: 0;
        }
    
        .FormSubmitButton {
            margin: 0;
        }
      }
      
      .Form__Status__Message.Form__Success__Message,
      .success-message {
          padding: 0 !important;
          
          text-align: center;
          font-size: 19.2px;
      }
      
      .Form__Status .Form__Status__Message {
          padding-bottom: 15px !important;
          
          &.hide {
              display: none;
          }
      }
      
      .success-message {
          margin-bottom: 0;
      }

      &.large-modal {
          width: 100%;
          max-width: 576px;
    
          @media (max-width:576px) {
              max-width: 90vw;
          }
      }
  }

  &.insto form {
      .FormChoice .FormChoice__Label {
          width: 60px;
      }

      .Form__MainBody section .FormTextbox--Textarea {
          margin-top: 16px;       
      }
  }
}

.modal-backdrop.sticky-premium-form-backdrop {
  opacity: 0.8
}

body.modal-open {
  .modal-backdrop.sticky-premium-form-backdrop {
      display: none;
  }

  .sticky-premium-content-form.premium-content-modal {
      z-index: 5;
  }
}

.gated-content-block {
  display: none;

  &.unlocked {
      display: block;
  }
}