@import '../style/common';

.podcast-block {
  $podcast-block-switch-breakpoint: lg;

  border-top: $border-style;
  border-bottom: $border-style;
  padding: space(4) space(3);

  @include media-breakpoint-up($podcast-block-switch-breakpoint) {
    padding: space(5);
  }

  &__row {
    @include make-row;
  }

  &__main-column,
  &__cta-column {
    @include make-col-ready;
  }

  &__main-column {
    @include media-breakpoint-up($podcast-block-switch-breakpoint) {
      @include make-col(8);
      padding-right: space(5);
    }
  }

  &__cta-column {
    margin-top: space(4);
    position: relative;

    @include media-breakpoint-up($podcast-block-switch-breakpoint) {
      @include make-col(4);
      margin-top: 0;
      padding-left: space(5);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: $border-width * 2;
        background-color: $border-color;
      }
    }
  }

  &__date {
    font-weight: $text-util-font-weight;
    text-transform: uppercase;
    font-size: $text-util-size-sm;
    letter-spacing: $text-util-sm-letter-spacing;
    margin-bottom: space(3);
  }

  &__title {
    @include make-heading(3);
    margin-bottom: $headings-margin-bottom;
  }

  &__description {
    margin-bottom: space(3);
  }

  &__contributor-list,
  &__cta-list {
    @include list-unstyled;
    margin-bottom: 0;
  }

  &__contributor-list {
    display: inline-block;
    margin-bottom: space(4);
  }

  &__contributor-item {
    display: inline-block;
    margin-right: space(1);
  }

  &__cta-list {
    margin-bottom: 0;
  }

  &__cta-item {
    display: inline-block;
    margin-right: space(4);

    @include media-breakpoint-up($podcast-block-switch-breakpoint) {
      display: block;
    }
  }

  &__cta-item ~ &__cta-item {
    margin-top: space(4);
  }
}
