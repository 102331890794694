﻿
/****
    SECONDARY NAV
    - These are used on the investment professionals page and the client services (contact us pages)
****/

//Secondary NAV -- offsets for the sticky main nav + hide it unless it is sticky.
.secondary-nav {
    visibility: hidden;
    height: auto;
    background-color: #001e6d;

    ul {
        display: none;
    }

    &.sticky {
        visibility: visible;
        position: fixed;
        top: 95px;
        left: 0;
        right: 0;
        z-index: 1020;
    }
}

@media all and (min-width: 992px) {
    .secondary-nav.sticky ul {
        display: flex;
    }
}

#sticky-waypoint.loaded:not(.sticky) {
    display: none;
}


/* Offsets for the individual items to be navigated to. 
Negative margins use to push down text below the sticky nav, browsers default anchor behaviour is to have it displayed under the main nav. */

.investments-professionals-page .ip-item-section {
    > h2 {
        padding-top: 170px !important;
    }

    margin-top: -140px !important;
}
.investments-professionals-page #commodities {
    > h2 {
        padding-top: 190px !important;
    }

    margin-top: -160px !important;
}

.client-services-page {
    .ip-item-section > h2, .ip-item-selection > h2 {
        padding-top: 190px !important;
    }

    .ip-item-section, .ip-item-selection {
        margin-top: -140px !important;
    }
}

@media (max-width: 768px) {
    .investments-professionals-page .ip-item-section > h2 {
        padding-top: 200px !important;
    }

    .client-services-page {
        .ip-item-section > h2, .ip-item-selection > h2 {
            padding-top: 200px !important;
        }
    }

    .investments-professionals-page .ip-item-section, .client-services-page .ip-item-section {
        margin-top: -200px !important;
    }
}