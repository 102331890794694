@import '../style/common';

.link-farm {

  &__list-title {
    margin-bottom: space(4);
  }

  &__link {
    display: block;
    margin-bottom: space(3);
  }

  &__outer-container {
    margin-bottom: space(4);

    &:not(:nth-of-type(3n)):not(:last-child) {
      .link-farm__inner-container {
        @include media-breakpoint-up(lg) {
          border-right: $border-style;
        }
      }
    }
  }

  &__inner-container {
    padding-right: space(3);
    height: 100%;
  }
}
