@import '../style/common';

.bundled-block {
  @include media-breakpoint-down(sm) {
    .divider-bottom {
      border-bottom: $border-width solid $gray-2;
      margin-bottom: space(2);
    }
  }

  &__inflation {
    height: auto;
    margin-bottom: space(4);

    .grid-card {
      &__title-link {
        @include link-focus-outline;

        h3 {
          font-size: $h2-font-size;
        }
      }
    }

    @include media-breakpoint-only(md) {
      .grid-card__divider {
        display: none;
      }

      &-small {
        margin-top: space(3);

        .grid-card {
          padding-top: space(4);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;

      .grid-card__header {
        margin-bottom: 0;
      }

      .grid-card__divider {
        display: none;
      }

      .grid-card__title-link {
        margin-bottom: space(2);
      }

      &-small .small-card {
        border-bottom: $border-width solid $gray-2;
        width: 50%;
        margin-bottom: space(3);

        &:nth-child(n + 3) {
          width: 100%;
        }

        .grid-card__divider {
          display: none;
        }

        .grid-card__header {
          margin-bottom: space(2);
        }
      }
    }
  }
}
