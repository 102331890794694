﻿/** {
    padding: 0;
    margin: 0;
    border: 0;
}

html, body {
    padding: 0;
    margin: 0;
    background: #ffffff;
    font-size: 10px;
}*/

.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}


table {
    border-collapse: collapse;
}

a {
    text-decoration: none;
    outline: none;
}

a:hover {
    text-decoration: underline;
}

ul {
    list-style-type: none;
}

.wrapper {
    width: 971px;
    margin: 0 auto;
    max-width: 100%;
}

.wrapper.home {
    width: 100%;
}

header {
}

header .logo {
    background: rgba(0, 0, 0, 0) url("/globalassets/home.au/media/libraryitems/images/icon-and-images/icon_globe.png") no-repeat scroll 0 0;
    display: block;
    width: 262px;
    height: 94px;
    border: 0;
    margin: 20px 0 0px 0;
    float: left;
}

header .head_right {
    float: right;
    margin: 15px 0 0px 0;
    position: relative;
    *width: 300px;
}

.head_right #contact_us {
    list-style-type: none;
    text-align: right;
}

.contactList li,
#contact_us li {
    margin: 0;
    display: inline;
    font-size: 11px;
}

.contactList li a,
#contact_us li a {
    text-decoration: none;
    color: #003967;
}

.head_right,
.search_header {
    margin: 4px 0 0 0;
    float: right;
}

.search_header {
    width: 100%;
    float: right !important;
}

.search_header input {
    width: 199px !important;
    float: left;
}

.search_header .button_search {
    float: left;
    display: block;
    color: #fff;
    font-size: 11px;
    background: url(/Static/au/images/etf/btn_search.png) no-repeat;
    text-decoration: none;
    width: 56px;
    height: 25px;
    line-height: 25px;
    margin-left: 2px;
    padding-left: 14px;
}

.head_right .icon {
    width: 17px;
    height: 16px;
    display: block;
    position: absolute;
    top: 63px;
}

.head_right .icon.youtube {
    background: url(/Static/au/images/etf/icons.png) 0 0 no-repeat;
    right: 25px;
}

.head_right .icon.twitter {
    background: url(/Static/au/images/etf/icons.png) -24px 0 no-repeat;
    right: 0;
}

nav.top {
    position: unset;
    margin-top: 12px;
    z-index: 6;
}

nav.top .drop_tab {
    width: 268px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-bottom: 1px solid #E3E7ED;
    color: #fff;
    font-size: 14px;
    display: block;
    float: left;
    text-decoration: none;
}

nav.top .drop_tab.green {
    background: url(/Static/au/images/etf/green.png) repeat-x;
}

nav.top .drop_tab.blue {
    background: #0033A0;
}

.shadow {
    background: url(/Static/au/images/etf/shadow.png) repeat-x;
    border-bottom: 1px solid #E3E7ED;
    float: left;
    height: 38px;
    position: relative;
    top: 7px;
    width: 4px;
}

.shadow.foot {
    height: 45px;
    top: -1px;
}


/*nav.top .nav_main {
    float: left;
    list-style-type: none;
    height: 39px;
    background: #EEF1F4;
    margin: 7px 0 0 0;
    line-height: 42px;*/
    /*
    width: 589px;
    */
/*}*/

.nav_main li {
    display: inline;
    font-size: 14px;
    margin-left: 40px;
    line-height: 42px;
}

.nav_main li a {
    color: #333333;
    text-decoration: none;
    line-height: 42px;
}


.drop {
    font-family: Arial, sans-serif;
    position: absolute;
    position: -ms-page;
    width: 970px;
    top: 114px;
    left: 0;
    z-index: 9999;
    border: 1px solid #000;
    border-top: none;
    display: none;
}

.drop.selected {
    display: block;
    z-index: 9999;
}

.jqTransformSelectWrapper {
    z-index: 1 !important;
}

.drop .drop_contetn {
    border: 5px solid #0033A0;
    background: #fff;
    padding: 10px;
}

.drop_contetn a {
    color: #191919;
}

.drop_contetn a:hover {
    color: #000000;
}

.drop_contetn .part {
    float: left;
}

.drop_contetn .part .for_lists {
    width: 280px;
}

.part h3.title_part {
    color: #333333;
    font-size: 12px;
    font-weight: bold;
    border-bottom: 1px solid #333333;
    padding-bottom: 3px;
    margin-bottom: 7px;
    margin-right: 10px;
}

h3.title_part span {
    color: #84A62D;
}

.part .for_lists.last {
    padding: 0;
}

.lists {
    list-style-type: none;
    margin-bottom: 10px;
    padding-left: 0;
}

.drop_contetn .part {
    width: 580px;
}

.drop_contetn .part ul.lists {
    width: 290px;
    float: left;
}

.drop_contetn .part .for_lists {
    width: 290px;
    float: left;
}

.drop_contetn .part.second {
    padding-left: 0px;
    float: left;
}

.drop_contetn .part.second {
    margin-left: 10px;
    width: 340px;
}

.drop_contetn .part.second ul.lists {
    width: 350px;
}

.part.second li.title {
    width: 330px;
}

.drop_contetn .part.second .for_lists.last .lists span {
    width: 420px;
}

.drop_contetn .part.second .for_lists.last .lists span.short {
    width: 25px;
}

.lists li {
    height: 19px;
    line-height: 19px;
    font-size: 11px;
    color: #191919;
    padding: 0 7px;
    background: #fff;
    margin-right: 10px;
}

.lists li span {
    float: left;
    width: 240px;
}

.lists li span:hover {
    text-decoration: underline;
}


.lists li span.short {
    width: 25px;
}


.lists li.title {
    color: #fff;
    border-bottom: 1px solid #000;
    height: 21px;
    line-height: 21px;
    font-weight: bold;
}

#slides a.prev, #slides a.next, ul.pagination li a, #slides ul.pagination {
    z-index: 1 !important;
}

.lists.vinous li.title {
    background: #B42F30;
}

.lists.vinous li.colored {
    background: #F8EAEA;
}

.lists.orandge li.title {
    background: #DC6D1B;
}

.lists.orandge li.colored {
    background: #F9F0E9;
}


.lists.yellow li.title {
    background: #EEC40E;
}

.lists.yellow li.colored {
    background: #F9F7EA;
}


.lists.lightGreen li.title {
    background: #B6B330;
}

.lists.lightGreen li.colored {
    background: #F5F6E6;
}

.lists.green li.title {
    background: #30B272;
}

.lists.green li.colored {
    background: #D6F0E3;
}

.lists.darkBlue li.title {
    background: #3172B6;
}

.lists.darkBlue li.colored {
    background: #E0EAF4;
}

.lists.blue li.title {
    background: #2DB5B5;
}

.lists.blue li.colored {
    background: #E0F4F3;
}

.lists.blue li {
    height: auto;
    line-height: 18px;
}

.lists.violet li.title {
    background: #674792;
}

.lists.violet li.colored {
    background: #EFECF3;
}

.lists.grey li.title {
    background: #4D4D4D;
}

.lists.grey li.colored {
    background: #d1ba7a;
}

.lists.pistachio li.title {
    background: #d1ba7a;
}

.lists.pistachio li.colored {
    background: #f3eee1;
}

.lists.shaded-gray li.title {
    background: #9a9a9a;
}

.lists.shaded-gray li.colored {
    background: #e5e5e5;
}



.drop_contetn .empty_block {
    border-top: 1px solid #000;
    padding: 10px 0 0 0;
    margin-top: 16px;
    *margin-top: 0;
}

.empty_block .empty_block_first {
    float: left;
    width: 187px;
    height: 48px;
    border: 1px solid #84A72E;
    margin-right: 11px;
}

.empty_block .empty_block_second {
    float: left;
    width: 737px;
    height: 50px;
    border: 1px solid #84A72E;
}

.shadow_under_drop {
    position: absolute;
    left: 0;
    bottom: -33px;
    height: 33px;
    width: 493px;
    background: url(/Static/au/images/etf/shadow_under_drop.png) repeat-x;
}


.line_top {
    background: #0033A0;
    height: 3px;
    border: 1px solid #333333;
    border-bottom: none;
}


.location {
    list-style-type: none;
    padding: 2px 0 0px 12px;
    height: 23px;
}

.breadcrumbNew span {
    padding: 0;
}

.location li {
    display: inline;
    font-size: 10px;
    font-family: Arial, sans-serif;
    color: #4C4C4C;
}

.location li a {
    color: #003967;
    text-decoration: none;
}

section.main {
    border-top: none;
    border-bottom: none;
    padding: 0px 15px 30px 11px;
    background: #fff;
}

.main .left_col {
    float: left;
    margin: 91px 24px 0 0;
    width: 230px;
}

.left_col .block {
    width: 230px;
}

.left_col .block.media {
    /*margin-bottom: 10px; */
}

.left_col .block.funds {
    /*margin-bottom: 4px; */
}

.block .picture {
    padding: 0;
    border-top: 1px solid #1A1A1A;
    min-height: 252px;
}

.block .picture img {
    width: 230px;
}

.more_icon {
    margin-top: 1px;
    background: url(/Static/au/images/etf/bk_for_icon.png) repeat-x;
    height: 23px;
    padding: 6px 0 0 10px;
}

.more_icon.no_backg {
    background: none;
    padding: 0;
}

.more_icon .icon2 {
    width: 17px;
    height: 16px;
    display: block;
    float: left;
    margin-right: 20px;
}

.more_icon .icon2.in {
    background: url(/Static/au/images/etf/icons2.png) 0 0 no-repeat;
}

.more_icon .icon2.facebook {
    background: url(/Static/au/images/etf/icons2.png) -37px 0 no-repeat;
}

.more_icon .icon2.youtube {
    background: url(/Static/au/images/etf/icons.png) 0 0 no-repeat;
}

.more_icon .icon2.twitter {
    background: url(/Static/au/images/etf/icons.png) -24px 0 no-repeat;
}


.block h3,
.block h4 {
    border-bottom: 1px solid #1A1A1A;
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #4C4C4C;
}


.block h3 span,
.block h4 span {
    color: #143766;
    font-size: 9px;
    font-family: Verdana;
    font-weight: normal;
}

h3.financial {
    font-size: 15px;
    padding-bottom: 3px;
}

.block .light_blue,
.block .videoViewpoint {
    background: #ECF0F4;
    padding: 11px 7px 7px 8px;
    color: #666666;
    font-size: 10px;
}

.light_blue h6 {
    font-size: 9px;
    font-weight: normal;
    color: #949494;
    border-bottom: 1px solid #959595;
    padding-bottom: 2px;
}

.light_blue a {
    color: #006bb4;
}

.with_border_dashed {
    border-bottom: 1px dashed #959595;
    padding-bottom: 12px;
}

.with_border_dashed.none {
    border: none;
}

.light_blue .text_news {
    padding: 0 0 0 0px;
    margin-top: 10px;
    line-height: 16px;
}


.text_news a {
    color: #666666;
    text-decoration: none;
}

.text_news a:hover {
    text-decoration: underline;
}

.light_blue .text_news.income {
    padding: 0 5px 0px 0px;
    line-height: 15px;
    margin-top: 16px;
}


.text_news span.question {
    color: #333333;
    font-size: 11px;
}

.text_news .img_in_text_news {
    float: left;
    margin-right: 7px;
}

.description_text {
    margin: 0;
    line-height: 15px;
}

.light_blue .description_text.recent {
    padding: 0 0 0 22px;
}


.light_blue .names {
    padding: 4px 0px;
}

.names a {
    text-decoration: none;
    color: #006bb4; /* override with blue color. color: #333333;*/
}

.names a:hover {
    text-decoration: underline;
}

.names .a_name {
    color: #FFFFFF;
    display: inline-block;
    float: left;
    font-size: 11px;
    font-weight: bold;
    height: 17px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    width: 50px;
}

.names .a_name:hover {
    text-decoration: none;
}

.names .a_name.vinous, .colored-ticker.vinous {
    background: url(/Static/au/images/etf/small_ticker.png) 0 0 no-repeat;
}

.names .a_name.orandge, .colored-ticker.orandge {
    background: url(/Static/au/images/etf/small_ticker.png) 0 -18px no-repeat;
}

.names .a_name.yellow, .colored-ticker.yellow {
    background: url(/Static/au/images/etf/small_ticker.png) 0 -36px no-repeat;
}

.names .a_name.lightGreen, .colored-ticker.lightGreen {
    background: url(/Static/au/images/etf/small_ticker.png) 0 -54px no-repeat;
}

.names .a_name.green, .colored-ticker.green {
    background: url(/Static/au/images/etf/small_ticker.png) 0 -72px no-repeat;
}

.names .a_name.darkBlue, .colored-ticker.darkBlue {
    background: url(/Static/au/images/etf/small_ticker.png) 0 -90px no-repeat;
}

.names .a_name.blue, .colored-ticker.blue {
    background: url(/Static/au/images/etf/small_ticker.png) 0 -108px no-repeat;
}

.names .a_name.violet, .colored-ticker.violet {
    background: url(/Static/au/images/etf/small_ticker.png) 0px -144px no-repeat;
}

.names .a_name.grey, .colored-ticker.grey {
    background: url(/Static/au/images/etf/small_ticker.png) 0px -126px no-repeat;
}

.names .a_name.pistachio, .colored-ticker.pistachio {
    background: url(/Static/au/images/etf/small_ticker.png) 0px -308px no-repeat;
}

.names .a_name.shaded-gray, .colored-ticker.shaded-gray {
    background: url(/Static/au/images/etf/small_ticker.png) 0px -360px no-repeat;
}

.names p {
    color: #191919;
    font-size: 11px;
    padding-bottom: 10px;
    margin: 1px 0 0 60px;
    line-height: 16px;
}

.funds .separator {
    border-bottom: 1px dashed #959595;
    margin-bottom: 10px;
    margin-left: 57px;
}

.contact_home p {
    margin-bottom: 5px;
}

.leftMenu {
    margin: 0px;
    background-color: #ECF0F4;
    background-image: none;
    color: #666666;
    font-size: 10px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 17px;
    width: 228px;
}

h3.register {
    width: 228px;
    background-color: #FFFFFF;
    border-bottom-color: #000000;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #4C4C4C;
    font-family: Arial,sans-serif;
    font-size: 15px;
    padding: 10px 0 2px 0px;
    text-transform: none;
}

.register_etf a, .register_etf a:visited, .forgotpassword_etf a, .forgotpassword_etf a:visited {
    color: #003A69 !important;
    font-size: 10px !important;
    font-weight: normal;
    text-decoration: none;
}

.membership_login {
    margin: 8px 0 8px 0;
}

.membership_login_cdn {
    margin: 5px 0 15px 0;
    font-family: Arial;
    color: #666666;
}

.username, .password {
    border: 1px solid #A2A2A2;
    height: 20px;
    margin: 0 0 10px 0px;
    padding: 0px;
    width: 205px !important;
}

.loginButton {
    background-image: url("/Static/au/images/etf/btn_log_in.png") !important;
    border: 1px solid #333333;
    color: #FFFFFF;
    float: right;
    font-family: Verdana;
    font-size: 10px;
    font-weight: normal;
    height: 25px;
    margin: 0px;
    padding: 0px;
    position: relative;
    text-align: center;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
    text-transform: capitalize;
    top: -26px;
    width: 70px;
    left: 0px;
    border-radius: 4px;
}

.registerButtonCdn {
    background: #0033A0 none repeat scroll 0 0;
    border: 1px solid #666666;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: Verdana;
    font-size: 10px;
    font-weight: normal;
    height: 18px;
    margin: 0;
    padding: 4px 0 0;
    text-align: center;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
    text-transform: capitalize;
    width: 81px;
    margin-bottom: 8px;
}

.registerButtonCdn a {
    color: #fff !important;
}

.registerButtonCdn a:link, .registerButtonCdn a:visited {
    line-height: 13px !important;
}

a.log_in, button.log_in {
    background: url("/Static/au/images/etf/btn_log_in.png") no-repeat scroll 0 0 transparent;
    color: #FFFFFF;
    cursor: pointer;
    float: left;
    font-family: Verdana,sans-serif;
    font-size: 11px;
    height: 27px;
    line-height: 27px;
    margin: 7px 0 0 39px;
    text-decoration: none;
    width: 87px;
}




.right_col {
    margin: 5px 0 0 0px;
    float: left;
    width: 688px;
    font-family: Arial, sans-serif;
}

.right_col .right_block,
.right_col .etf_hyothetical_growth {
    margin-bottom: 25px;
    position: relative;
    font-family: Arial, sans-serif;
    float: left;
    width: 688px;
}

.etf_hyothetical_growth {
    margin-bottom: 25px;
    position: relative;
    font-family: Arial, sans-serif;
}

.right_col .right_block.widget_fix {
    margin: 0;
}

.right_col .right_block.top {
    margin-top: 0;
}

.right_block h1, .right_block h2 {
    font-size: 25px;
    font-weight: bold;
    color: #0033A0;
    border-bottom: 1px solid #1A1A1A;
    padding: 0 0 3px 2px;
}

.right_col .top_block {
    margin: 0 0 20px 0;
}

a.ticker {
    color: #FFFFFF;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    height: 30px;
    left: 7px;
    line-height: 30px;
    position: relative;
    text-align: center;
    text-decoration: none;
    top: -2px;
    width: 87px;
}

a.ticker.vinous {
    background: url(/Static/au/images/etf/big_ticker.png) 0 0 no-repeat;
}

a.ticker.orandge {
    background: url(/Static/au/images/etf/big_ticker.png) 0 -31px no-repeat;
}

a.ticker.yellow {
    background: url(/Static/au/images/etf/big_ticker.png) 0 -62px no-repeat;
}

a.ticker.lightGreen {
    background: url(/Static/au/images/etf/big_ticker.png) 0 -93px no-repeat;
}

a.ticker.green {
    background: url(/Static/au/images/etf/big_ticker.png) 0 -124px no-repeat;
}

a.ticker.darkBlue {
    background: url(/Static/au/images/etf/big_ticker.png) 0 -155px no-repeat;
}

a.ticker.blue {
    background: url(/Static/au/images/etf/big_ticker.png) 0 -186px no-repeat;
}

a.ticker.violet {
    background: url(/Static/au/images/etf/big_ticker.png) 0px -248px no-repeat;
}

a.ticker.grey {
    background: url(/Static/au/images/etf/big_ticker.png) 0px -217px no-repeat;
}

a.ticker.pistachio {
    background: url(/Static/au/images/etf/big_ticker.png) 0px -522px no-repeat;
}

a.ticker.shaded-gray {
    background: url(/Static/au/images/etf/big_ticker.png) 0px -615px no-repeat;
}

a.icon_print {
    position: absolute;
    top: 12px;
    right: 2px;
    background: url(/Static/images/etf/icon_print.png) no-repeat;
    width: 18px;
    height: 15px;
    display: block;
}

.right_block .mini_block {
    float: left;
    margin-top: 10px;
    width: 218px;
}

.mini_block h5 {
    font-size: 10px;
    color: #191919;
    border-bottom: 1px solid #949494;
    padding: 0 0 5px 0;
}

.mini_block .text_mini_block, .block .text_mini_block, .block .text_mini_block {
    font-size: 12px;
    color: #191919;
    padding: 4px 0 0 0;
    line-height: 17px;
}

.text_mini_block a {
    color: #006bb4;
    text-decoration: none;
}

.right_block .mini_block.second {
    margin: 10px 0 0 16px;
    width: 444px;
}


.mini_block ul.details {
    color: #191919;
    font-size: 12px;
    list-style-type: none;
    width: 213px;
    float: left;
}

ul.details a {
    color: #006bb4; /* #18467C; - changed link color for Documents and download*/
    text-decoration: none;
}

ul.details a:hover, a.download:hover, a.view:hover, .location li a:hover {
    text-decoration: underline;
}

.mini_block ul.details .bt_fund_fact {
    background: url(/Static/au/images/etf/icon_acrob_ex.png) 0 0 no-repeat;
    color: #191919;
    display: inline-block;
    line-height: 18px;
    padding-left: 21px;
}

.mini_block ul.details.next {
    margin: 0 0 0 17px;
}

ul.details li {
    padding: 4px 8px 4px 7px;
    overflow: hidden;
    font-size: 12px;
}

ul.details li.pleft15 {
    padding-left: 15px;
}

ul.details .download {
    color: #153767;
    display: inline-block;
}

ul.details .download.pdf {
    background: url(/Static/au/images/etf/icon_acrob_ex.png) 0 0 no-repeat;
    padding-left: 19px;
    line-height: 18px;
}

ul.details .download.xml {
    background: url(/Static/au/images/etf/icon_ex.png) 0 0 no-repeat;
    padding-left: 19px;
    line-height: 18px;
}

ul.details li.grey {
    background: #E0E0E0;
}

ul.details li span {
    float: right;
    text-align: right;
}

.two_cols.two_cols_left_item ul.details li span {
    margin-left: 20px;
}

.two_cols.two_cols_left_item p.yearstomaturity {
    padding-top: 10px;
}

.two_cols.two_cols_left_item .line_chart_container_pie {
    height: 300px;
    margin-top: 15px;
}

ul.details li p {
    float: left;
    max-width: 67%;
}

.two_cols_right_item ul.details li p {
    max-width: 158px;
}

ul.details li.tit {
    border-bottom: 1px solid #AFAFAF;
}

ul.details li.total {
    background: #7d868c;
    font-weight: bold;
    border-bottom: 1px solid #AAAE9F;
    border-top: 1px solid #AAAE9F;
}

ul.details li .pdf {
    background: url(/Static/au/images/etf/icon_acrob_ex.png) 0 0 no-repeat;
    display: block;
    line-height: 18px;
    padding-left: 18px;
}

ul.details li .xml {
    background: url(/Static/au/images/etf/icon_ex.png) 0 0 no-repeat;
    display: inline-block;
    line-height: 18px;
    padding-left: 25px;
    min-width: 200px !important;
}

ul.details li .txt {
    background: url(/Static/au/images/etf/icon_txt_ex.png) 0 0 no-repeat;
    display: inline-block;
    line-height: 18px;
    padding-left: 25px;
}

.right_col nav.in_right {
    margin: 5px 0 0;
    float: left;
    width: 100%;
    font-family: Arial;
}

nav.in_right ul {
    list-style-type: none;
    min-width: 688px;
    background: url(/Static/au/images/etf/bk_for_icon.png) repeat-x;
}

nav.in_right ul li {
    display: inline;
    float: left;
    font-size: 12px;
    height: 29px;
    line-height: 29px;
    position: relative;
}

nav.in_right ul li:hover {
    background: #0033A0 !important;
}

nav.in_right ul li:last-child {
    background: url(/Static/au/images/etf/bk_for_icon.png) repeat-x;
}

nav.in_right ul li:last-child a:hover {
    background: #0033A0;
}

nav.in_right ul li a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    letter-spacing: 0px;
    height: 29px;
    border-left: 1px solid #fff;
}

nav.in_right ul li:hover a {
    color: #fff;
}

nav.in_right ul li.active {
    /* border-left: 1px solid #fff;
    border-right: 1px solid #fff;*/
    background: #0033A0;
}

nav.in_right ul li.active a {
    margin: 0;
    color: #fff;
    *height: 29px;
}


li.pass .ar_green {
    display: none;
}

li.active .ar_green, li:hover .ar_green {
    background: url(/Static/au/images/global_mf/fundnav_marker.gif) no-repeat;
    display: block;
    width: 18px;
    height: 8px;
    position: absolute;
    top: 29px;
    left: 43%;
}

.right_col h4, .etf_hyothetical_growth h4 {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #000;
    padding: 0 0 2px 0px;
    font-family: Arial, Sans-Serif !important;
}

.right_col h4 sup {
    font-size: 12px;
}

.dotbody h4 span span,
.right_col h4 span {
    color: #000;
    font-size: 11px;
    font-weight: normal;
}

.right_col h4 .view {
    margin-top: 5px;
    float: right;
    color: #003A68;
    font-size: 11px;
    font-weight: normal;
}

.right_col h4 .download {
    color: #003A68;
    display: inline-block;
    float: right;
    font-size: 11px;
    font-weight: normal;
    line-height: 18px;
    padding-left: 20px;
    margin-top: 5px;
}

.right_col h4 .download.xml {
    background: url(/Static/au/images/etf/icon_ex.png) no-repeat;
    margin-top: 2px;
}

.right_col h4 .download.pdf {
    background: url(/Static/au/images/etf/icon_acrob_ex.png) no-repeat;
    margin-top: 2px;
}

.right_col h4 .view + .view {
    margin-right: 30px;
}

.right_block h4 a {
    color: #006bb4 !important;
}

.right_block .first a {
    color: #006bb4 !important;
}

.right_block table#prices,
.right_block table.prices {
    font-size: 11px;
    color: #000000;
    text-align: center;
    border-collapse: collapse;
    margin-top: -6px;
    width: 100%;
}

#prices tr,
.prices tr {
    height: 54px;
    vertical-align: bottom;
    padding-top: 5px;
}

#prices tr.light_yellow,
.prices tr.light_yellow {
    background: #E8E3DB;
    line-height: 20px;
    vertical-align: middle;
    font-size: 12px;
}

#prices tr.light_yellow td,
.prices tr.light_yellow td {
    border-top: 1px solid #afafaf;
    border-bottom: 1px solid #afafaf;
}

#prices td.first,
.prices td.first,
.prices th.first {
    width: 68px;
    text-align: left;
    padding-left: 6px;
}

#prices td.second,
.prices td.second,
.prices th.second {
    width: 51px;
}

#prices td.third {
    width: 94px;
}

.prices td.third,
.prices th.third {
    width: 100px;
}

#prices td.fourth {
    width: 85px;
}

.prices td.fourth,
.prices th.fourth {
    width: 105px;
}

#prices td.fifth {
    width: 96px;
}

.prices td.fifth,
.prices th.fifth {
    width: 70px;
}

#prices td.sixth {
    width: 83px;
}

.prices td.sixth,
.prices th.sixth {
    width: 70px;
}

#prices td.seventh,
.prices td.seventh,
.prices th.seventh {
    width: 98px;
}

#prices td.eighth {
    width: 41px;
}

.prices td.eighth,
.prices th.eighth {
    width: 61px;
}

#prices td.ninth,
.prices td.ninth,
.prices th.ninth {
    width: 66px;
}

#prices a.icon_docs,
.prices a.icon_docs {
    display: block;
    height: 17px;
    width: 16px;
    margin: 6px auto;
}

#prices a.icon_ex,
.prices a.icon_ex {
    background: url("/Static/au/images/etf/icon_ex.png") no-repeat scroll 0 0 transparent;
}

#prices a.icon_acrob,
.prices a.icon_acrob {
    background: url("/Static/au/images/etf/icon_acrob_ex.png") no-repeat scroll 0 0 transparent;
}

#prices a.icon_graf,
.prices a.icon_graf {
    background: url("/Static/au/images/etf/icon_graf.png") no-repeat scroll 0 0 transparent;
    width: 21px;
}

.description {
    color: #666;
    font-size: 10px;
    margin: 5px 2px;
    clear: both;
}

.description a {
    color: #006bb4;
}

.description p + p {
    margin-top: 5px;
}

.right_col .tabs {
    margin-bottom: 5px;
    height: 32px;
    background: #ececec;
    border-bottom: 1px solid #0033A0;
}

.tabs .all {
    float: right;
    color: #4d4d4d;
    font-size: 12px;
    margin: 11px 10px 0 0;
    background: url(/Static/au/images/etf/icon_ex.png) 0% 50% no-repeat;
    height: 17px;
    padding-left: 20px;
}

ul.tabNavigation {
    list-style-type: none;
    padding: 8px 0 0 7px;
}

.tabNavigation li {
    display: inline;
    float: left;
    height: 25px;
    line-height: 25px;
    *zoom: 1;
}


.tabNavigation li a {
    color: #000;
    font-size: 11px;
    text-decoration: none;
    padding: 0 10px;
    cursor: pointer;
}

.tabNavigation li a span {
    color: #666666;
}

.tabNavigation li a.activeTabs {
    background: #fff;
    height: 26px;
    display: block;
}

ul.tabNavigation li a.selected, ul.tabNavigation li a.selected:hover {
    background: #fff;
    height: 24px;
    line-height: 28px;
    display: block;
    padding: 0 10px;
    border: 1px solid #0033A0;
    border-bottom: none;
}


table.holding {
    width: 100%;
    font-size: 11px;
    color: #000;
    text-align: center;
    border-collapse: collapse;
    margin-top: 10px; /* border-bottom: 1px solid #AFAFAF;*/
}

table.holding .total td {
    font-weight: bold;
    background: #7d868c;
    border-bottom: 1px solid #AFAFAF;
    border-top: 1px solid #AFAFAF;
}

table.holding .total td:first-child {
    text-align: left;
    padding-left: 10px;
}

table.holding tr {
    font-size: 12px;
    text-align: right;
    line-height: 23px;
}

table.holding tr.color {
    background: #E8E3DB;
}

table.holding th, table.holding td {
    padding-right: 3px;
}

table.holding th:first-child, table.holding td:first-child {
    padding-right: 2px;
}

.is-top-ten table.holding tr td:nth-of-type(3):not(.rightAlign), .is-top-ten table.holding tr th:nth-of-type(3):not(.rightAlign) {
    text-align: left !important;
    padding-left: 100px;
}

table.holding div.paddingRight {
    padding-right: 10px;
}

table.holding div.paddingLeft {
    padding-left: 20px;
}

table.holding th:nth-child(6) div, table.holding td:nth-child(6) div {
    padding-left: 0px;
    max-width: 87px;
}

table.holding th:nth-child(7) div, table.holding td:nth-child(7) div {
    padding-right: 0px;
}

table.holding th:nth-child(3) div, table.holding td:nth-child(3) div {
    padding-right: 2px;
}

table.holding th:nth-child(2), table.holding td:nth-child(2) {
    padding-right: 1px;
}

table.holding th:nth-child(5) div, table.holding td:nth-child(5) div {
    padding-left: 3px;
    text-align: center;
}

table.holding tr th.leftAlign, table.holding tr td.leftAlign, table.holding div.leftAlign {
    text-align: left;
}

table.holding tr th.rightAlign, table.holding tr td.rightAlign, table.holding div.rightAlign {
    text-align: right;
}

table.holding th {
    vertical-align: bottom;
    border-bottom: 1px solid #AFAFAF;
    text-align: right;
    line-height: normal;
    font-weight: normal;
}


table.two_cols_table {
    width: 100%;
    font-size: 11px;
    color: #000;
    text-align: center;
    border-collapse: collapse;
}

table.two_cols_table tr {
    font-size: 12px;
    text-align: right;
    line-height: 23px;
}

table.two_cols_table tr.color {
    background: #E8E3DB;
}

table.two_cols_table th, table.two_cols_table td {
    padding: 0 5px 0 5px;
}

table.two_cols_table th {
    vertical-align: bottom;
    border-bottom: 1px solid #AFAFAF;
    text-align: right;
    line-height: normal;
    font-weight: normal;
    padding-bottom: 4px;
    padding-top: 4px;
}

table.two_cols_table th.leftAlign, table.two_cols_table td.leftAlign {
    text-align: left;
}

table.two_cols_table th:first-child, table.two_cols_table td:first-child {
    width: 56%;
}

table.two_cols_table .total td {
    font-weight: bold;
    background: #E2EACD;
    border-bottom: 1px solid #AFAFAF;
    border-top: 1px solid #AFAFAF;
}

table.two_cols_table .total td:first-child {
    text-align: left;
    padding-left: 10px;
}


#first .view {
    color: #003967;
    text-decoration: none;
    font-size: 12px;
    float: right;
    margin: 5px 0 0 0;
}

.tb_category {
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;
    margin-top: 4px;
}

.tb_category tr td {
    background: #F0F4E6;
    /*text-align: center;*/
    padding: 5px 9px;
    vertical-align: top;
}

.tb_category tr th {
    font-weight: normal;
    border-bottom: 1px solid #AFAFAF;
    padding: 0 9px;
    text-align: left;
}

.tb_category tr th:first-child, .tb_category tr td:first-child {
    text-align: left;
}

.rait {
    line-height: 10px;
    margin-bottom: 2px;
    min-height: 10px;
}

.rait .star {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(/Static/au/images/etf/star.png) 0 0 no-repeat;
}

.three_cols_item h5 {
    font-size: 17px;
    color: #333;
    border-bottom: 1px solid #000;
}

.feature_list {
}

.feature_list p {
    line-height: 18px;
}

.feature_list h6 {
    font-size: 12px;
    color: #84A72E;
    font-weight: normal;
    margin: 6px 0 4px;
}

.three_cols_item {
    width: 216px;
    float: left;
    font-size: 12px;
    margin-bottom: 25px;
}
/*.three_cols_item+.three_cols_item { margin-left: 19px;}*/
.three_cols_item ul.details li.grey {
    background: #F0F4E6;
}

.three_cols_item ul.details li.total {
    border: none;
}

.three_cols_item .warning {
    color: #666;
    font-size: 9px;
    margin-top: 9px;
    display: block;
}

.mleft20 {
    margin-left: 20px;
}

.mleft17 {
    margin-left: 17px;
}

.mright17 {
    margin-right: 17px;
}

.mbot50 {
    margin-bottom: 50px;
}

.mtop10 {
    margin-top: 10px;
}

.mtop50 {
    margin-top: 50px;
}

.mtop35 {
    margin-top: 35px;
}

.mbot20 {
    margin-bottom: 20px;
}

.mbot25 {
    margin-bottom: 25px;
}

.ptop15 {
    padding-top: 15px;
}

.pleft10 {
    padding-left: 10px;
}

.clear {
    clear: both;
}

.right_block .second {
    margin-top: 32px;
}

.right_block .third {
    width: 455px;
    margin-top: 32px;
}

.tb_history {
    margin-bottom: 10px;
}

.tb_history td:not(:first-child),
.tb_history td:nth-child(n+2) {
    text-align: right !important;
}

.tb_common {
    width: 100%;
    font-size: 12px;
}

.tb_common td {
    padding: 4px 10px;
    text-align: left;
}

.tb_common th {
    padding: 4px 10px;
    border-bottom: 1px solid #AFAFAF;
    text-align: right;
    font-weight: normal;
}

.tb_common td.last, .tb_common th.last {
    text-align: right;
}

.tb_common .odd td {
    background: #E8E3DB;
}

.total_number {
    background: #ECECEC;
    font-size: 11px;
    padding: 5px 10px;
    border-bottom: 1px solid #0033A0;
}

.diagram_block {
    font-size: 12px;
    /* overflow: hidden; */
    margin-bottom: 20px;
}

.right_col .right_block.right_block_dia {
    width: 452px;
}

.diagram_block .chart_container_pie {
    float: left;
    margin-left: -15px;
    width: 225px;
}

.diagram_block .details {
    float: right;
    width: 220px;
    margin-top: 8px;
}

.diagram_block ul.details li.grey {
    background: #E8E3DB;
}

.diagram_block .details .details li.grey {
    background: #F0F4E6;
}

.diagram_block .details .dia_color {
    float: left;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    margin-top: 2px;
}

.diagram_block ul.details li.total {
    padding-left: 25px;
}

.diagram_block .dia_color.green {
    background: #84A72E;
}


.distr_history {
    width: 100%;
    font-size: 12px;
}

.distr_history.dividend tr td:first-child {
    min-width: 60px;
}

.distr_history th {
    font-weight: normal;
    border-bottom: 1px solid #AFAFAF;
    padding: 3px 5px;
    text-align: left;
    vertical-align: bottom;
}

.distr_history td {
    padding: 4px 5px;
    text-align: left;
}

.distr_history .color td {
    background: #E8E3DB;
    border-top: 1px solid #AFAFAF;
}

.distr_history th:first-child, .distr_history td:first-child {
    text-align: left;
    min-width: 60px;
}

.graf_block {
}

.two_cols {
    font-size: 12px;
}

.two_cols_left_item {
    width: 452px;
    float: left;
    margin-bottom: 25px;
}

.two_cols_left_item .img_graf {
    margin: 10px 0 10px 5px;
    max-width: 448px;
}

.two_cols_right_item {
    width: 218px;
    float: left;
    margin-bottom: 25px;
}

.two_cols_left_item li a.link,
.two_cols_right_item li a.link {
    padding-left: 15px;
}

.two_cols ul.details li.grey {
    background: #E8E3DB;
}

.two_cols ul.details.darkGrey li.grey {
    background: #E8E3DB;
}

.graf_block .img_graf {
    margin: 12px 0 0 6px;
}


.features {
    line-height: 17px;
    padding-bottom: 6px;
}

.features strong {
    color: #0033A0;
}

.features p {
    margin-bottom: 6px;
}

.features ul {
    padding: 0 0 0 15px;
}

.features ul li {
    line-height: 17px;
}

/* =================== footer */
footer {
    padding: 0;
    margin-bottom: 0;
    position: relative;
    font-family: arial, sans-serif;
    font-size: 12px;
}

.home-page footer {
    border: none;
}

/* footer seo-menu styles */

.footerCopyRight {
    float: right;
    right: 50px;
    position: absolute;
    bottom: 30px;
    font-size: 14px;
    color: #000;
    font-weight: bold;
}

.copyRight {
    color: #000;
    font-weight: bold;
    padding-left: 0;
}

.footerNav li {
    border-right: none;
    margin-right: 0;
    padding-right: 5px !important;
    font-family: arial, sans-serif;
    font-size: 1.2em !important;
    font-weight: bold;
}

.footerNav {
    padding: 50px 0 0;
    margin: 0 32px;
    color: #404040 !important;
}

.footerNav li a {
    color: #404040;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
}

.footerNav li a:hover {
    text-decoration: underline;
}


footer .copyRightFooter {
    font-family: Arial, sans-serif;
    font-size: 11px;
    color: #666;
}



/* performance */
.peformance_footer {
    color: #666;
    font-size: 11px;
    margin: 5px 2px;
    clear: both;
}

.peformance_footer p + p {
    margin-top: 5px;
}

/* docs pdf, content and link */
a.docs {
    font-size: 1.0em;
    display: block;
    padding-left: 18px;
}

a.pdf {
    background: url(/globalassets/home.au/media/libraryitems/images/Icon-and-Images/pdf_sidebar.gif) top left no-repeat;
}

a.content {
    background: url(/globalassets/home.au/media/libraryitems/images/Icon-and-Images/a.png) top left no-repeat;
}

a.link {
    background: url(/globalassets/home.au/media/libraryitems/images/Icon-and-Images/a.png) top left no-repeat;
}

a.podcast {
    padding-left: 18px;
}

a.video {
    padding-left: 18px;
}

.sidebar a.pdf:hover, .fundInfo a.pdf:hover, .sidebar a.link:hover {
    text-decoration: underline;
}

.button_moat {
    -moz-text-blink: none;
    -moz-text-decoration-color: -moz-use-text-color;
    -moz-text-decoration-line: none;
    -moz-text-decoration-style: solid;
    background-color: #B0C883;
    border-radius: 5px;
    color: #000000 !important;
    font-size: 15px;
    font-weight: bold !important;
    margin: 3px 0;
    padding: 7px 9px;
    position: relative;
    text-align: center;
    width: 70px;
}

/* videoViewpoint side */
.videoViewpoint li {
    clear: both;
    overflow: hidden;
}

.leftMenuColHome .videoViewpoint li {
    overflow: hidden;
    clear: none;
}

.videoViewpoint img {
    float: left;
    display: inline;
    margin: 0 5px 0 0;
    border: 1px solid #000;
    position: relative;
}

.videoViewpoint li a {
    display: block;
    overflow: hidden;
    line-height: 1.3em;
    padding-bottom: 5px;
    color: #006bb4;
    text-decoration: none;
}



.videoViewpoint li a:hover {
    text-decoration: underline;
}

.videoViewpoint li.new a span {
    display: block;
    margin-left: 97px;
}


/* growth chart */
.right_col .etf_hyothetical_growth {
    min-width: 100%;
}

.etf_hyothetical_growth .diagram_block .chart_container {
    float: left;
    min-width: 100%;
}

.growthChartControls {
    float: left;
    margin: 0 0 0 100px;
}

.growthChartControls a:link {
    font-weight: bold;
    color: #0033A0;
    text-decoration: none;
    font-size: 10px;
}

.growthChartControls select {
    border: 1px solid;
}

.growthChartControls input[type="submit"] {
    border: 2px outset buttonface;
}

.growthChartControls table a {
    padding: 0 10px;
}

.growthChartControls table {
    width: auto !important;
}

.growthChartControls table.growth_period {
    border: 0;
    margin: 0 0 8px 50px;
}

.growthChartControls table.growth_date {
    border: 0;
    margin: 0 0 8px 0;
}

/*foreign tax table*/

/*.dotasof */
.dotbody-inner table tr:first-child {
    border-bottom: 1px solid #AFAFAF;
}

.dotbody-inner table {
    width: 100%;
}
/*
.dotbody-inner table tr
{
    padding: 4px 12px 4px 7px;
    overflow: hidden;
    font-size: 12px;
    vertical-align: top;
}
*/

.dotbody-inner table tr:nth-child(even),
.foreign_tax p:nth-of-type(even) {
    background: #E8E3DB;
}

.foreign_tax p,
.dotbody-inner table td {
    padding: 4px 12px 4px 7px;
    overflow: hidden;
    font-size: 12px;
}

.dotbody-inner table td:nth-child(2),
.dotbody-inner table td:first-child + td {
    text-align: right;
}

.ETFdisclaimer {
    font-family: Arial, sans-serif;
    font-size: 11px;
    line-height: 17px;
    color: #666666;
    margin: 5px 58px;
    clear: both;
}

/* override for Index Keypoints */
.features ul {
    list-style-type: disc;
}

.right_block .features a {
    color: #006bb4 !important;
}

/*prices widget*/
.dotbody h4 span {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
    /*
    border-bottom: 1px solid #000; */
    padding: 0 0 2px 0px;
}

.prices th {
    font-weight: normal;
}
/*Override for select another fund control*/
.fundDropdown {
    float: right !important;
}
/*Override for select another Email control*/
a.mail {
    background: url(/Static/au/images/global_mf/mail.gif) no-repeat top left;
    position: relative;
    float: right;
}

a.icon {
    width: 25px;
    height: 16px;
    margin-top: -15px;
}

a.print {
    position: relative;
    background: url(/Static/images/etf/icon_print.png) no-repeat top left;
    float: right;
}

#imgVaneckLogo {
    float: right;
}


.two_cols_right_item h4 span span {
    float: right;
    *float: none;
}

#ctl00_ctl00_cphMain_cphTop_dzFundInfo_uxDropZone {
    margin-top: 15px;
}
/* override for Foreign tax widget */
.wi_tabs_table a {
    color: #006bb4;
}

.prices a[disabled=disabled] {
    text-decoration: none;
    color: black !important;
}

#imgVaneckLogo {
    float: right;
}


/*language drop-down */
.LanguageDropDown {
    float: left;
}



/* alignment styles */
.alignLeft {
    text-align: left;
}

.alignRight {
    text-align: right;
}

/*override fund dropdown*/
#fundDD {
    width: 150px !important;
}
/*hide context menu in high charts*/
.highcharts-button {
    display: none;
}




/* NEW styles 5/7/13
*************************************************/
.right_block .without_border {
    border: none;
}

.vector_picker {
    border: 1px solid #0033A0;
    background: #e8e3db;
    padding: 15px 20px 0 21px;
}

.vector_picker .pick {
    color: #18191B;
    font-size: 12px;
    /*overflow: hidden;*/
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: inline-flex;
}

.pick .title_line {
    border-bottom: 1px solid #0033A0;
    margin: 0 0 7px 25px;
    padding: 0 0 5px 0;
    font-weight: 700;
}

.pick .title_line:first-child {
    margin: 0 0 7px 0;
}

.pick li {
    margin: 0 0 10px 25px;
    width: 100%;
    float: left;
}


/* IE hack */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .pick li.pickerCols {
        width: auto !important;
    }

    .vector_picker .pick {
        width: auto !important;
    }

    .zebra tr td {
        vertical-align: middle !important;
    }
}


.pick li.wide {
    width: 302px;
}

.pick li.wide.for-slider {
    margin: 10px 0 10px 28px;
    width: 297px;
}

.pick li:first-child {
    margin: 0 0 10px 0;
}

.pick li p {
    padding: 0 0 5px 0;
}

.pick input[type="checkbox"] {
    margin: 2px 6px 0 0;
    float: left;
    *margin: 0 6px 0 0;
}

.pick label {
    display: block;
    padding: 2px 0 0 0;
}

.pick label#amount {
    float: right;
    color: #797B75;
    font-size: 10px;
    line-height: 12px;
    white-space: nowrap;
    padding-top: 6px;
}

.pick select {
    width: 100%;
}

.filters {
    background: #E0E9CC;
    border-left: 1px solid #83A72B;
    border-right: 1px solid #83A72B;
    padding: 11px 15px 14px 16px;
    clear: both;
    font-size: 12px;
}

.filters .active_filters {
    margin: 11px 0 0 0;
}

.active_filters .one_filter {
    margin: 0 6px 0 0;
    background: #83A72B;
    color: #fff;
    border: 1px solid #000;
    height: 28px;
    line-height: 28px;
    padding: 0 15px 0 6px;
    overflow: hidden;
    float: left;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

.one_filter .delete_filt {
    display: block;
    width: 17px;
    height: 17px;
    float: left;
    margin: 5px 7px 0 0;
}

.one_filter span {
    display: block;
    float: left;
}

.active_filters .clear {
    color: #000;
    text-decoration: none;
    display: block;
    float: left;
    margin: 8px 0 0 10px;
}

.active_filters .clear:hover {
    text-decoration: underline;
}

.vector_picker ul.tabNavigation {
    margin-bottom: -1px;
}

.right_block .picker_tabs {
}

.tabNavigation li a {
    height: 24px;
    display: block;
    line-height: 28px;
}

ul.tabNavigation li a.selected,
ul.tabNavigation li a.selected:hover {
    line-height: 27px;
}

#slider {
    margin-left: 2px;
    width: 293px;
}

#slider.ui-slider-horizontal {
    height: 3px;
}

#slider.ui-slider-horizontal .ui-slider-range {
    top: -1px;
    height: 5px;
}

#slider .ui-widget-header {
    background-position: 0 -40px;
}

#slider a.ui-slider-handle {
    background-position: 0 -57px;
    border: medium none;
    height: 20px;
    width: 9px;
    top: -8px;
    cursor: pointer;
    margin-left: -4px;
}

#sliderScale {
    margin-left: -5px;
    margin-top: 10px;
    width: 310px;
}

#sliderScale span {
    color: #797B75;
    font-size: 10px;
    margin-left: 16px;
    position: relative;
    text-decoration: none;
}

#sliderScale span:first-child {
    margin-left: 0;
}

#sliderScale span ins {
    border-left: 1px solid #BCBCBC;
    height: 8px;
    left: 12px;
    position: absolute;
    top: -17px;
    width: 1px;
}


.zebra {
    width: 100%;
    border-bottom: 1px solid #AFAFAF;
    font-size: 11px;
    margin: 17px 0 0 0;
    border-collapse: collapse;
}

.zebra tr td {
    border: none;
    height: 25px;
    padding: 10px 3px 7px 3px;
    vertical-align: top;
    background: #fff;
    line-height: 1.4;
    color: #666666;
}

.zebra tr td:first-child,
.zebra tr th:first-child {
    padding: 10px 3px 5px 8px;
    width: 46px;
}

.zebra tr.color td {
    background: #e8e3db;
}

.zebra tr th {
    vertical-align: bottom;
    padding: 8px 0px 2px 5px;
    border-bottom: 1px solid #AFAFAF;
    font-weight: 700;
    text-align: left;
}

.zebra tr th.asOfDate {
    text-align: right;
}

.zebra th a {
    cursor: pointer;
    color: #000000;
}

.zebra th a span {
    display: inline-block;
    height: 10px;
    margin-left: 2px;
    width: 8px;
    background: url(/globalassets/home.au/media/libraryitems/images/Icon-and-Images/up_off.gif) 0 0 no-repeat;
}

.zebra th a.sortUp span {
    background: url(/globalassets/home.au/media/libraryitems/images/Icon-and-Images/up_on.gif) 0 0 no-repeat;
}

.zebra th a.sortDown span {
    background: url(/globalassets/home.au/media/libraryitems/images/Icon-and-Images/down_on.gif) 0 0 no-repeat;
}

.zebra tr th.left-text {
    text-align: left;
    padding: 0 0 5px 10px;
}

.zebra .left-text {
    text-align: left;
    padding: 10px 3px 7px 10px;
}

.zebra tr td p {
    color: #AEAEAE;
}

.colored-ticker {
    border-radius: 3px 3px 3px 3px;
    color: #FFFFFF;
    display: block;
    font-size: 9px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    width: 36px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.colored-ticker a {
    color: #ffffff;
}

.zebra .pdf_docs {
    background: url("/Static/au/images/etf/icon_acrob_ex.png") no-repeat scroll left top transparent;
    display: inline;
    padding-bottom: 6px;
    padding-left: 18px;
}

/* -- Safari fix */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .zebra .pdf_docs {
        background: url("/Static/au/images/etf/icon_acrob_ex.png") no-repeat scroll left top transparent;
        display: inline-block;
        padding-bottom: 6px;
        padding-left: 18px;
        min-height: 40px;
    }

    .zebra .xsl_docs {
        display: inline-block !important;
        min-height: 40px;
    }
}

.zebra .xsl_docs {
    background: url("/Static/au/images/etf/icon_ex.png") no-repeat scroll left top transparent;
    display: inline;
    padding-bottom: 6px;
    padding-left: 18px;
}

.zebra .txt_docs {
    background: url("/Static/au/images/etf/icon_txt_ex.png") no-repeat scroll left top transparent;
    display: inline;
    padding-bottom: 6px;
    padding-left: 18px;
}

.right_block h2.headerForMain {
    border-bottom: none;
}

.main .left_col {
    margin-top: 69px !important;
}

.pick_equity a.jqTransformCheckbox, .pick_income a.jqTransformCheckbox, .pick a.jqTransformCheckbox {
    background-image: url("/Static/au/images/etf/jqTransform/ve_checks_sprite.png");
    background-position: 2px top;
}

.pick_equity a.jqTransformChecked, .pick_income a.jqTransformChecked, .pick a.jqTransformChecked {
    background-position-y: bottom;
}

#icb_b a.jqTransformCheckbox {
    background-position: 2px top;
}

#icb_i a.jqTransformCheckbox {
    background-position: -18px top;
}

#icb_ha a.jqTransformCheckbox {
    background-position: -38px top;
}

#icb_s a.jqTransformCheckbox {
    background-position: -58px top;
}

#icb_cb a.jqTransformCheckbox {
    background-position: -78px top;
}

#icb_ei a.jqTransformCheckbox {
    background-position: -98px top;
}

#icb_ib a.jqTransformCheckbox {
    background-position: -118px top;
}

#icb_mib a.jqTransformCheckbox {
    background-position: -138px top;
}

.pick a.jqTransformCheckbox {
    background-position: -157px top;
}


#icb_b a.jqTransformChecked, #icb_lvc a.jqTransformChecked {
    background-position: -98px bottom;
}

#icb_i a.jqTransformChecked {
    background-position: -18px bottom;
}

#icb_as a.jqTransformChecked {
    background-position: 2px bottom;
}

#icb_abb a.jqTransformChecked {
    background-position: -98px bottom;
}

#icb_ha a.jqTransformChecked {
    background-position: -38px bottom;
}

#icb_s a.jqTransformChecked {
    background-position: -58px bottom;
}

#icb_cb a.jqTransformChecked {
    background-position: -78px bottom;
}

#icb_ei a.jqTransformChecked {
    background-position: -98px bottom;
}

#icb_ib a.jqTransformChecked {
    background-position: -118px bottom;
}

#icb_mib a.jqTransformChecked {
    background-position: -138px bottom;
}

#icb_ce a.jqTransformChecked {
    background-position: -38px bottom;
}

#icb_asamc a.jqTransformChecked {
    background-position: -118px bottom;
}

.pick a.jqTransformChecked {
    background-position: -158px bottom;
}

.pick span.jqTransformCheckboxWrapper {
    margin-right: 7px;
}

span.jqTransformCheckboxWrapper {
    display: block;
    float: left;
}

/*etf login control */

.failure_text {
    color: red;
    font-size: 10px;
}

.videoViewpoint {
    background-color: #ECF0F4;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat;
    color: #666666;
    font-size: 10px;
    padding: 7px;
}

ul.videoViewpoint li.new IMG {
    border: none;
}

.videoViewpoint li:first-child img {
    padding-bottom: 5px;
}

.sidebarLi h4 {
    border-bottom-color: #1A1A1A;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: #4C4C4C;
    font-family: Arial,sans-serif;
    font-size: 15px;
    font-weight: bold;
}

.buttonClass {
    background-color: #ABABAB;
    border: 2px outset buttonface !important;
    color: #000000 !important;
    padding: 4px 7px !important;
    text-decoration: none;
}

.distr_history td.first {
    text-align: left;
}

.distr_history td.number {
    text-align: right;
}

/* Start Prices and Returns */
.pricesreturns .tabs {
    background: none repeat scroll 0 0 #ECECEC;
    border-bottom: 1px solid #0033A0;
    height: 32px;
    margin: 0 0 0 10px !important;
    width: 98.5% !important;
}

.pricesreturns .dotbody {
    margin: 10px 0 20px !important;
}

.pricesreturns .dotbody table {
    margin-bottom: 28px;
}

.pricesreturns .dotbody a:link, .dotbody a:visited {
    font-weight: normal !important;
    font-family: Arial !important;
    color: #ffffff;
}

.pricesreturns .dotasof {
    margin-top: 10px;
}

.pricesreturns nav.in_right ul {
    min-width: 681px !important;
}

.pricesreturns .dotright span, .dotbody span {
    float: none !important;
    font-family: Arial,sans-serif !important;
}

.pricesreturns {
    width: 712px !important;
}

.pricesreturns .dotbody .index_returns_table {
    width: 100% !important;
}

.pricesreturns .index_returns_table .dotright span {
    float: right !important;
}

.pricesreturns .index_returns_table th {
    min-width: 24px;
    padding: 5px 2px 2px;
}

.pricesreturns .dotbody-inner table tr:first-child {
    border-bottom: medium none !important;
    border-top: 1px solid #AFAFAF;
}

.pricesreturns .dotbody-inner table td {
    text-align: right !important;
}

.pricesreturns .dotbody-inner table td:first-child {
    text-align: left !important;
}

.pricesreturns select, input.textInput {
    width: 75px;
}

.pricesreturns .dailyprices {
    padding-bottom: 10px;
}
/* End Prices and Returns */

/* Global Nav Start */
/** {
    box-sizing: content-box;
}*/

/*#global-nav-wrapper {
    background-color: #ffffff;
    border-top: none;
    float: right;
    width: 67%;
}*/

#global-nav li a {
    border-top: none;
}

#global-nav {
    border-top: none;
    margin-top: 6px;
}

.text_mini_block p {
    margin-bottom: 6px;
}

.iframeContainer {
    height: 535px !important;
}
/*Customize Widget  CSS*/
.exposure-otc div h4 {
    font-size: 12px;
}

.derivative-holdings div {
    margin-bottom: 10px !important;
}
/*START - New Styles as per new color scheme for funds*/
.lists.aquamarine li.title {
    background: #37ADC3;
}

.lists.aquamarine li.colored {
    background: #def2f6 none repeat scroll 0 0;
}

.lists.pale-blue li.title {
    background: #A0DCF2;
}

.lists.pale-blue li.colored {
    background: #e0f4f3 none repeat scroll 0 0;
}

.lists.light-green li.title {
    background: #66C3A0;
}

.lists.light-green li.colored {
    background: #e6f5f0 none repeat scroll 0 0;
}

.lists.warm-red li.title {
    background: #BE604D;
}

.lists.warm-red li.colored {
    background: #f5e5e2 none repeat scroll 0 0;
}

.lists.aqua-lime li.title {
    background: #296b53;
}

.lists.aqua-lime li.colored {
    background: #c2e7d9 none repeat scroll 0 0;
}

.lists.orange li.title {
    background: #E3804A;
}

.lists.orange li.colored {
    background: #edded2 none repeat scroll 0 0;
}

.lists.peach li.title {
    background: #836e4f;
}

.lists.peach li.colored {
    background: #ddd4c7 none repeat scroll 0 0;
}

a.ticker.aquamarine {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -336px;
}

a.ticker.pale-blue {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -367px;
}

a.ticker.light-green {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -398px;
}

a.ticker.warm-red {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -429px;
}

a.ticker.orange {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -460px;
}

a.ticker.peach {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -553px;
}

a.ticker.aqua-lime {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -584px;
}

a.ticker.cerulean {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -646px;
}

a.ticker.purple {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -677px;
}

a.ticker.supernova {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -708px;
}

a.ticker.midnight-blue {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-big-ticker.png") no-repeat scroll 0 -739px;
}

.names .a_name.aquamarine, .colored-ticker.aquamarine {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -199px;
}

.names .a_name.pale-blue, .colored-ticker.pale-blue {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -216px;
}

.names .a_name.light-green, .colored-ticker.light-green {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -234px;
}

.names .a_name.warm-red, .colored-ticker.warm-red {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -252px;
}

.names .a_name.orange, .colored-ticker.orange {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -270px;
}

.names .a_name.peach, .colored-ticker.peach {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -325px;
}

.names .a_name.aqua-lime, .colored-ticker.aqua-lime {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -342px;
}

.names .a_name.cerulean, .colored-ticker.cerulean {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -378px;
}

.names .a_name.purple, .colored-ticker.purple {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -396px;
}

.names .a_name.supernova, .colored-ticker.supernova {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -414px;
}

.names .a_name.midnight-blue, .colored-ticker.midnight-blue {
    background: rgba(0, 0, 0, 0) url("/uploadedImages/icon-and-images/sprite-small-ticker.png") no-repeat scroll 0 -432px;
}

.pick_equity a.jqTransformCheckbox, .pick_income a.jqTransformCheckbox, .pick a.jqTransformCheckbox {
    background-image: url("/uploadedImages/icon-and-images/sprite-checks-ticker.png");
    background-position: 2px top;
}

.pick a.jqTransformChecked {
    background-position: -117px bottom;
}

#icb_abb a.jqTransformChecked, #icb_g a.jqTransformChecked {
    background-position: 2px bottom;
}

#icb_aei a.jqTransformChecked {
    background-position: -18px bottom;
}

#icb_as a.jqTransformChecked {
    background-position: -18px bottom;
}

#icb_asamc a.jqTransformChecked {
    background-position: -38px bottom;
}

#icb_ce a.jqTransformChecked, #icb_gs a.jqTransformChecked {
    background-position: -78px bottom;
}

#icb_i a.jqTransformChecked {
    background-position: -58px bottom;
}

#icb_afi a.jqTransformChecked {
    background-position: -136px bottom;
}

#icb_ai a.jqTransformChecked, #icb_ai a.jqTransformChecked, #icb_a a.jqTransformChecked, #icb_a a.jqTransformChecked {
    background-position: -136px bottom;
}

#icb_si a.jqTransformChecked {
    background-position: -176px bottom;
}

#icb_t a.jqTransformChecked {
    background-position: -234px bottom;
}

#icb_aei a.jqTransformChecked, #icb_ai a.jqTransformChecked {
    background-position: -272px bottom;
}

/*END - New Styles as per new color scheme for funds*/

/*START - Slides Override*/
.slides_container .vector.slide {
    border-bottom: 10px solid #0033A0 !important;
}

.slides_container .vector.slide h1 span.attention {
    color: #0033A0 !important;
}

.slides_container .vector.slide a {
    color: #0033A0 !important;
}
/*END - */
.features li span, .etf_landingpg h3 {
    color: #0033A0 !important;
}

/************ Bootstrap Modal - START *******************/
.modal-open {
    overflow: hidden
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    padding: 0;
    margin: 0;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0,-25%);
    -ms-transform: translate(0,-25%);
    -o-transform: translate(0,-25%);
    transform: translate(0,-25%)
}

.modal.in .modal-dialog {
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0)
}

.modal button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}

.modal .close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-dialog {
    position: relative;
    width: 600px;
    margin: 10px auto
}

.modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 0;
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5)
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10040;
    background-color: #000
}

.modal-backdrop.fade {
    filter: alpha(opacity=0);
    opacity: 0
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5
}

.modal-header {
    padding: 15px
}

.modal-header .close {
    margin-top: -2px
}

.modal-title {
    margin: 0;
    line-height: 1.42857143
}

.modal-body {
    position: relative;
    padding: 0 !important;
    overflow: auto;
    width: 100%
}

.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    overflow: auto
}

.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px
}

.modal-footer .btn-group .btn + .btn {
    margin-left: -1px
}

.modal-footer .btn-block + .btn-block {
    margin-left: 0
}

.modal-footer .agree-btn, .modal-footer .decline-btn, .modal-footer .close-btn {
    text-align: left
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

button.close {
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2
}

.popup-holder {
    position: relative;
    height: 0;
    overflow: hidden;
}

.disclaimerpop {
    height: 100%;
    min-height: 100%;
}

.disclaimerpop .header {
    height: 5%;
}

.disclaimerpop h1 {
    margin: 0 0 16px;
    color: #000;
    font: 30px/39px "Open Sans",Arial,Verdana,sans-serif;
}

.disclaimerpop p {
    margin: 0 0 18px;
}

.disclaimerpop .holder {
    overflow: auto;
    margin: 0 0 31px;
}

.disclaimerpop .holder .frame {
    padding: 0;
}

/* buttons */
.disclaimerpop .buttons-holder {
    overflow: hidden;
    padding: 0 41px 11px;
    height: 20%
}

.disclaimerpop .agree-btn, .disclaimerpop .decline-btn, .disclaimerpop .close-btn {
    float: right;
    color: #fff;
    height: 30px;
    text-transform: uppercase;
    font: bold 14px/28px Verdana,Geneva,Arial,Helvetica,sans-serif;
    padding: 0 27px 0 0
}

.disclaimerpop .agree-btn span, .disclaimerpop .decline-btn span, .disclaimerpop .close-btn span {
    float: left;
    height: 30px;
    padding: 0 7px 0 13px;
    min-width: 113px
}

/* decline button */
.disclaimerpop .close-btn {
    background: url(/Static/au/images/sprite.png) no-repeat 100% -671px
}

.disclaimerpop .decline-btn {
    float: left;
    background: url(/Static/au/images/sprite.png) no-repeat 100% -671px
}

.disclaimerpop .decline-btn span, .disclaimerpop .close-btn span {
    background: url(/Static/au/images/sprite.png) no-repeat 0 -701px;
    cursor: pointer;
}

.disclaimerpop .decline-btn:hover, .disclaimerpop .close-btn:hover {
    background: url(/Static/au/images/sprite.png) no-repeat 100% -581px;
    text-decoration: none
}

.disclaimerpop .decline-btn:hover span, .disclaimerpop .close-btn:hover span {
    background: url(/Static/au/images/sprite.png) no-repeat 0 -611px
}

/* agree button */
.disclaimerpop .agree-btn {
    background: url(/Static/au/images/sprite.png) no-repeat 100% -731px
}

.disclaimerpop .agree-btn span {
    background: url(/Static/au/images/sprite.png) no-repeat 0 -701px;
    cursor: pointer;
}

.disclaimerpop .agree-btn:hover {
    background: url(/Static/au/images/sprite.png) no-repeat 100% -641px;
    text-decoration: none
}

.disclaimerpop .agree-btn:hover span {
    background: url(/Static/au/images/sprite.png) no-repeat 0 -611px
}

/* use some fancybox original styles*/
#disclaimerpop-content {
    padding: 0;
    outline: none;
    position: relative;
    overflow: hidden;
    z-index: 1102;
    background: #fff;
    border-width: 0;
    width: 100%;
    height: auto;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 25px;
    color: rgb(88, 89, 91);
}

.disclaimerpop-content-inner {
    width: auto;
    height: auto;
    overflow: auto;
    position: relative;
}

#popup1 {
    padding: 0 !important;
    width: 100% !important;
}

#disclaimerpop-close {
    position: absolute;
    top: 18px;
    right: 14px;
    width: 13px;
    height: 11px;
    background: transparent url('/Static/au/images/fancy_close.png');
    cursor: pointer;
    z-index: 1103;
    display: none;
}

iframe[name='google_conversion_frame'] {
    display: none;
}

@media screen and (max-width:768px) {
    .modal {
        padding: 0 !important;
        margin: 0 !important;
    }

    .modal-dialog {
        width: auto !important;
        margin: 10px;
    }

    .modal-body {
        padding: 0;
        overflow: auto;
        width: 100%;
    }

    .disclaimerpop {
        padding: 10px 5px;
    }

    .disclaimerpop .agree-btn span, .disclaimerpop .decline-btn span, .disclaimerpop .close-btn span {
        min-width: 92px;
    }

    #disclaimerpop-content {
        padding: 0 15px;
        width: auto;
        overflow: auto;
    }

    .disclaimerpop .holder {
        width: 100% !important;
    }

    .disclaimerpop .buttons-holder {
        padding: 0;
    }
}
/************ Bootstrap Modal - END *******************/

/* Ektron CMS bubble Fix */

a.EktronEditorsMenuMarker {
    float: left;
    width: 5px !important;
    height: 0px !important;
}

a.EktronEditorsMenuMarker img {
    margin: -5px 0 5px 0 !important;
    padding: 0;
    width: 8px;
    height: 8px;
    position: absolute !important;
}

/************ AHP MODAL - BEGIN *******************/
.ahp-deeplink-modal div.modal-content {
    width: 595px;
    height: 465px;
    border: 0;
}

.ahp-deeplink-modal iframe {
    width: 100%;
    height: 465px;
}

.ahp-selector-modal iframe#selector {
    width: 902px;
    height: 700px;
}

.ahp-selector-modal .modal-dialog {
    width: 902px;
}

.ahp-selector-modal .modal-body {
    padding: 0px;
    overflow: hidden;
}

.ahp-selector-modal {
    padding-right: 0 !important;
    padding-left: 0 !important;
    overflow: hidden !important;
}

@media screen and (max-width: 902px) and (min-width: 0) {
    .ahp-selector-modal iframe#selector {
        width: 100%;
    }

    .ahp-selector-modal iframe#selector {
        width: 100%;
    }

    .ahp-selector-modal .modal-content {
        margin: 5px;
    }
}


@media screen and (min-width: 0px) and (max-width: 768px) {
    .ahp-deeplink-modal .modal-dialog {
        width: 595px !important;
        margin: 30px auto;
    }

    a#open-ahp-modal-mobile {
        line-height: 20px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .ahp-selector-modal iframe#selector {
        width: 100%;
    }

    .ahp-selector-modal .modal-dialog {
        margin: 10px 0;
    }
}

@media only screen and (min-device-width: 593px) and (max-device-width: 1024px) and (orientation: landscape) {
    .ahp-selector-modal .modal-body {
        padding: 0px;
        margin: 0;
    }

    .ahp-selector-modal body {
        margin-left: 0;
    }
}


@media screen and (min-width: 0px) and (max-width: 414px) {
    .ahp-deeplink-modal div.modal-dialog {
        width: 90% !important;
        margin: 30px auto;
    }

    .ahp-deeplink-modal div.modal-content {
        width: 100%;
        height: 500px;
        border: 0 !important;
    }

    .ahp-deeplink-modal iframe {
        width: 100%;
        height: 500px;
    }
}

@media screen and (min-width: 0px) and (max-width: 325px) {
    .ahp-selector-modal iframe#selector {
        min-width: 325px !important;
    }
}

/************ AUDIENCE MODAL - END *******************/

/************ HomePage - BEGIN *******************/
.leftColHome, .rightColHome {
    width: 290px;
    background: none;
    margin: 20px 0 0 7px;
    padding: 0;
}

.leftColHome h2, .rightColHome h2, .leftColHome h4, .rightColHome h4 {
    margin: 0 0 10px 0;
}

.home-page .main {
    border: 0;
    border-top: none;
    border-bottom: none;
    padding: 0 15px 0 11px;
    background: #fff;
}

.home-page .videoViewpoint li.new a {
    background: none;
}

.home-page .slides_container .vector.slide {
    border-bottom: 0 !important;
}

.home-page .build-better-portfolios-img {
    padding-left: 15px;
    padding-top: 0;
    padding-bottom: 15px;
}

.home-page .build-better-portfolios-img-2 {
    padding-bottom: 22px;
    padding-left: 0;
}

.home-page .td-padding-left {
    padding-left: 15px;
}

.home-page .left-blue-line {
    height: 550px;
    width: 3px;
    background-color: #ffffff;
}

.home-page h4 {
    padding-top: 0;
    color: #002194 !important;
}

.home-page .leftColHome {
    margin: 20px 0 0 8px;
    float: left;
}

.home-page .home-tile4 {
    background-color: #FFFFFF;
    font-size: 12px;
    border: none;
    padding: 0;
    margin-left: 0;
}

.home-page .home-tile5 {
    margin-left: 0;
    border: 1px solid #06379D;
    padding: 3px 9px 20px;
}

.home-page .home-tile5 table {
    width: 100%;
}

.home-page .home-tile5 td {
    padding: 10px 2px 10px 8px;
}

.home-page .home-tile5 .first td {
    padding: 13px 10px;
}

.home-page .home-tile5 span {
    color: #06379D;
    font-size: 12px;
}

.home-page .home-tile4 a:link, .home-page .home-tile4 a:visited, .home-page .home-tile4 a {
    font-size: 12px;
    color: #777 !important;
    font-weight: normal;
    text-decoration: underline;
    display: block;
    padding-top: 6px;
}

.home-page .home-tile5 .first {
    color: #06379D;
    font-size: 11px;
    font-weight: bold;
}

.home-page .home-tile5 .alternate {
    background-color: #E1E6EA;
}

.home-page .home-tile5 .right {
    vertical-align: top;
    width: 90px;
    line-height: 16px;
}

.home-page .home-tile5 a:link, .home-page .home-tile5 a:visited, .home-page .home-tile5 a {
    font-size: 12px;
    color: #777 !important;
    font-weight: normal;
    text-decoration: underline;
    display: block;
    padding-top: 6px;
}

.home-page .home-tile {
    width: 277px;
    height: 295px;
    margin-bottom: 20px;
}

.home-page .home-tile img.bkgrnd {
    width: 280px;
    height: 266px;
}

.home-page .home-tile1 {
    margin-left: 0;
    background-color: #FFFFFF;
    padding: 18px 20px 21px;
    font-size: 12px;
    border: 1px solid #06379D;
    margin-bottom: 7px;
    width: 235px;
    min-height: 225px;
}

.home-page .home-tile1 img {
    padding: 0;
    margin-top: 30px;
}

.home-page .home-tile1 .date {
    font-size: 10px;
    color: #333;
}

.home-page .home-tile1 .title {
    color: #06379D;
    font-size: 16px;
    width: 235px;
    line-height: 20px;
    border-bottom: 1px solid #D8D9DD;
    padding: 12px 0;
}

.home-page .home-tile1 .title a {
    color: #06379D;
}

.home-tile1 .date a {
    font-size: 10px;
    color: #333 !important;
}

.home-page .home-tile1 a, .home-page .home-tile a {
    color: #fff;
}

.home-page .home-tile1 p a {
    line-height: 18px;
    color: black !important;
    font-weight: normal;
}

.home-page .home-tile1 p {
    font-size: 13px;
    padding-top: 14px;
    line-height: 18px;
    color: black;
    font-weight: normal;
    letter-spacing: 0.5px;
}

.home-page .home-tile3-text, .home-page .home-tile4-text, .home-page .home-video1-text, .home-page .home-video2-text, .home-page .home-video3-text {
    color: white;
    text-decoration: none;
    position: absolute;
    font-size: 16px;
    line-height: 21px;
}

.home-page .home-video4-text {
    bottom: 115px;
    position: relative;
    left: 15px;
    font-size: 16px;
    line-height: 21px;
    color: #fff;
}

.home-page .home-tile4 a:link {
    padding: 0;
}

.home-page .home-tile4-text {
    bottom: 70px;
    left: 20px;
    font-weight: bold;
}

.home-page .home-video2-text, .home-page .home-video3-text {
    font-size: 14px;
    bottom: 26px;
}

.home-page .home-tile3-text, .home-page .home-tile2-text, .home-page .home-video1-text, .home-page .home-video2-text, .home-page .home-video3-text {
    left: 18px;
}

.home-page .leftColHome a, .home-page .rightColHome a {
    display: block;
    position: relative;
}

.greentab h4 {
    color: #fff !important;
}

@media screen and (min-width:0) and (max-width: 767px) {
    .home-page .home-tile1, .home-page .home-tile4 {
        margin-left: 5px;
    }
}
/************ HomePage - END *******************/

/************ Hide AHP and Deep link in IE only in PRINT media  - END *******************/
@media print and (-ms-high-contrast: active), print and (-ms-high-contrast: none) {
    .modal-backdrop.in {
        filter: alpha(opacity=0) !important;
        opacity: 0 !important;
    }

    .ahp-deeplink-modal, .ahp-selector-modal {
        display: none !important;
    }
}

@media screen and (min-width:596px) and (max-width: 902px) {
    .ahp-deeplink-modal .modal-dialog {
        width: 595px !important;
    }
}

@media screen and (min-width:0px) and (max-width: 595px) {
    .ahp-deeplink-modal .modal-dialog {
        max-width: 595px !important;
    }

    .ahp-deeplink-modal div.modal-content {
        width: auto !important;
    }

    .ahp-deeplink-modal .modal-dialog {
        width: auto !important;
        margin: 30px 10px;
    }
}

/*VKommera: Added the below for Charts, please check with me before removing.*/
.growthChartControls select, .prices select, .fund_returns_table_prices_etf select {
    background-image: url(/Static/au/images/etf/jqTransform/sel_right.png), url(/Static/au/images/etf/jqTransform/sel_left.png);
    background-position: right bottom, left top;
    background-repeat: no-repeat, no-repeat;
    padding: 2px 0px 4px 3px;
    border: 0;
    -webkit-appearance: none;
    font-size: 12px !important;
    line-height: 19px;
    -moz-appearance: none;
    appearance: none;
}

.growthChartControls select::-ms-value, .prices select::-ms-value, .fund_returns_table_prices_etf select::-ms-value {
    background: none;
    color: #5a5a5a;
}

.fund_returns_table_prices_etf select {
    padding: 2px 0px 1px 3px;
}

.fund_returns_table_prices_etf select::-ms-expand, .prices select::-ms-expand, .growthChartControls select::-ms-expand {
    display: none;
}

@-moz-document url-prefix() {
    .fund_returns_table_prices_etf select {
        padding: 1px 12px 2px 3px;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .fund_returns_table_prices_etf select, .prices select {
        padding: 2px 0px 4.2px 4px;
    }

    .growthChartControls select {
        padding: 4px 0px 2.2px 3px;
    }
}

.growthChartControls input[type=submit] {
    padding: 3px 8px;
    background: #ccc;
    border: 0 none;
    cursor: pointer;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin-left: 5px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .growthChartControls select, .prices select {
        padding: 2px 0px 1px 3px;
    }
}

@supports (-ms-ime-align: auto) {
    .growthChartControls select, .prices select {
        padding: 1px 5px 0px 3px;
    }
}
/* VK: End*/
.content .not_found {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.content .not_found p {
    font-size: 24px;
    line-height: 40px !important;
}

.content .not_found .link {
    margin-top: 20px;
    color: #006BB4;
    font-size: 12px;
}

.content .not_found .link a, .content .not_found .link a:visited {
    color: #006BB4;
    font-size: 12px;
    padding: 0 7px;
}
/* Epi related CSS */

.utility-links {
    position: relative;
    top: -9px;
    left: -1px;
}

ul.details a.link {
    padding-left: 25px;
}
/* fix for JIRA bug #491 */
.etf-picker-tool .zebra tr th {
    text-align: center;
    padding: 5px 0 2px !important;
    vertical-align: bottom;
}

.etf-picker-tool .zebra tr td {
    text-align: center;
    padding: 10px 0;
    vertical-align: middle;
}

.etf-picker-tool .zebra tr td:first-child, .etf-picker-tool .zebra tr td:nth-child(2), .etf-picker-tool .zebra tr th:nth-child(2), .etf-picker-tool .zebra tr td:nth-child(3), .etf-picker-tool .zebra tr th:nth-child(3) {
    text-align: left;
}

.etf-picker-tool .zebra tr td:first-child {
    padding: 5px 8px;
}

.etf-picker-tool .zebra tr th:first-child {
    padding: 5px 8px 2px;
}

.etf-picker-tool .zebra tr th.asOfDate {
    text-align: right !important;
}

.etf-picker-tool span#factSheet {
    left: 25px;
    position: relative;
    display: block;
}

.etf-picker-tool span .pdf_docs, .etf-picker-tool .zebra span .xsl_docs {
    top: -3px;
    position: relative;
    min-height: inherit;
    display: inline !important;
}

.etf-picker-tool .zebra tr th a {
    font-size: 9px !important;
}
/* end of fix for JIRA bug #491 */

/* Epi related CSS */
.location {
    height: auto;
    min-height: 22px;
    padding: 7px 0 0 12px;
}

.utility-links li a.mail {
    width: 25px;
    height: 16px;
    margin-top: -15px;
}

.breadcrumb ul:nth-child(2) {
    margin: 0;
    padding: 0;
    position: absolute;
    float: right;
    right: 0;
    top: 20px
}

.location li {
    display: inline-block;
}

.utility-links li a.print {
    position: relative;
    background: url(/Static/images/etf/icon_print.png) no-repeat 0px 0px;
    float: right;
    left: 0px;
}

.breadcrumb {
    position: relative;
    padding: 0 !important;
}

.mainBody {
    font-size: 12px;
}

ul.details a.link {
    padding-left: 25px;
}

.page-class-etf-performance-list .dotbody-inner table td:nth-child(2), .page-class-etf-performance-list .dotbody-inner table td:first-child + td {
    vertical-align: bottom;
}

.exposure-otc h4 {
    font-size: 12px;
}

.page-class-investment-teams .leftMenu {
    background-color: #ffffff;
}

.page-class-etf .web-position-right {
    float: right;
}

.page-class-etf .block img {
    margin: 17px 0;
}

.page-class-etf .date-right h4 {
    float: right;
    width: 218px;
}

.page-class-etf .date-right h4 SPAN {
    float: right;
}

.page-class-etf .date-right {
    position: relative;
    display: inline-block;
}

.page-class-etf .date-right ul.details a.link {
    padding-left: 15px;
}

.page-class-etf ul.details a.link .page-class-etf ul.details li .xml {
    padding-left: 18px;
}

h3.register {
    padding-top: 30px;
}

.page-class-etf .etf_hyothetical_growth .chartContainer {
    margin-top: 20px;
}

.page-class-etf .picker_tabs span#lblManagementFee {
    font-size: 11px !important;
}

/* CMS blog post link CSS*/
.admin-link-container {
    position: relative;
}

.admin-link-container .admin-link-inner {
    position: absolute;
    right: 0;
}

.admin-link-container .admin-link-inner a {
    font-size: 14px;
    color: #0033A0;
}

.home-page .admin-link-container {
    height: 114px;
}

.home-page .admin-link-container .admin-link-inner {
    bottom: 0;
}

@media screen and (min-width: 0) and (max-width: 768px) {
    .admin-link-container {
        height: 0;
    }

    .admin-link-container .admin-link-inner {
        margin: 0 10px 10px;
    }
}

/* end of Epi related CSS */


/* Homepage redesign */

nav.top .drop_tab.blue {
    background-color: none;
}

/*a.hp-etf-tab {
    font-family: "Open Sans","Helvetica",sans-serif;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    display: block;
    float: left;
    font-size: 12px;
    font-weight: 600;
    top: 8px;
    position: relative;
    margin-left: 80px;
    margin-right: 20px;
    line-height: 38px;
    padding: 0 8px;
    color: #333;
}*/

a.hp-etf-tab:hover {
    text-decoration: none;
}

div.marketvectors nav.top div.title-part a {
    color: #17468f;
}

div.title-part {
    font-size: 22px;
    display: block;
    left: 14px;
    border-bottom: 2px solid #ccc;
    font-family: "Open Sans","Helvetica",sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
    line-height: 39px;
}

div.title-part a:hover {
    text-decoration: none;
}

.drop {
    border: 0;
    width: 100%;
    background-color: #fff;
    background: url(/Static/au/images/submenu-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 10px 5px 10px rgba(0,0,0,.2);
}

.drop .drop_contetn {
    border: 0;
    background: transparent;
    min-height: 430px;
    max-width: 1300px;
    margin: auto;
    margin-top: 12px;
}

.drop .drop_contetn .part {
    width: 700px;
}

.drop .drop_contetn .part.second {
    width: 330px;
}

.drop .drop_contetn .part ul.lists {
    width: 350px;
    margin-bottom: 30px;
}

.drop .drop_contetn .for_lists {
    width: 350px;
}

.drop .drop_contetn .lists li span:first-child {
    width: 400px;
    font-size: 11px;
    line-height: 35px;
}

.drop .drop_contetn .lists li span:first-child strong {
    color: #17468f;
}

.drop .drop_contetn .lists li span {
    transition: transform .4s ease-out;
}

.drop .drop_contetn .lists li span:hover {
    transform: translateX(5px);
    text-decoration: none;
}

.drop .drop_contetn ul li {
    font-family: "Open Sans","Helvetica",sans-serif;
    text-transform: uppercase;
}

.drop .drop_contetn ul.lists.aquamarine li.title {
    background-color: transparent;
    border-bottom: none;
    line-height: 15px;
    color: #001e6d;
}

.drop .drop_contetn ul.lists.aquamarine li.title a {
    transition: transform .4s ease-out;
    display: block;
    font-size: 15px;
    color: #001e6d;
    max-width: 400px;
}

.drop .drop_contetn ul.lists.aquamarine li.title a:hover {
    transform: translateX(5px);
    text-decoration: none;
}

.drop .drop_contetn .for_lists .lists.aquamarine li.colored {
    background-color: transparent;
}

/* position fix (sticky) desktop menu */

@media screen and (min-width: 992px) {
    .drop.selected {
        position: fixed;
    }

    .wrapper {
        padding-top: 108px;
    }
}
