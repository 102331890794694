@import '../style/common';

.share {
  &__toggle {
    white-space: nowrap;
  }

  &__toggle-icon {
    color: $accent;
    margin-left: space(2);
  }

  &__menu {
    border: 0;
    width: auto;
    min-width: auto;
    padding: space(1) 0;
  }

  &__action {
    padding: space(1) 0;
    display: inline-block;
    color: $primary;

    @include hover-focus {
      background-color: $white;
      color: $dark;
    }
  }

  &__action-icon {
    color: $dark;
    margin-right: space(2);
    vertical-align: -0.3em;
  }
}
