﻿/****
    SECTION 2 - Forms
****/
/* Mainly for the one on the homepage */

.subscribe-block.subscribe-block--horizontal .subscribe-block__content {
    margin-bottom: 10px;
}

.home .subscribe-block__content .row > div:nth-child(2) {
    width: 100%;
}

@media all and (min-width: 992px) {
    .subscribe-block.subscribe-block--horizontal .subscribe-block__content .row div:nth-child(2) {
        width: 66.66%;
    }
}

.subscribe-block--vertical {
    .dropdown__menu {
        max-height: 12rem;
    }

    .subscribe-block__content:after {
        /*Fix rendering issue with some forms that are displayed vertically. */
        min-height: 15rem;
    }
}

.fused-vertical-form #subscribe-block-collapse .Form__Element, #subscribe-block-collapse .button.submit-container {
    width: 100%;
}

.fused-vertical-form #subscribe-block-collapse {
    margin-top: 2em;
}

/***
    Grouped campaign form (used in NFT page)
*/

.grouped-campaign-from.EPiServerForms .Form__Status .Form__Status__Message {
    padding: 0;
    margin-top: 3rem;
}

/***
    Subscribe Block
*/
/* Right align disclaimer for subscribe block */
@media all and (max-width: 480px) {
    .subscribe-block--horizontal .subscribe-block__disclaimer {
        text-align: left;
    }
}

/* Make input fields full width in tablet. */
@media (min-width: 768px) and (max-width: 991px) {
    .subscribe-block.subscribe-block--horizontal .subscribe-block__content .row div:nth-child(2) {
        width: 100%;
    }
}

.subscribe-block.subscribe-block--horizontal .subscribe-block__content {
    margin-bottom: 10px;
}

.home .subscribe-block__content .row > div:nth-child(2) {
    width: 100%;
}

@media all and (min-width: 992px) {
    .subscribe-block.subscribe-block--horizontal .subscribe-block__content .row div:nth-child(2) {
        width: 66.66%;
    }
}

.ValidationRequired .invalid-feedback {
    display: block;
}

.ValidationFail {
    > {
        input {
            border-color: #e00;
        }

        .dropdown > .dropdown__button {
            border-color: #e00;
            margin-bottom: 0 !important;
        }
    }

    .invalid-feedback {
        display: block;
    }
}


/****
    SECTION 9 - SUBSCRIPTION CENTER PAGE
*/

/* Viewport-wide spinner, used in subscription center pages */

#viewport-wide-spinner {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 10;

    &.hide {
        display: none;
    }
}

//for above and all other spinners
@keyframes spinner-line-fade-default {
    0%, 100% {
        opacity: 0.22; /* minimum opacity */
    }

    1% {
        opacity: 1;
    }
}

/* Subscription Access Form */

body.subscription-center-page {
    .top-Country {
        .dropdown__button {
            position: relative;
        }

        .dropdown__menu {
            min-width: calc(100% - 30px);
            width: calc(100% - 30px);
        }
    }

    .dropdown__button {
        &.mb-3 {
            margin-bottom: 0 !important;
        }

        &.disabled {
            background-color: #f0f3f6;
        }
    }

    div.subscription-center-subscription-access {
        #veSpinnerContainer > #loading > #loadingcontent > #loadingspinner {
            margin: 0;
        }

        .row > div {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .Form__MainBody > section > div {
            &.Form__Element.FormTextbox, &.col-lg-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .FormTextbox label {
            display: none;
        }
    }

    .EPiServerForms .Form__Element .Form__Element__ValidationError {
        color: #e00;
        font-size: .6871875rem;
        font-weight: 600;
        margin-left: 5px;
        margin-top: .25rem;
        text-transform: uppercase;
        width: 100%;
    }
}

/* This page is an exception to the margins of the custom-dropbodown */

/* body.subscription-center-page .dropdown__button.disabled {
    background-color: #f0f3f6;
} */

@media (min-width: 992px) {
    body.subscription-center-page div.subscription-center-subscription-access .invalid-feedback {
        position: absolute;
    }
}

@media (min-width: 992px) {
    body.subscription-center-page div.subscription-center-subscription-access {
        .row > {
            .col-lg-4:nth-child(1) {
                flex: 0 0 25%;
                max-width: 25%;
            }

            div:nth-child(2) {
                flex: 0 0 75%;
                max-width: 75%;
            }
        }

        .Form__MainBody > section > div {
            &.Form__Element.FormTextbox {
                flex: 0 0 58.333333333%;
                max-width: 58.333333333%;
            }

            &.col-lg-6 {
                flex: 0 0 41.666666666%;
                max-width: 41.6666666666%;
            }
        }
    }
}

.subscription-center-page {
    :not(div.subscription-center-subscription-access) .container > form > .Form__Status {
        display: none;
    }

    .your-information__header, .checkbox-group__header {
        background-image: url(/globalassets/home/us/common/abstract-background-tertiary.svg);
    }
}

.manage-subscription .checkbox-group__header {
    background-image: url(/globalassets/home/us/common/abstract-background-tertiary.svg);
}

.subscription-feedback-from .EPiServerForms .Form__Element {
    margin-bottom: 0;
}

/** Aligning checkboxes correctly based on font size */
body.subscription-center-page .checkbox-group__body .custom-checkbox .custom-control-label::before, body.manage-subscription .checkbox-group__body .custom-checkbox .custom-control-label::before {
    margin-top: 3px;
}

body.subscription-center-page .checkbox-group__body .custom-checkbox .custom-control-label::after, body.manage-subscription .checkbox-group__body .custom-checkbox .custom-control-label::after {
    margin-top: 3px;
}

body.manage-subscription .more-info-icon-svg svg, body.subscription-center-page .more-info-icon-svg svg {
    margin-top: -3px;
}

.custom-control-input:checked ~ .custom-control-label::before, .custom-control-label::before {
    border-color: #323232;
}

/* IE 11 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .subscription-feedback-form div.subscribe-reason.FormChoice {
        width: 99%;
    }

    body {
        &.manage-subscription .unsubscribe-reason > .row > .col-lg-6 {
            margin-bottom: 35px !important;
        }

        &.subscription-center-page .Form__ElementInvestorTypeSelectInvestorType ~ .dropdown__menu {
            width: calc(100% - 30px);
        }
    }
}

.manage-subscription .Form__Status {
    display: none;
}

.EPiServerForms .Form__Status .Form__Success__Message {
    background-color: transparent !important;
}

.unsubscribe-page .Form__MainBody > .row {
    padding: 0 15px;
}

.manage-subscription .survey-block {
    .Form__Element, section.FormStep {
        margin-bottom: 0 !important;
    }

    a.stayintheknowlink {
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .unsubscribe-reason .custom-control {
        padding-left: 0;
    }
}

body.manage-subscription .unsubscribe-reason > .row {
    flex-wrap: nowrap;
    flex-direction: column;
}
/*Remove label from manage subscription blocks*/
.manage-subscriptions-block.bs4-nav .Form__MainBody .Form__Element.FormTextbox label {
    display: none;
}


/* ==== End Subscription Center Code  ==== */

/* Webinar thankyou page */

.event-registration.subscribe-block {
    .custom-checkbox.custom-control {
        margin-bottom: 10px;
    }
}

.premium-content-block-full-width {
    .premium-content-block {
        .Form__Success__Message{
            text-align:right;
        }
    }
}

.premium-content-heading {
    font-size: .75rem;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1;
    text-transform: uppercase;
    padding-bottom: 10px;
}

#subscription-access-left-content {
    section.Form__Element > div,
    .sub-title,
    .subscribe-block__content .row div:nth-child(2),
    .left_col .sub-title, .left_col .subscribe-block__content .row div:nth-child(2) {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
    }
}

.premium-content-block {
    #subscription-access-left-content .sub-title,
    #subscription-access-left-content .subscribe-block__content .row div:nth-child(2),
    .left_col .sub-title, .left_col .subscribe-block__content .row div:nth-child(2) {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        .subscribe-block__content .row div:nth-child(2) {
            width: 100% !important;
        }

        section.Form__Element > div {
            padding: 0 15px;
        }
    }

    .Form__Success__Message {
        padding: 10px 15px !important;
    }

    .Form__Success__Message > a.btn-primary {
        max-width: 390px;
        width: 100% !important;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }
    }

    #subscription-access-left-content .sub-title {
        padding-bottom: 20px;
    }

    section.Form__Element > div {
        max-width: inherit;
        min-width: auto;
        width: 100%;
        padding: 0 15px;
    }

    .subscribe-block__content .row div:nth-child(2) {
        width: 66.66%;
    }
}


//Full width

.premium-content-block .subscribe-block__content .row .sub-title > .h3 {
    width: 100%;
}

div:not(.col-lg-3) .premium-content-block .subscribe-block__disclaimer {
    text-align: right;
}

@media (min-width: 768px) {
    div.col-lg-9 .premium-content-block .Form__MainBody > section.Form__Element {
        display: flex;
    }

    .premium-content-block .Form__MainBody > section.Form__Element > .FormInvisibleRecaptcha {
        flex: 0;
    }

    form.premium-content-form {
        padding-top: 0 !important;
    }

    .premium-content-block .subscribe-block__content form.premium-content-form {
        padding-top: 0 !important;
    }

    div.col-lg-3 .premium-content-block .subscribe-block__content form.premium-content-form {
        padding-top: 1rem !important;
    }

    div.col-lg-3 .premium-content-block .row > *, div.col-lg-3 .premium-content-block .premium-content-button {
        min-width: 100%;
        width: 100%;
    }

    div.col-lg-3 .premium-content-block .premium-content-button {
        margin-bottom: 1rem;
    }

    div.col-lg-3 .premium-content-block div.FormTextbox {
        flex: 100%;
    }

    .premium-content-block .Form__Status {
        text-align: right;
    }

    .premium-content-block .Form__Status .Form__Success__Message {
        padding-top: 0;
    }
}

@media (max-width: 768px) {
    .premium-content-block .premium-content-button {
        margin-bottom: 1rem;
    }
}

// AU registration form

.au-registration {
    .CompanyName, .SubscriptionCheckboxes, .FormPassword, .PasswordConfirmation, .Title, .Phone, div.ToggleManualAddress input, div.Email div.InputWithHintContainer div#emailIntroMessage {
        display: none;
    }

    .CertifiedFinancialProfessional {
        visibility: hidden;
    }

    div .ToggleManualAddress label {
        text-decoration: underline;
        cursor: pointer;
        transition: text-decoration .3s ease;
    }

    div.Address1, div.Address2, div.City, div.State, div.ZipCode, div.Country {
        display: none;
    }

    div.Form__Element.FormChoice {
        position: relative;
    }

    .FormChoice__Input--Checkbox {
        position: absolute;
        top: 7px;
    }

    .FormChoice__Label {
        padding-left: 20px;
    }

    .submit-container, .Form__Element.Policy {
        text-align: center;
    }

    form div.Form__MainBody > section {
        background-color: #f0f3f6;
        background-image: url(/globalassets/home/us/common/share-form-bg.svg);
        background-position: 64% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid #e6e7e8;
        padding: 1.5rem 1rem;
    }
}

@media (min-width: 992px) {
    .au-registration {
        .Form__Element {
            &.FormTextbox, &.FormSelection, &.Form__StateContainer {
                width: 50%;
            }
        }
    }
}

