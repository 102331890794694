@import '../style/common';

.thought-leaders-block {
  @include media-breakpoint-down(md) {
    &__item {
      margin-bottom: 1.875rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
