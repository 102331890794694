﻿.alert-bar.alert-bar-event {
    // Items below apply to the EVENT version of the Alert bar only!
    // Any change here need to be updated on: C:\web_src\websites_public\VanEck.Web\Static\css\header-footer\override.css

    background-color: rgba(0,200,5,1);
    background-image: initial;
    background-image: unset;
    overflow: hidden;
    position: relative;
    z-index: 400;


    &:before, .alert-bar__content:before {
        background-color: rgba(0,0,0,0.04);
        content: " ";
        position: absolute;
        right: -300px;
        display: block;
        height: 800px;
        width: 800px;
        transform: rotate(-60deg);
        margin-top: -30vh;

        @include media-breakpoint-down(xl) {
            right: -468px;
        }

        @include media-breakpoint-down(md) {
            right: -555px;
        }
        //Remove all overlays on the desktop to make it clickable.
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .alert-bar__content:before {
        background-color: rgba(0,0,0,0.08);
        right: -200px;

        @include media-breakpoint-down(xl) {
            right: -368px;
        }

        @include media-breakpoint-down(md) {
            right: -455px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .alert-bar__left-label, .alert-bar__ctalink {
        color: #fff;
    }

    .alert-bar__description {
        white-space: nowrap;
        color: #fff;
    }

    .alert-bar__ctaright {
        position: relative;
        right: 0;
        z-index: 500;
    }

    a {
        text-decoration-color: #fff;
    }

    .alert-bar__left-label:hover .alert-bar__description {
        margin-left: 10px;
        transition: 0.2s margin-left;
    }

    .alert-bar__title {
        font-weight: bold;
    }
}
