@import '../style/common';

.find-an-etf {

  .nav.nav-tabs {
    display: none;
  }
  .nav.nav-tabs.show {
    display: block;
  }

  .nav-link.active {
    background-color: $blue;
  }

  &__title {
    @include make-heading(2);
    margin-bottom: space(3);
  }

  &__select-block, &__content {
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__select-block {
    color: $white;
    background-color: $tertiary;
    background-image: url('./assets/images/find-an-etf-main-background.svg');
    padding: space(4);
    background-position: 35% 96%;
  }

  &__content {
    background-image: url('./assets/images/find-an-etf-content-background.svg');
    background-position: 23% 0%;
  }

  &__dropdown-container {
    display: flex;
    align-items: center;
  }

  &__dropdown-action-text {
    text-transform: uppercase;
    font-size: $font-size-sm;
    margin-right: space(3);
    flex: 0 1 auto;
  }

  &__dropdown {
    flex: 1 1 auto;
  }

  &__content-title {
    @include make-heading(3);
  }

  &__content-title, &__content-body {
    margin-bottom: space(4);
  }

  .tab-content > .tab-pane {
    display: block;
    visibility: hidden;
    height: 0;
    padding: 0 space(4);
  }

  .tab-content > .active {
    visibility: visible;
    height: auto;
  }

  .tab-pane.active {
    padding: space(4);
    border: $border-width solid $tertiary;
  }
}
