@import '../style/common';

.text-hero {
  position: relative;

  &__follow-widget {
    &-icon-label {
      display: block;
      font-size: $text-util-size-sm;
      font-weight: $text-util-font-weight;
    }
  }

  &__date {
    font-size: $font-size-lg;
  }

  &__body {
    font-size: $font-size-lg;
    margin-top: space(3);
    color: $gray-6;
    max-width: 990px; // 75% of container width;
  }

  &__divider {
    width: 100%;
    height: $border-width;
    background-color: $gray-2;
    margin-top: 1.25rem;
  }
}
