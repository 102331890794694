@import '../style/common';

.fund-explorer-block {
  padding: space(3);
  border-left: space(1) solid $blue;
  box-shadow: 0 2px space(1) 0 rgba($gray-5, 0.5);
  display: inline-block;
  background-color: $white;
  text-align: left;

  &__rating-description,
  &__info-title {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
  }

  &__rating-in-words,
  &__info-description {
    font-size: $font-size-xs;
  }

  &__info-title,
  &__info-description {
    flex: 1;
  }

  &__details {
    font-size: $font-size-xxxs;
    color: $gray-4;
  }
}
