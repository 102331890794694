@import '../style/common';

.register-form {
  &__dialog {
    max-width: 790px;

    @include media-breakpoint-down(md) {
      max-width: none;
    }
  }

  &__modal-body {
    padding: space(5) space(4);
  }

  &__body {
    font-size: $font-size-lg;
  }

  &__eyebrow {
    width: 90%;
  }

  &__close {
    position: absolute;
    top: space(4);
    right: space(4);

    &.close-icon {
      @include link-focus-outline;
    }
  }

  /*&__content {
    background-color: $light;
    background-image: url('#{$images-path}form-bg.svg');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
  }*/

  &__left-container {
    border-right: $border-width solid $gray-2;
  }

  &__badge {
    border-radius: 0;
    background-color: $purple;
    color: $white;
    letter-spacing: $text-util-sm-letter-spacing;
    font-weight: $font-weight-semi-bold;
  }

  &__date,
  &__time {
    font-weight: $display1-weight;
  }

  &__description {
    font-weight: $display1-weight;
  }

  &__section-title {
    font-size: $h4-font-size;
    list-style: $h2-line-height;
  }

  &__disclaimer {
    font-size: $font-size-xxs;
  }

  .dropdown__button-default:disabled {
    background-color: $gray-1;
    pointer-events: none;
  }

  @include media-breakpoint-down(md) {
    &__outer-container,
    &__inner-container {
      text-align: left;
    }
  }
}
