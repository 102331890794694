@import '../style/common';

.register-cta {
  &__cta-wrapper {
    width: 16rem;
  }

  &__content {
    border-right: $border-width solid $gray-2;
  }

  &__badge {
    border-radius: 0;
    background-color: $purple;
    color: $white;
    letter-spacing: $text-util-sm-letter-spacing;
    font-weight: $font-weight-semi-bold;

    @media print {
      color: $gray-5;
      background-color: $white;
    }
  }

  &__date,
  &__time {
    font-weight: $display1-weight;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }

  &__description {
    font-weight: $display1-weight;
  }

  @include media-breakpoint-down(md) {
    &__cta-wrapper {
      padding-top: space(4);
    }
  }

  @include media-breakpoint-down(sm) {
    &__content {
      border-width: 0;
    }
  }

  &__register-label {
    font-size: $font-size-md;
    letter-spacing: $letter-spacing-base;
    font-weight: $font-weight-semi-bold;
  }
}
