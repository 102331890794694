@import '../style/common';

.recommended-insights {
  $recommended-insights-carousel-control-size: 2.1875rem;

  background-color: $light;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: space(5) 0;
  position: relative;

  .container {
    position: relative;
  }

  &__heading {
    margin-bottom: space(4);
  }

  .slick-track {
    display: flex;
  }

  .slick-disabled {
    background-color: $gray-3;
    pointer-events: none;
  }


  .slick-slider {
    position: relative;
  }

  .slick-slide {
    height: auto;

    > div {
      height: 100%;
    }
  }

  .tab-content {
    position: relative;
  }

  .tab-content > .tab-pane {
    visibility: hidden;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .tab-content > .active {
    visibility: visible;
    position: relative;
  }

  &__list {
    margin: 0 $grid-gutter-width / -2;
    padding-bottom: $recommended-insights-carousel-control-size;
  }

  &__list-item {
    padding: 0 $grid-gutter-width / 2 space(4);
    height: 100%;
  }

  &__link {
    height: 100%;
  }

  &__carousel-control {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-right: $grid-gutter-width / 2;
    background-color: $dark-blue;
    color: $white;
    padding: space(1);
    height: $recommended-insights-carousel-control-size;
    width: $recommended-insights-carousel-control-size;
    transition: $btn-transition;

    &--prev {
      right: $recommended-insights-carousel-control-size;
    }

    &--next {
      background-color: $dark-blue;
    }
  }

  &__counts {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 5.25rem;
    height: $recommended-insights-carousel-control-size;
    padding: 0 space(2);
    background-color: $gray-1;
    display: flex;
    align-items: center;
    justify-content: center;

    b, span {
      padding: 0 space(1);
    }
  }
}
