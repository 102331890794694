﻿/****
    Tabs
*/
/* Mobile view styling */
@media all and (max-width: 767px) {
    ul.tab-nav__list.nav.nav-pills {
        flex-wrap: nowrap;
        width: 100vw;
        overflow-x: auto;
        margin-left: -15px;
        margin-right: -15px;
    }

    li.tab-nav__list-item.nav-item {
        min-width: 120px;
        text-align: center;
        min-height: 60px;
    }

    a.tab-nav__link.nav-link {
        padding: 0.5rem 1rem;
        height: 100%;
    }
}

/***
    HERO
*/
.hero {
    &.text-white {
        a {
            color: white;
        }
    }

    /* larger than the default subtitle */
    .caps-subtitle {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 700;
    }

    .description-large {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 32px;
        font-weight: 400;
    }

    &.reduced-padding {
        padding-top: 4rem;
        padding-bottom: 4.5rem;
    }
}

.hero.hero__video {
    overflow: hidden;

    video.hero__bg {
        min-width: 100%;
        min-height: 100%;
    }
}

.hero__content-container-full-width {
    min-height: 150px;
    max-width: 100%;
    color: #fff;
}

// Shift hero image to bring either top or bottom into focus
.hero.top-focus .hero__bg {
    background-position-y: 10%;
}

.hero.bottom-focus .hero__bg {
    background-position-y: 90%;
}

// Shift hero image to bring either left or right side into focus on mobile screens
@media (max-width: 576px) {
    .hero.left-focus .hero__bg {
        background-position-x: 40%;
    }

    .hero.right-focus .hero__bg {
        background-position-x: 60%;
    }
}

/****
    ALERT BAR
*/
.alert-bar {
        &__date {
            white-space: nowrap;

            @media (max-width: 767px) {
                width: 100%;
                display:block;
            }
        }
}


/****
   ATTACHMENT BLOCKS
*/

.attachment-block.dark {
    
    
    .attachment-block__content {
        padding: 1.5rem 2.5rem;
    }
}


/****
    ACCORDIONS
*/
.accordion.heading-h3 .accordion__label.h2 {
    font-size: 1.186875rem;
    line-height: 1.1315;
}

.accordion .accordion__toggle:hover, .accordion__toggle:focus {
    text-underline-offset: 0.4rem; /*FF only*/
}


/****
     Search page
*/

.page-class-search .page-item:first-child .page-link {
    border-radius: 50%;
}

@media (min-width: 576px) {
    .search__container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .search__container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .search__container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .search__container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .search__container {
        max-width: 1320px;
    }
}

@media (min-width: 992px) {
    .search__container {
        padding-right: 3rem;
        padding-left: 3rem;
    }
}






/****
     SECTION 15 - Accordia Flow Not sure which page this is used in!
*/
.flow-area-wrapper {
    border: 1px solid #7D868C;
    padding: 26px 29px;

    h2 {
        color: #323232;
        font-weight: 500;
        padding-bottom: 4px;
    }

    h3 {
        color: #323232;
        font-weight: 400;
        letter-spacing: 0;
        font-size: 18px;
    }

    .accordian-flow-wraper {
        position: relative;

        .flow-wrapper {
            padding-top: 22px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            > div {
                padding: 19px 25px 20px 25px;
                width: 25%;
                position: relative;
                border-right: 2px solid #990099;

                &:after {
                    content: "";
                    width: 19px;
                    height: 19px;
                    background: #ffffff;
                    border-top: 2px solid #990099;
                    display: block;
                    border-right: 2px solid #990099;
                    position: absolute;
                    top: 25px;
                    right: -11px;
                    z-index: 3;
                    transform: rotate(58deg) skew(30deg);
                }

                &:last-child {
                    border: none;

                    &:after {
                        display: none;
                    }
                }

                &:first-child {
                    padding: 19px 25px 20px 0px;
                }

                &:last-child {
                    padding: 19px 0px 20px 25px;
                }
            }
        }
    }
}

.accordian-flow-wraper .flow-wrapper .flow-item {
    h4 {
        font-size: 22px;
        margin-bottom: 2px;
    }

    .text-content {
        line-height: 18px;
        margin-top: 5px;
        letter-spacing: 0;
        font-size: 15px;

        strong, a {
            color: #17468F;
        }
    }
}

.client-logos img {
    width: 100%;
    padding-top: 10px;
}

@media screen and (max-width: 768px) {
    .flow-area-wrapper .accordian-flow-wraper .flow-wrapper > div {
        width: 100%;
        padding-left: 0 !important;
        border-right: none;
        border-bottom: 2px solid #990099;

        &:after {
            top: auto;
            right: auto;
            bottom: -11px;
            left: 25px;
            transform: rotate(143deg) skew(17deg);
        }
    }
}

/* Additonal Link farm styles */

.link-farm__inner-container .cta-link:hover {
    text-decoration: underline;
    transform: none;
}

.link-farm__inner-container .text-util-md {
    text-transform: none;
}

.distributions-tax-center .link-farm__inner-container .text-util-md {
    font-weight: bold;
    color: #17468F;
    letter-spacing: 0;
    line-height: 16px;
}

/* Additonal accordion styles */
.accordion__toggle_subheading {
    font-size: 12px;
}


@media (max-width: 450px) {
    .find-an-etf .fund-carousel-block__heading {
        margin: 0;
    }
}

.col-lg-6 .link-farm__inner-container .text-util-md {
    text-transform: none;
}

.col-lg-6 .link-farm__inner-container .cta-link.link-farm__link:hover {
    text-decoration: underline;
}


/****
    Section - Carousel Block Rectification
*/
.carousel-block__main.carousel-block__main--desktop .slick-slide > div {
    height: 100%;
    margin-right: 1.5rem;
}

.related-insights .carousel-block__main.carousel-block__main--desktop .slick-slide > div,
.related-insights .carousel-block__main.carousel-block__main--desktop .slick-slide > div > div {
    height: 100%;
    margin-right: 0rem;
}

.carousel-block__main.carousel-block__main--desktop .slick-slide > div .insight-card.carousel-block__item {
    height: 100%;
}

.slick-dots li button:before {
    content: "•";
 }

//fix issue with misaligned left and right arrows on carousels which are anchored blocks
.anchored-block .carousel-block__control {
    top: calc(12rem + 100px)
}

/**
    WEBP-4180 extra space above the footer in IE
*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #root{
        display:block;
    }
}
/** Education page -- issue with the fund carousel having an extra wrapping div */

.find-an-etf__content-carousel .slick-slide > div {
    width: calc(100% - 30px);
}

/*
    Section WEBP-4096 - Shadow on all drop downs
*/
.dropdown-menu {
    border: 1px solid #939598;
    border-top: none;
}

.dropdown.show .dropdown__button {
    border: 1px solid #939698;
}

/*Drop down styles if webinar registration form select opens upwards*/
.us-webinar-page .dropdown__menu.show[x-placement="top-start"] ~ .dropdown__button,
.find-an-etf__dropdown .dropdown__menu.show[x-placement="top-start"] ~ .dropdown__button,
.subscribe-block .dropdown__menu.show[x-placement="top-start"] ~ .dropdown__button {
    border-radius: 0 0 .3125rem .3125rem;
    border-top: none;
}

.us-webinar-page .dropdown .dropdown__menu.show[x-placement="top-start"],
.find-an-etf__dropdown .dropdown .dropdown__menu.show[x-placement="top-start"],
.subscribe-block .dropdown .dropdown__menu.show[x-placement="top-start"]
{
    border-radius: .3125rem .3125rem 0 0;
    border-top: 1px solid #939598;
    border-bottom: none;
}
.find-an-etf__dropdown .dropdown-menu.dropdown__menu.show, 
#countryFlag.dropdown.country-united-states .dropdown-menu.dropdown__menu.show {
    right: 0px;
}

.EPiServerForms .Form__Element .Form__Element__Caption{
    vertical-align: baseline !important;
}
.subscribe-block .location-dd .dropdown-menu.dropdown__menu {
    width: 100%;
}
.subscribe-block .dropdown .dropdown-menu.dropdown__menu.show{
    /*min-width: calc(100% - 1px);*/
}
/*
    .subscribe-block .dropdown .dropdown__menu.show[x-placement="top-start"],
    .subscribe-block .dropdown__menu.show[x-placement="top-start"] ~ .dropdown__button
*/

/** Register From*/
.register-form__description {
    font-weight: normal;
}

/* WEBP-5269 Modified Callout Card styles*/

.callout-top-border {
    &.callout__content-item:before {
        content: none;
    }

    .callout__content {
        border-top: 3px solid #A200B5;
        padding-bottom: .5rem;
        padding-left: .5rem;
        padding-top: 1rem;
    }
}

/* Styles for the Schedule a Call */

.custom-page-content {
    .jumbotron {
        .jumbotron__cta {
            svg {
                width: 38px;
                height: 22px;
                padding-left: 10px;
                line-height: 1em;
                font-size: 1.8rem;

                path {
                    fill: #ffffff;
                }
            }

            margin: 0;

            .cta-link__text {
                font-size: 1.3rem;
                line-height: 1.25;
                text-transform: none;
                letter-spacing: inherit;
                display: inline;
                margin-bottom: 0px;
            }
        }

        .jumbotron__quote {
            display: none;
        }

        .jumbotron__lead {
            display: none;
        }

        .jumbotron__body {
            display: none;
        }
    }
}

@media (min-width: 992px) {
    .jumbotron {
        padding: 3rem 6rem;
    }
}

@media (min-width: 768px) {
    .custom-page-content {
        .jumbotron {
            .jumbotron__cta {
                .cta-link__text {
                    font-size: 1.88rem;
                    font-weight: 600;
                    letter-spacing: -0.55px;
                    line-height: 1.4em;
                    margin-bottom: 0;
                }

                svg {
                    line-height: 1.1315em;
                    font-size: 2rem;
                    height: 40px;
                }
            }
        }
    }
}

@media (min-width: 425px) {
    .custom-page-content {
        .jumbotron {
            .jumbotron__cta {
                .cta-link__text {
                    display: inline;
                    float: left;
                }

                svg {
                    display: inline;
                    float: left;
                }
            }
        }
    }
}

@media all and (max-width: 450px) {
    .custom-page-content {
        .jumbotron {
            .jumbotron__cta {
                .cta-link__text {
                    font-size: 1.2rem;
                }

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

@media all and (max-width: 400px) {
    .custom-page-content {
        .jumbotron {
            .jumbotron__cta {
                .cta-link__text {
                    font-size: 1.4rem;
                }

                svg {
                    margin-top: -4px;
                }
            }
        }
    }
}


/*Tab contents*/

.content-area-tab {
    .tab {
        background: #f0f3f6;
        overflow: hidden;
        border: 0px solid #ccc;
        padding-top: 15px;


        @media screen and (max-width:768px) {
            overflow-x: scroll !important;
            display: flex;

            .tablink {
                width: unset;
            }
        }
    }

    .tabcontent {
        padding: 6px 12px;
        border: 0px solid #ccc;
        border-top: none;

        .tabcontent-img-element {
            margin-right: 25pt;
        }
    }

    .tabcontent-child-elements {
        display: flex;
        align-items: center
    }
    /****/
    /* Style the tab */ .tab {
        background: #F0F3F6;
        overflow: hidden;
        border: 0px solid #ccc;
        padding-top: 15px;
    }
    /* Style the buttons inside the tab */

    button.tablinks:hover {
        background-color: #545e6b;
    }

    .tab button {
        width: 20%;
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        font-size: 17px;
        background-color: #545e6b;
        border-radius: 0;
        color: #fff;
        font-size: .6871875rem;
        font-weight: 600;
        padding: 1.5rem 3rem;
        text-transform: uppercase;
    }
    /* Change background color of buttons on hover */

    .tab button:hover {
        background-color: #40444c;
    }
    /* Create an active/current tablink class */

    .tab button.active {
        background-color: #ccc;
        background-color: #fff;
        color: #323232;
    }
    /* Style the tab content */

    .tabcontent {
        padding: 6px 12px;
        border: 0px solid #ccc;
        border-top: none;
    }
}

/*After changing the accordian icon size to 2em - DIG42-11*/
.accordion__icon{
    top:0.5em;
}

/*WEBP-5360*/
.insurance-ticker-wrapper {
    .fund-card {
        &__cta {
            position: relative;

            svg {
                position: absolute;
                height: 1.2rem;
                width: 1.2rem;
                bottom: 1px;
            }
        }
    }
}
/*WEBP-5442*/
.remove-extra-space.anchored-block{
    visibility:hidden;

    div, section, h2{
        visibility:visible;
    }
}

.site-header {
    .navbar-toggler {
        svg {
            pointer-events: none;
        }
    }
}