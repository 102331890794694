﻿ve-compound-hero {
    .component-loading {
        text-align: center;
        padding-top: 150px;
        padding-bottom: 150px;
        position: relative;

        &:after {
            content: " ";
            background-image: url(/globalassets/home/us/common/ve-loading.gif);
            position: absolute;
            height: 150px;
            width: 150px;
            top: calc(50% - 75px);
            bottom: calc(50% - 75px);
            left: calc(50% - 75px);
            right: calc(50% - 75px);
        }
    }
}

ve-compound-hero {
    display: block;

    > .component-loading {
        display: none;
    }
}

.hero__bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
}

/****
    SECTION 3 - Homepage Style changes
****/
.home-page {

    .compound-hero__carousel.carousel-loading {
        min-width: 100%;
    }
    
    .compound-hero__heading.h1.display-1 {
        font-size: 2.25rem;
        font-weight: 725;
        line-height: 1.25;
    }

    .compound-hero__main {
        .btn-theme-green.focus, .btn-theme-green:focus, .btn-theme-green:hover {
            background-color: #fff;
            border-color: #007703;
            color: #000;
        }
    }

    .compound-hero__carousel-explore {
        padding-left: 1.5rem;
    }

    .compound-hero__carousel-main {
        border-bottom: 0px;
    }

    .compound-hero__carousel-item {
        border-bottom: 1px solid rgba(147, 149, 152, 0.27);
        padding: 1.5rem 0 0;
        margin: 0 1.5rem;
    }

    .slider-for:not(.slick-initialized) > .compound-hero__slide {
        /*display: none;*/

        &:nth-child(1) {
            visibility: visible;
            display: block;
        }
    }

    .promo-banner {
        background-size: cover;
        background-position: bottom;

        @media (min-width: 450px) and (max-width: 1199.9px) {
            background-position-x: right;
        }
    }

    .capabilities-insight-card__heading.card-header a {
        color: #fff;
    }

    /**New styles for the hero banner*/
    .compound-hero__aside {

        &.mobile-block {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}


/** Disable rendering of other slick slides on non-mobile -- they are not needed */
@media (min-width: 992px) {
    .home-page {
        .compound-hero__aside.mobile-block {
            display: none;
        }
    }

    ve-compound-hero {
        flex: 1;
        display: flex;
        justify-content: end;
    }
}

@media (max-width:991.98px) {
    .home-page {
        .compound-hero__aside.desktop-block {
            display: none;
        }

        .compound-hero__aside.mobile-block {
            display: block;

            .compound-hero__carousel-main {
                color: white;
            }
        }

        .compound-hero-new {
            margin-bottom: 2rem;
        }
    }
}

@media (max-width:575.98px) {
    /*.home-page .slider-for .slick-slide > div {
        height: 100%
    }*/
    .home-page .compound-hero__bg--overlay {
        background-position: 30%;
    }

    .home-page .compound-hero__slide {
        min-height: 320px;
        display: flex;
        align-items: center;
    }

    .home-page {
        .compound-hero__main {
            .compound-hero__cta-wrapper {
                margin-bottom: 0rem;
            }

            .compound-hero__heading {
                margin-bottom: 2rem;

                .compound-hero__eyebrow {
                    font-size: 13px;
                    line-height: 18px;
                    color: #F0F3F6;
                }

                a {
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 700;
                }
            }

            .compound-hero__cta.btn {
                /*min-width: 250px;*/
                line-height: 17px;
            }
        }

        .compound-hero__carousel {
            .text-util-sm {
                font-size: 11px;
                line-height: 15px;
            }

            &-body {
                font-size: 16px;
                line-height: 20px;
            }

            &-explore {
                line-height: 17px;
            }
        }
    }
}



/* Safari fix for scrollbars */
_::-webkit-full-page-media, _:future, :root .home-page .ticker-nav__content-inner {
    overflow-x: scroll;
}

_::-webkit-full-page-media, _:future, :root .home-page .ticker-nav__content-inner::-webkit-scrollbar {
    display: inherit;
    height: 10px;
}

_::-webkit-full-page-media, _:future, :root .home-page .ticker-nav__content-inner::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.4);
    border-radius: 8px;
    -webkit-border-radius: 8px;
}

_::-webkit-full-page-media, _:future, :root .home-page .ticker-nav__content-inner::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(100,100,100,0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

_::-webkit-full-page-media, _:future, :root .home-page .ticker-nav__content-inner {
    overflow: none;
    overflow-x: none;
    overflow-x: scroll;
}

/* TICKER NAV **/
/* Mobile fixes */
@media all and (max-width: 480px) {
    /* Prevent whitespace from wrapping */
    .ticker-nav__content > a.cta-link.ticker-nav__cta {
        white-space: nowrap;
    }
}

/* Ticker list to be one row with sticky date */

.ticker-nav .tab-content {
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 13px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.5);
        border: 2px solid #fff;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
    }

    > .active {
        display: flex;
    }

    .ticker-nav__content {
        max-width: 100%;
        width: 100%;
    }

    .ticker-nav__timestamp {
        position: sticky;
        left: 10px;
        width: 180px;
        margin-left: 2rem;
    }

    .ticker-nav__column {
        min-width: 14rem;
        max-width: 100px;
    }

    .ticker-nav__item {
        padding: 0.25rem 2rem;
    }

    .ticker-nav__set {
        border-right: 1px solid #bbb;

        &:last-child {
            border-right: none;
        }
    }
}

/****
    SECTION 8 - Home Jumbotron page
****/
.no-quote .jumbotron__quote:before {
    display: none;
}


/****
     SECTION 14 - SEO
*/
.hm-h2 {
    font-size: 0.9375rem;
    line-height: 1.666;
    margin: 0;
}

@media (min-width: 768px) {
    .hm-h2 {
        font-size: 0.9375rem;
    }
}

.hm-h3 {
    margin-bottom: 0;
    font-size: 1.0621rem;
}

@media (min-width: 768px) {
    .hm-h3 {
        font-size: 1.186875rem;
        line-height: 1.1315;
    }
}

.hm-blog-title {
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.666;
}

.main-top-menu__item ul {
    list-style: none;
    padding-left: 0;
}

.site-header__mobile-menu-action-inner {
    display: block;
}

.compound-hero__eyebrow span {
    display: block;
}

/*Promo Banner Styles - WEBP-4414*/
.promo-banner-hp {
    .promo-banner {
        padding: 2rem 3rem;

        &__heading {
            font-size: 2rem !important;
            line-height: 35px;
        }

        &__subheading {
            font-size: 1.5rem !important;
            line-height: 1;
            padding-top: 12px;
        }

        .cta-link {
            font-size: 1.125rem !important;
            text-transform: uppercase;
            position: relative;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 2.25px;

            &__icon {
                color: #fff;
                transform: scale(1.25);
                position: absolute;
                bottom: -5px;
            }
        }

        &__disclaimer-text {
            color: white;
            font-size: 0.875rem;
            line-height: 23px;
            font-weight: 400;
            margin-bottom: 0;
        }

        .promo-banner-link, .promo-banner-disclaimer {
            margin-top: 34px;
        }

        @include media-breakpoint-down(lg) {
            &__heading {
                font-size: 1.75rem !important;
                line-height: 34px;
            }

            &__subheading {
                font-size: 1.25rem !important;
                line-height: 24px;
                padding-top: 10px;
            }

            &-link .cta-link--white {
                .cta-link__text {
                    font-size: 1rem !important;
                    line-height: 17px;
                    letter-spacing: 2px;
                }

                .cta-link__icon {
                    bottom: -5px;
                    color: #fff;
                    position: absolute;
                    transform: scale(1.33);
                }
            }

            &__disclaimer-text {
                line-height: 23px;
                font-weight: 400;
                letter-spacing: 0;
                margin-bottom: 0.4rem;
            }

            .promo-banner-link, .promo-banner-disclaimer {
                margin-top: 18px;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .promo-banner {
            padding: 2rem;
        }
    }
}

/*Style Updates with the fund dropdown*/
.ticker-nav {
    .home-fund-dropdown {
        .section-header__dropdown {
            width: 200px;

            .dropdown__button-condensed + .dropdown-menu {
                top: 36px !important;
            }
        }

        .dropdown-main-header {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            line-height: 1.2;
            margin: 0.5rem 0px;
            /*padding-right: 0.5rem;*/
            /*border-right: 2px solid black;*/
        }
    }

    .fund-widget {
        &.tabs__content {
            padding-top: 0px;
        }

        @media (min-width: 768px) {
            .ticker-nav__cta {
                margin: 0.5rem 0;
            }
        }
    }
}

//Video hero banner
.compound-hero-new {
    .compound-hero__slide {
        .compound-hero__bg {
            overflow: hidden;

            video {
                margin: auto;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

// CLS fixes for performance - min heights for components that take time to load
.home-page {
    .ticker-nav {
        min-height: 281px;
    }

    ve-compound-hero {
        min-height: 325px;
    }

    ve-hybrid-insights-grid-block {
        display: block;
        min-height: 895px;
    }
}

