@import '../style/common';

.callout {
  &__content-item {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 2px;
      background-color: $accent;
      left: $grid-gutter-width / 2;
      top: 0;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: space(4);
    }
  }

  &__content {
    padding-left: space(4);
    padding-right: space(5);
    padding-bottom: space(2);
  }
}
