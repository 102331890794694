@import '../style/common';


.site-header {
    $site-header-switch-breakpoint: lg;
    $site-header-height: $site-header-height;

    background-color: $white;
    padding-top: space(2);
    box-shadow: 0 0.313rem 0.625rem 0 rgba($gray-6, 0.2);

    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    .navbar {
        position: static;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-brand {
        margin-bottom: space(2);
    }

    &__nav-link {
        font-size: $text-util-size-md;
        letter-spacing: $text-util-md-letter-spacing;
        font-weight: $text-util-font-weight;
        text-transform: uppercase;
        line-height: 1;
        position: relative;

        &:after {
            display: none;
            content: '';
            position: absolute;
            top: 100%;
            right: $navbar-nav-link-padding-x;
            left: $navbar-nav-link-padding-x;
            height: 3px;
            background-color: $transparent;
        }

        @include media-breakpoint-up($site-header-switch-breakpoint) {
            text-align: center;

            &:after {
                display: block;
            }
        }

        @include hover-focus {
            &:after {
                background-color: $primary;
            }
        }

        &.active {
            &:after {
                background-color: $primary;
            }
        }
    }

    .nav-item:hover > .nav-link,
    .nav-item.open > .nav-link {
        &:after {
            background-color: $primary;
        }
    }

    .nav-item {
        @include media-breakpoint-up($site-header-switch-breakpoint) {
            &:hover:after,
            &.open:after {
                content: '';
                position: absolute;
                top: 100%;
                right: 0;
                left: 0;
                min-height: 3rem;
                margin-top: -1rem;
            }

            @include hover-focus {
                .site-header__submenu {
                    display: block;
                }
            }

            &.open > .site-header__submenu {
                display: block;
            }
        }
    }

    &__mobile-menu-toggle {
        position: relative;
        z-index: 1;
    }

    &__toggle-icon {
        position: relative;
        z-index: -1;

        &--open {
            display: none;
        }

        &--close {
            display: block;
        }

        .collapsed &--open {
            display: block;
        }

        .collapsed &--close {
            display: none;
        }
    }

    &__submenu {
        @include padding(space(4) null);
        background-image: url('#{$images-path}submenu-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right center;
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        background-color: $white;
        z-index: $zindex-dropdown;
        min-height: 29rem;
        box-shadow: 0 0.3125rem 0.625rem 0 $black-20;

        &:hover {
            display: block;
        }
    }

    &__mobile-menu {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        overflow: auto;
        background-color: $white;
        height: calc(100vh - #{$site-header-height});
        z-index: $zindex-dropdown;

        &.show {
            display: block;

            @include media-breakpoint-up($site-header-switch-breakpoint) {
                display: none;
            }
        }
    }

    &__mobile-menu-inner {
        position: relative;
        min-height: 100vh;
        width: 100%;
    }

    &__mobile-submenu {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $white;
        z-index: $zindex-dropdown + 1;

        &.show {
            display: block;

            @include media-breakpoint-up($site-header-switch-breakpoint) {
                display: none;
            }
        }
    }

    &__mobile-sub-submenu-toggle {
        position: relative;
        width: 100%;
    }

    &__mobile-menu-action-inner {
        text-align: left;
        position: relative;
        pointer-events: none;
        width: 100%;
        max-width: 100%;
        white-space: nowrap;
    }

    &__mobile-submenu-icon-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        pointer-events: none;
    }

    &__mobile-sub-submenu {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        min-height: 100%;
        width: 100%;
        background-color: $white;
        z-index: $zindex-dropdown + 2;

        &.show {
            display: block;

            @include media-breakpoint-up($site-header-switch-breakpoint) {
                display: none;
            }
        }
    }

    &__mobile-menu-icon,
    &__mobile-submenu-icon {
        pointer-events: none;
        max-width: 2em;
        color: $dark-blue;
    }

    &__mobile-submenu-open {
        display: block;
        width: 100%;
        font-size: $display2-size;
        font-weight: $display2-weight;
    }

    &__mobile-submenu-action {
        @include make-heading(4);
        @include padding(space(2) null);
        width: 100%;
        color: $gray-4;
        position: relative;
        z-index: 0;
    }

    &__mobile-submenu-close,
    &__mobile-sub-submenu-close {
        @include make-text-util(lg);
        width: auto;
        text-align: left;
    }

    &__mobile-sub-submenu-open &__mobile-submenu-icon {
        opacity: 0.4;
    }

    &__mobile-submenu-icon {
        pointer-events: none;
    }

    &__submenu &__submenu-inner {
        display: block;
        position: relative;
    }

    &__submenu-link-text {
        display: inline-block;
        margin-right: space(4);
    }

    &__submenu-icon-wrapper {
        color: rgba($gray-4, 0.4);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: space(1) 0 space(1) space(2);
        max-width: 2rem;
    }

    &__submenu-link-icon {
        color: $dark-blue;
        opacity: 0;
    }

    .list-group,
    .list-group-item {
        border: 0;
        border-radius: 0;
    }

    &__submenu-content:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: $border-width * 2;
        background-color: $border-color;
    }

    .list-group-item {
        padding: space(1) space(2);
        margin-bottom: space(2);
        position: relative;
        background-color: $transparent;
        color: $gray-4;
        text-transform: none;
        font-weight: $font-weight-bold;

        &.active {
            background-color: $transparent;
            color: $primary;

            .site-header__submenu-icon-wrapper {
                color: $dark-blue;
            }

            .site-header__submenu-link-icon {
                opacity: 1;
            }
        }

        @include hover-focus {
            background-color: $transparent;
            color: $primary;

            .site-header__submenu-icon-wrapper {
                color: $dark-blue;
            }

            .site-header__submenu-link-icon {
                opacity: 1;
            }
        }
    }

    .navbar-toggler {
        margin-left: space(3);
        display: inline-block;
        border: 0;
        vertical-align: middle;
    }

    &__side-drawer {
        &.fade .modal-dialog {
            margin: 0 0 0 auto;
            height: 100%;
            max-width: 390px;
            transform: translate(100%, 0);
        }

        &.fade.show .modal-dialog {
            transform: none;
        }

        .modal-dialog {
            &:after {
                content: '';
                position: absolute;
                width: $grid-gutter-width / 2;
                top: 0;
                bottom: 0;
                left: 100%;
                background-color: $white;
            }
        }

        .modal-content {
            height: 100%;
        }

        .modal-body {
            padding: space(5) space(3);

            @include media-breakpoint-up(md) {
                padding: space(5);
            }
        }

        &-toggle {
            position: absolute;
            top: space(2);
            right: space(3);
        }
    }

    &__search {
        .modal-dialog {
            margin: space(6) 0 0;
            width: 100%;
            max-width: 100%;
        }

        .modal-content {
            background-color: $transparent;
            color: $white;
        }
    }
}

@media print {
    .sticky-wrapper {
        display: none;
    }
}
