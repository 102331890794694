@import '../style/common';

.dropdown {
  &__button {
    background-color: $dropdown-bg;
    color: $dropdown-color;
    display: block;
    width: 100%;
    text-align: left;
    transition: $btn-transition;

    &-default {
      border: $border-style;
      border-radius: $dropdown-border-radius;
      padding: $dropdown-item-padding-y 2.5rem $dropdown-item-padding-y $dropdown-item-padding-x;

      @include hover-focus {
        background-color: $light;
      }
    }

    &-condensed {
      padding: space(2) 0;

      + .dropdown-menu {
        /* stylelint-disable-next-line declaration-no-important */
        top: space(2) !important;
        border-radius: $dropdown-border-radius;
        border-top: $border-width solid $gray-3;

        .dropdown-item:first-child {
          border-top: 0;
        }
      }
    }
  }

  &.show &__button {
    border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;

    &.dropdown__button-condensed {
      border: none;
    }
  }

  &__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
    max-width: 100%;
  }

  &__icon {
    color: $dark-blue;
    position: absolute;
    top: 0;
    bottom: 0;

    &-default {
      right: $dropdown-item-padding-x;
      margin: auto 0;
    }

    &-condensed {
      margin: auto space(2);
    }
  }

  &.show &__icon {
    transform: rotate(180deg);
  }

  &__menu {
    border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
    max-height: 20rem;
    overflow-y: auto;
  }

  &__item {
    border: $border-style;
    border-bottom: none;

    &:first-child {
      border-radius: 0;
    }

    &:hover {
      border-left: none;
      border-right: none;
    }
  }

  &__item ~ &__item {
    border-top: $border-style;
  }

  &__image {
    display: inline-block;
    vertical-align: bottom;
    margin-right: space(2);
    max-height: 1.15rem;
    max-width: 1.5rem;
    overflow: hidden;

    > img {
      max-height: 100%;
    }
  }
}
