@import '../style/common';

.accordion {
  border-top: $border-width solid $border-color;
  border-bottom: $border-width solid $border-color;
  padding: space(3) space(4);

  &__toggle {
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
    padding: space(3) space(5) space(3) 0;

    @include hover-focus {
      text-decoration: underline;
    }
  }

  &__label {
    display: inline;
    vertical-align: middle;
  }

  &__icon {
    color: $dark-blue;
    position: absolute;
    top: 0.15em;
    right: 0;
    transform: rotate(180deg);

    .collapsed & {
      transform: rotate(0);
    }
  }

  &__content {
    padding: space(4) 0;
  }
}
