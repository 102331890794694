
// ----- base typography -----

* {
  text-size-adjust: none;
  -webkit-tap-highlight-color: $transparent;
}

html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ----- headings -----

h1, .h1 {
  @include make-heading(1);

  small, .small {
    @include make-text-util(lg);
  }
}

h2, .h2 {
  @include make-heading(2);

  small, .small {
    @include make-text-util(sm);
    @include media-breakpoint-up(md) {
      @include make-text-util(lg);
    }
  }
}

h3, .h3 {
  @include make-heading(3);

  small, .small {
    @include make-text-util(sm);
    margin-bottom: space(3);
  }
}

h4, .h4 {
  @include make-heading(4);
}

h5, .h5 {
  @include make-heading(5);
}

h6, .h6 {
  @include make-heading(6);
}

.display-1 {
  @include make-display-heading(1);
}

.display-2 {
  @include make-display-heading(2);
}

// ----- paragraphs -----

strong {
  font-weight: $font-weight-bold;
}

// ----- utility -----

.text-util-lg {
  @include make-text-util(lg);
}

.text-util-md {
  @include make-text-util(md);
}

.text-util-sm {
  @include make-text-util(sm);
}

.text-util-xs {
  @include make-text-util(xs);
}

// ----- focusables -----
* { // stylelint-disable-line no-duplicate-selectors
  @include focus-outline($primary);
}


// ----- buttons -----

// unstyle unclassed buttons
button {
  @include focus-outline($primary); // force overwrite button reset
  color: inherit;
  font: inherit;
  background: none;
  border: 0;
  padding: 0;
  outline: none;
}

.btn {
  text-transform: uppercase;
  letter-spacing: $text-util-md-letter-spacing;
}

@each $name, $value in $grid-breakpoints {
  @include media-breakpoint-up($name) {
    .btn-#{$name}-block {
      display: block;
      width: 100%;
    }
  }
}

// theme buttons
.btn-primary,
.btn-secondary,
.btn-accent {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.btn-primary {
  @include button-variant($primary, $primary, $white, $primary, $white, $primary);
  box-shadow: $btn-box-shadow;
}

.btn-secondary {
  @include button-variant($secondary, $secondary, $white, $secondary, $white, $secondary);
  box-shadow: $btn-box-shadow;
  color: $dark;

  @include hover-focus {
    color: $dark;
  }
}

.btn-tertiary {
  @include button-variant($tertiary, $tertiary, $white, $tertiary, $white, $tertiary);
  box-shadow: $btn-box-shadow;
}

.btn-accent {
  @include button-variant($accent, $accent, $white, $accent, $white, $accent);
  box-shadow: $btn-box-shadow;

  @include hover-focus {
    color: $dark;
  }
}

.btn-white {
  @include button-variant($white, $dark, $gray-4, $gray-4, $gray-4, $gray-4);
  box-shadow: $btn-box-shadow;
}


// ----- nav bars -----
.navbar-light {
  .navbar-nav {
    .nav-link:hover,
    .nav-link.focus-visible {
      color: $navbar-light-hover-color;
    }
  }
}

.navbar-dark {
  .navbar-nav {
    .nav-link:hover,
    .nav-link.focus-visible {
      color: $navbar-dark-hover-color;
    }
  }
}


// ----- links -----
.a {
  // i.e. make a button look like an <a>
  display: inline;
  cursor: pointer;
  text-align: inherit;

  // copied from bootstrap link reset css
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: $transparent;
  -webkit-text-decoration-skip: objects; // stylelint-disable-line property-no-vendor-prefix

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &.active {
    color: $link-hover-color;
  }
}


// ----- paragraphs -----
p {
  img {
    max-width: 100%;
  }
}

// ----- forms -----

label, .label {
  display: inline-block;
  font-size: $text-util-size-md;
  font-weight: $text-util-font-weight;
  letter-spacing: $text-util-md-letter-spacing;
  margin-bottom: $label-margin-bottom;
  text-transform: uppercase;
}

.form-control {
  letter-spacing: $text-util-sm-letter-spacing;
  text-transform: uppercase;
  background-clip: border-box;
}

.custom-control-label:before,
.custom-control-label:after {
  top: 0;
}

.custom-control-sm .custom-control-label {
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
  letter-spacing: normal;
  text-transform: none;
}

.invalid-feedback,
.valid-feedback {
  text-transform: uppercase;
  font-size: $form-feedback-font-size;
  font-weight: $font-weight-semi-bold;
}

// .custom-control-input:checked ~ .custom-control-label {
//   &:after {
//     box-shadow: $custom-checkbox-indicator-checked-box-shadow;
//   }
// }

// ----- cards -----
.card {
  &-body {
    padding: $card-spacer-y $card-spacer-x;
  }
}

// ----- no-print -----
.no-print {
  @media print {
    display: none;
  }
}

// ---- lists -----

// .list-cards {
//   @include list-unstyled;
// }

// .list-cards-item {
//   padding: $card-spacer-y $card-spacer-x;
//   margin-bottom: space(4);
//   box-shadow: $box-shadow;
//   border-radius: $card-border-radius;
//   background-color: $card-bg;

//   &:last-child {
//     margin-bottom: 0;
//   }
// }

// .list-columns {
//   @include list-unstyled;
//   @include make-row;

//   &-item {
//     @include make-col-ready;
//     @include padding(space(3) null);

//     @include media-breakpoint-up(md) {
//       @include make-col(6);
//     }

//     &:after {
//       content: '';
//       display: block;
//       height: $border-width;
//       background-color: $border-color;
//       position: absolute;
//       right: $grid-gutter-width / 2;
//       bottom: 0;
//       left: $grid-gutter-width / 2;
//     }
//   }
// }

// .dropdown-menu {
//   margin-top: space(2);
//   box-shadow: $embossed-box-shadow;
//   border-radius: $border-radius-sm;
// }
